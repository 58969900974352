<template>
  <div>
    <history-element-details-modal
      :show="showModalHistory"
      :selectedHistoryElement="selectedHistoryElement"
      @hideModal="hideModalHistory"
    />

    <item-card title="Historique" icon="nc-chat-33">
      <form @submit.prevent="search">
        <div class="input-group no-border">
          <input
            v-model="searchValue"
            type="text"
            class="form-control"
            placeholder="Recherche..."
            @keyup="search"
          />
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="nc-icon nc-zoom-split" />
            </div>
          </div>
        </div>
      </form>
      <div class="history-container">
        <card
          v-for="historyElement in searchedHistoryElements"
          :key="historyElement.id"
          :style="{
            backgroundColor: textToHslColor(
              historyElement.userIdentity,
              lightColorType
            ),
          }"
        >
          <span class="history-element-timestamp">{{
            historyElement.timestamp
          }}</span>
          <p class="history-element-content">
            <strong
              :style="{
                color: textToHslColor(
                  historyElement.userIdentity,
                  darkColorType
                ),
              }"
              >{{ historyElement.userIdentity }}</strong
            >
            <span v-html="historyElement.content"></span>
          </p>
          <button
            v-if="historyElement.oldValue || historyElement.newValue"
            class="btn btn-icon btn-round icon-history-show-more"
            @click="showHistoryModal(historyElement.id)"
          >
            <i class="nc-icon nc-single-copy-04" />
          </button>
        </card>
        <card v-if="!searchedHistoryElements.length" class="empty-history"
          >L'historique ne contient actuellement aucun élément.
        </card>
      </div>
    </item-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import mixinMessages from '@/mixins/mixinMessages';
import { Card } from 'src/components/UIComponents';
import ItemCard from './ItemCard.vue';
import HistoryElementDetailsModal from '../../Modals/HistoryElementDetailsModal.vue';

export default {
  components: {
    Card,
    ItemCard,
    HistoryElementDetailsModal,
  },
  mixins: [mixinMessages],
  data() {
    return {
      showModalHistory: false,
      selectedHistoryElement: {},
      searchValue: '',
      searchedHistoryElements: [],
    };
  },
  computed: {
    ...mapState({
      itemId: (state) => state.item.itemData.id,
      historyElements: (state) => state.item.itemData.history,
    }),
  },
  watch: {
    historyElements: {
      handler(newVal) {
        this.searchedHistoryElements = newVal;
      },
    },
  },
  methods: {
    search() {
      const lowerCasedSearch = this.searchValue.toLowerCase();

      // Filter the displayed
      this.searchedHistoryElements = this.historyElements.filter(
        (data) =>
          data.content.toLowerCase().includes(lowerCasedSearch) ||
          data.userIdentity.toLowerCase().includes(lowerCasedSearch) ||
          data.timestamp.includes(lowerCasedSearch)
      );
    },
    showHistoryModal(elementId) {
      [this.selectedHistoryElement] = this.searchedHistoryElements.filter(
        (data) => data.id === elementId
      );

      this.showModalHistory = true;
    },
    hideModalHistory() {
      this.showModalHistory = false;
    },
  },
};
</script>

<style scoped>
.history-container {
  max-height: 594px;
  overflow: scroll;
}

.history-element-timestamp {
  color: #888;
  font-size: 0.9em;
}

.history-element-content {
  margin-top: 0.5em;
}

.empty-history {
  background-color: rgba(222, 222, 222, 0.3);
  font-style: italic;
}
</style>
