<template>
  <div>
    <label>{{ label }}</label>
    <div class="row form-group2">
      <el-select
        :disabled="disabled"
        :modelValue="modelValue"
        class="select-info"
        size="large"
        placeholder="Sélectionner"
        @update:modelValue="$emit('update:modelValue', $event)"
      >
        <el-option
          v-for="option in options"
          :value="option.value"
          :label="option.label"
          :key="option.label"
          class="select-info"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: ['modelValue', 'disabled', 'label', 'options'],
  data() {
    return {
      editingDisabled: false,
      selects: {
        simple: '',
      },
    };
  },
};
</script>
