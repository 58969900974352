<template>
  <navbar :show-navbar="showNavbar">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-icon btn-round"
          @click.prevent="minimizeSidebar"
        >
          <i
            class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"
          />
          <i
            class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"
          />
        </button>
      </div>
      <div class="navbar-toggle">
        <navbar-toggle-button @click="toggleSidebar" />
      </div>
      <a class="navbar-brand" href="#pablo">EPROCOM</a>
    </div>

    <template #navbar-menu>
      <div class="search-form">
        <div v-if="$route.name === ROUTES_NAMES.ITEMS" class="search-form">
          <button
            class="btn btn-default btn-search btn-advanced-search"
            @click="showAdvancedSearchModal"
          >
            Recherche
            <br />avancée
          </button>
          <button
            class="btn btn-default btn-search btn-search-by-material"
            @click="showSearchByFrancaisAdvancedMaterialModal"
          >
            Recherche par matériel
            <br />de français avancé
          </button>
          <button
            type="button"
            class="btn btn-icon btn-round btn-cancel-search"
            @click="cancelAdvancedSearchFilter"
          >
            <i class="fa fa-ban" />
          </button>
        </div>
        <form
          v-if="
            $route.name !== ROUTES_NAMES.ITEMS &&
            $route.name !== ROUTES_NAMES.ITEM_DETAILS &&
            $route.name !== ROUTES_NAMES.FRANCAIS_ADVANCED_MATERIAL_DETAILS &&
            $route.name !== ROUTES_NAMES.FRANCAIS_SIMPLE_MATERIAL_DETAILS &&
            $route.name !== ROUTES_NAMES.MATH_MATERIAL_DETAILS &&
            $route.name !== ROUTES_NAMES.SYNCHRO_PER
          "
          @submit.prevent="search"
        >
          <div class="input-group no-border">
            <div class="input-group-append">
              <div
                id="resetSearch"
                class="input-group-text cursor-displayed"
                @click.prevent="clearField"
              >
                <i class="nc-icon nc-simple-remove" />
              </div>
            </div>
            <input
              v-model="searchField"
              type="text"
              value
              class="form-control"
              placeholder="Recherche..."
            />
            <div class="input-group-append">
              <div
                class="input-group-text cursor-displayed"
                @click.prevent="search"
              >
                <i class="nc-icon nc-zoom-split" />
              </div>
            </div>
          </div>
        </form>
      </div>
      <ul class="navbar-nav">
        <li class="nav-item m-0">
          <a class="nav-link" href="#" @click="logout">Logout</a>
        </li>
      </ul>
    </template>
  </navbar>
</template>

<script>
import ROUTES_NAMES from '@/constants/ROUTES_NAMES';
import { Navbar, NavbarToggleButton } from 'src/components/UIComponents';

export default {
  components: {
    Navbar,
    NavbarToggleButton,
  },
  data() {
    return {
      searchField: '',
      activeNotifications: false,
      showNavbar: false,
      ROUTES_NAMES,
    };
  },
  methods: {
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    logout() {
      this.$store.dispatch('account/logout');
    },
    showAdvancedSearchModal() {
      this.emitter.emit('showAdvancedSearchModal');
    },
    showSearchByFrancaisAdvancedMaterialModal() {
      this.emitter.emit('showSearchByFrancaisAdvancedMaterialModal');
    },
    cancelAdvancedSearchFilter() {
      this.emitter.emit('advancedSearch', {});
    },
    search() {
      this.emitter.emit('search', this.searchField);
      this.clearField();
    },
    clearField() {
      this.searchField = '';
    },
  },
};
</script>

<style lang="scss" scope>
.search-form {
  width: 100%;
}

.cursor-displayed {
  cursor: pointer;
}

.btn-search {
  min-width: 20em;
}

.btn-advanced-search {
  margin-right: 2em !important;
}

.btn-search-by-material {
  margin-right: 1em !important;
}
</style>
