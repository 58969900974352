// eslint-disable-next-line import/no-extraneous-dependencies
import { Extension } from '@tiptap/core';
import '@tiptap/extension-text-style';

export default Extension.create({
  name: 'background-color',
  addOptions() {
    return {
      types: ['textStyle'],
    };
  },
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          'background-color': {
            default: null,
            parseHTML: (element) =>
              element.style['background-color'].replace(/['"]+/g, ''),
            renderHTML: (attributes) => {
              if (!attributes['background-color']) {
                return {};
              }
              return {
                style: `background-color: ${attributes['background-color']}`,
              };
            },
          },
        },
      },
    ];
  },
  addCommands() {
    return {
      setBackgroundColor:
        (backgroundColor) =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { 'background-color': backgroundColor })
            .run();
        },
      unsetBackgroundColor:
        () =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { 'background-color': null })
            .removeEmptyTextStyle()
            .run();
        },
    };
  },
});
