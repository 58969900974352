const ROLES = {
  USER: {
    name: 'user',
    string: 'User',
    value: 0,
  },
  ADMINISTRATOR: {
    name: 'administrator',
    string: 'Administrator',
    value: 2,
  },
  POWER_USER: {
    name: 'poweruser',
    string: 'Power User',
    value: 2,
  },
  ORGANIZATION_USER: {
    name: 'organizationuser',
    string: 'Organization User',
    value: 1,
  },
  COMMITTE_MEMBER: {
    name: 'committeemember',
    string: 'Committe Member',
    value: 0,
  },
  CONTENT_EDITOR: {
    name: 'contenteditor',
    string: 'Content Editor',
    value: 0,
  },
  EXTERNAL_EXPERT: {
    name: 'externalexpert',
    string: 'External Expert',
    value: 0,
  },
};

export default ROLES;
