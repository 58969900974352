<template>
  <material-details-base
    :material-type="
      ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.MathMaterialEntity.value
    "
  />
</template>

<script>
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import MaterialDetailsBase from './UIComponents/materials/MaterialDetailsBase.vue';

export default {
  components: {
    MaterialDetailsBase,
  },
  data() {
    return {
      ITEM_METADATA,
    };
  },
};
</script>
