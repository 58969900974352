<template>
  <div>
    <label>Chapitre</label>
    <div class="row form-group2">
      <el-select
        v-model="selectedChapterId"
        :disabled="disabled"
        class="select-info"
        size="large"
        filterable
        clearable
        no-match-text="Aucun chapitre n'a été trouvé"
        placeholder="Sélectionner"
        @update:modelValue="selectChapter"
      >
        <el-option
          v-for="chapter in chapters"
          :value="chapter.id"
          :label="chapter.description"
          :key="chapter.description"
          class="select-info"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object, // This component emits the whole selected thematic object instead of just its ID, in order for the parents to work with its data.
    },
    learningObjectiveId: {
      type: Number,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      chapters: [],
      selectedChapterId: null,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    learningObjectiveId: {
      handler() {
        this.fetchData();
      },
    },
    modelValue: {
      handler(newVal) {
        // Reset the select list if the value of the component is null.
        if (!newVal) {
          this.selectedChapterId = null;
        }
      },
    },
  },
  methods: {
    fetchData() {
      if (this.learningObjectiveId) {
        return this.$http
          .get(
            `/chapters/search/byLearningObjectiveId?learningObjectiveId=${this.learningObjectiveId}&projection=chapter`
          )
          .then((payload) => {
            this.chapters = payload.data._embedded.chapters.map((entity) => ({
              id: entity.id,
              description: entity.description,
            }));
          });
      }

      return null;
    },
    selectChapter() {
      const selectedChapter = this.chapters.filter(
        (chapter) => chapter.id === this.selectedChapterId
      )[0];

      // This component emits the whole selected thematic object instead of just its ID, in order for the parents to work with its data.
      this.$emit('update:modelValue', selectedChapter);
    },
  },
};
</script>
