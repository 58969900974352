<template>
  <div>
    <item-card title="Difficulté" icon="nc-user-run">
      <h6 class="mt-4">Difficulté pour le contenu</h6>
      <fg-input
        v-model="operationType"
        :disabled="!isItemEdited"
        type="text"
        label="Type d'opérations"
      />
      <complexity-select
        v-model="operationTypeIndex"
        :disabled="!isItemEdited"
        title="Indice type d'opérations"
      />
      <fg-input
        v-model="operationNumber"
        :disabled="!isItemEdited"
        type="text"
        label="Nombre de manipulations"
      />
      <complexity-select
        v-model="operationNumberIndex"
        :disabled="!isItemEdited"
        title="Indice nombre de manipulations"
      />
      <fg-input
        v-model="languageObjectAmplitude"
        :disabled="!isItemEdited"
        type="text"
        label="Ampleur objet langagier"
      />
      <complexity-select
        v-model="languageObjectAmplitudeIndex"
        :disabled="!isItemEdited"
        title="Indice ampleur objet langagier"
      />
      <div>
        <fg-input
          :disabled="true"
          v-model="contentDifficultyName"
          type="text"
          label="Indice de difficulté du contenu"
        />
        <fg-input
          :disabled="true"
          v-model="contentDifficultyValue"
          type="text"
          label="Valeur"
        />
      </div>
      <h6 class="mt-5">Difficulté pour l'enveloppe</h6>
      <complexity-select
        v-model="instructionIndex"
        :disabled="!isItemEdited"
        title="Indice consigne"
      />
      <fg-input
        v-model="questioningFormat"
        :disabled="!isItemEdited"
        type="text"
        label="Format de questionnement"
      />
      <complexity-select
        v-model="questionFormatIndex"
        :disabled="!isItemEdited"
        title="Indice format de questionnement"
      />
      <material-present-select
        v-model="material"
        :disabled="!isItemEdited"
        title="Matériel"
      />
      <div>
        <fg-input
          :disabled="true"
          v-model="envelopeDifficultyName"
          type="text"
          label="Indice de difficulté de l'enveloppe"
        />
        <fg-input
          :disabled="true"
          v-model="envelopeDifficultyValue"
          type="text"
          label="Valeur"
        />
      </div>
      <div class="mt-5 form-group">
        <label class="control-label">Difficulté à postériori</label>
        <textarea
          v-model="difficultyAfterthought"
          :disabled="!isItemEdited"
          class="form-control"
          rows="3"
        />
      </div>
    </item-card>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import mixinDifficulty from '@/mixins/mixinDifficulty';
import ComplexitySelect from '../ComplexitySelect.vue';
import MaterialPresentSelect from './MaterialPresentSelect.vue';
import ItemCard from './ItemCard.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    ItemCard,
    ComplexitySelect,
    MaterialPresentSelect,
  },
  mixins: [mixinDifficulty],
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "cycle" for "per.cycle").
    ...mapFields([
      'isItemEdited',
      'itemData.disciplineRelatedData.french.contentDifficulty.operationType',
      'itemData.disciplineRelatedData.french.contentDifficulty.operationTypeIndex',
      'itemData.disciplineRelatedData.french.contentDifficulty.operationNumber',
      'itemData.disciplineRelatedData.french.contentDifficulty.operationNumberIndex',
      'itemData.disciplineRelatedData.french.contentDifficulty.languageObjectAmplitude',
      'itemData.disciplineRelatedData.french.contentDifficulty.languageObjectAmplitudeIndex',
      'itemData.disciplineRelatedData.french.envelopeDifficulty.instructionIndex',
      'itemData.disciplineRelatedData.multiple.questioningFormat',
      'itemData.disciplineRelatedData.french.envelopeDifficulty.questionFormatIndex',
      'itemData.disciplineRelatedData.french.envelopeDifficulty.material',
      'itemData.disciplineRelatedData.multiple.difficultyAfterthought',
    ]),
    ...mapFields({
      contentDifficultyName:
        'itemData.disciplineRelatedData.french.contentDifficulty.difficultyName',
      contentDifficultyValue:
        'itemData.disciplineRelatedData.french.contentDifficulty.difficultyValue',
      envelopeDifficultyName:
        'itemData.disciplineRelatedData.french.envelopeDifficulty.difficultyName',
      envelopeDifficultyValue:
        'itemData.disciplineRelatedData.french.envelopeDifficulty.difficultyValue',
    }),
  },
  watch: {
    operationTypeIndex: {
      handler() {
        this.calculateContentDifficulty();
      },
    },
    operationNumberIndex: {
      handler() {
        this.calculateContentDifficulty();
      },
    },
    languageObjectAmplitudeIndex: {
      handler() {
        this.calculateContentDifficulty();
      },
    },
    instructionIndex: {
      handler() {
        this.calculateEnvelopeDifficulty();
      },
    },
    questionFormatIndex: {
      handler() {
        this.calculateEnvelopeDifficulty();
      },
    },
    material: {
      handler() {
        this.calculateEnvelopeDifficulty();
      },
    },
  },
  methods: {
    calculateContentDifficulty() {
      // Locally calculate the content difficulties in order to display them to the user, but they won't be
      // sent to the server since it already calculates them (and we don't want frontend-side calculations
      // to be sent since it can be unsecure).
      const averageContentDifficulty =
        (this.operationTypeIndex +
          this.operationNumberIndex +
          this.languageObjectAmplitudeIndex) /
        3;

      this.contentDifficultyName = this.getDifficultyNameByValue(
        averageContentDifficulty
      );

      this.contentDifficultyValue =
        Math.round(averageContentDifficulty * 100) / 100;
    },
    calculateEnvelopeDifficulty() {
      // Locally calculate the envelope difficulties in order to display them to the user, but they won't be
      // sent to the server since it is already calculate them (and we don't want frontend-side calculations
      // to be sent since it can be unsecure).
      let averageEnvelopeDifficulty;

      if (this.material === -1) {
        averageEnvelopeDifficulty =
          (this.instructionIndex + this.questionFormatIndex + this.material) /
          3;
      } else {
        averageEnvelopeDifficulty =
          (this.instructionIndex + this.questionFormatIndex) / 2;
      }

      this.envelopeDifficultyName = this.getDifficultyNameByValue(
        averageEnvelopeDifficulty
      );

      this.envelopeDifficultyValue =
        Math.round(averageEnvelopeDifficulty * 100) / 100;
    },
  },
};
</script>
