<template>
  <div>
    <top-buttons
      :isEntityEdited="isItemEdited"
      :errorMessage="errorMessage"
      :lastUpdateDate="lastUpdateDate"
      :showDuplicateButton="true"
      @editOrSave="editOrSaveItem"
      @cancel="cancelButtonClicked"
      @delete="deleteItem"
      @duplicate="duplicateItem"
    />

    <div class="row">
      <!-- ** 1st column ** -->
      <div class="col-md-3">
        <tabs
          pills
          type="primary"
          icons
          centered
          tab-content-classes="tab-space"
          class="inspectors-tab-nav"
        >
          <tab-pane id="gestion" key="gestion" title="Gestion">
            <management-panel />
          </tab-pane>
          <tab-pane id="per" key="per" title="PER">
            <per-panel />
          </tab-pane>
          <tab-pane
            v-if="isMathematicsItem && disciplineId"
            id="math"
            key="math"
            title="Math"
          >
            <mathematics-discipline-panel :discipline-id="disciplineId" />
          </tab-pane>
          <tab-pane
            v-if="isFrenchItem"
            id="francais"
            key="francais"
            title="Français"
          >
            <french-discipline-panel />
          </tab-pane>
        </tabs>
      </div>

      <!-- ** 2nd column ** -->
      <div class="col-md-5">
        <tabs
          pills
          type="primary"
          icons
          centered
          tab-content-classes="tab-space"
          class="inspectors-tab-nav"
        >
          <tab-pane
            v-if="disciplineId"
            id="content"
            key="content"
            title="Contenu et corrigé"
          >
            <content-and-solution-panel :discipline-id="disciplineId" />
          </tab-pane>
          <tab-pane
            v-if="disciplineId"
            id="consignes"
            key="consignes"
            title="Consignes"
          >
            <instructions-panel :discipline-id="disciplineId" />
          </tab-pane>
        </tabs>
      </div>

      <!-- ** 3rd column ** -->
      <div class="col-md-4">
        <tabs
          pills
          type="primary"
          icons
          centered
          tab-content-classes="tab-space"
          class="inspectors-tab-nav"
        >
          <tab-pane id="journal" key="journal" title="Discussions">
            <logbook-panel />
          </tab-pane>
          <tab-pane
            id="changelog"
            key="changelog"
            title="Historique des modifications"
          >
            <item-history-panel />
          </tab-pane>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import COMMON from '@/constants/COMMON';
import PER_CONSTANTS from '@/constants/PER_CONSTANTS';
import ROUTES_NAMES from '@/constants/ROUTES_NAMES';
import mixinSwal from '@/mixins/mixinSwal';
import { getSentenceFormatedDateTime } from '@/services/DateService';
import { Tabs, TabPane } from 'src/components/UIComponents';
import ManagementPanel from './UIComponents/itemDetails/ManagementPanel.vue';
import PerPanel from './UIComponents/itemDetails/PerPanel.vue';
import FrenchDisciplinePanel from './UIComponents/itemDetails/FrenchDisciplinePanel.vue';
import MathematicsDisciplinePanel from './UIComponents/itemDetails/MathematicsDisciplinePanel.vue';
import ContentAndSolutionPanel from './UIComponents/itemDetails/ContentAndSolutionPanel.vue';
import InstructionsPanel from './UIComponents/itemDetails/InstructionsPanel.vue';
import LogbookPanel from './UIComponents/itemDetails/LogbookPanel.vue';
import ItemHistoryPanel from './UIComponents/itemDetails/ItemHistoryPanel.vue';
import TopButtons from './UIComponents/TopButtons.vue';

export default {
  components: {
    TabPane,
    Tabs,
    ManagementPanel,
    PerPanel,
    FrenchDisciplinePanel,
    MathematicsDisciplinePanel,
    ContentAndSolutionPanel,
    InstructionsPanel,
    LogbookPanel,
    ItemHistoryPanel,
    TopButtons,
  },
  mixins: [mixinSwal],
  data() {
    return {
      COMMON,
      PER_CONSTANTS,
      getSentenceFormatedDateTime,
    };
  },
  computed: {
    ...mapState({
      isItemEdited: (state) => state.item.isItemEdited,
      itemId: (state) => state.item.itemData.id,
      disciplineId: (state) => state.item.itemData.per.discipline.id,
      errorMessage: (state) => state.item.errorMessage,
    }),
    ...mapGetters({
      serverFormattedFields: 'item/getServerFormattedFields',
      serverFormattedItemThematicsFields:
        'item/getServerFormattedItemThematicsFields',
      itemDataHistory: 'item/getItemDataHistory',
      itemExternalMaterialsDataHistory:
        'item/getItemExternalMaterialsDataHistory',
      itemThematicsDataHistory: 'item/getItemThematicsDataHistory',
    }),
    lastUpdateDate() {
      return this.getSentenceFormatedDateTime(
        this.$store.state.item.itemData.lastUpdateDate
      );
    },
    isFrenchItem() {
      return this.disciplineId === PER_CONSTANTS.PER_DISCIPLINE_ID_FRANCAIS.ID;
    },
    isMathematicsItem() {
      return this.disciplineId === PER_CONSTANTS.PER_DISCIPLINE_ID_MATH.ID;
    },
    haveItemDataChanged() {
      // Get the history's new element since last save in order to check if there were any change since then.
      this.$store.commit(
        'item/removeUpdatedItemThematicsIfTheyAreNewItemThematics'
      );
      const fullHistory = this.itemDataHistory
        .concat(this.itemExternalMaterialsDataHistory)
        .concat(this.itemThematicsDataHistory);

      return fullHistory.length > 0;
    },
  },
  created() {
    this.$store.commit('item/enableLoading');

    const itemId = parseInt(this.$route.params.id, 10);
    this.$store.commit('item/setId', itemId);

    this.fetchItemById();
  },
  beforeMount() {
    window.addEventListener(
      'beforeunload',
      this.preventPageLeavingIfThereAreUnsavedChanges
    );
  },
  beforeRouteLeave(to, from, next) {
    if (this.haveItemDataChanged) {
      // Prevent the user to go to another page of the application if there are unsaved changes.
      if (
        // eslint-disable-next-line no-alert
        !window.confirm(
          'Vous vous apprêtez à quitter cette page sans avoir enregistré les modifications apportées. Souhaitez-vous vraiment faire cela ?'
        )
      ) {
        return;
      }
    }

    next();
  },
  beforeUnmount() {
    window.removeEventListener(
      'beforeunload',
      this.preventPageLeavingIfThereAreUnsavedChanges
    );
  },
  methods: {
    // Prevent the user to leave the tab or exit the browser if there are unsaved changes.
    preventPageLeavingIfThereAreUnsavedChanges(event) {
      // If there were no changes just leave the page.
      if (!this.haveItemDataChanged) {
        return;
      }

      // Otherwise prevent the leaving event by showing a message to the user.
      event.preventDefault();

      // Chrome requires returnValue to be set.
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    },
    editOrSaveItem() {
      if (this.isItemEdited) {
        this.updateItem();
      } else {
        this.$store.commit('item/toggleEdition');
      }
    },
    cancelButtonClicked() {
      this.$store.commit('item/toggleEdition');
      this.fetchItemById();
    },
    updateItem() {
      this.$store.commit('item/enableLoading');
      this.$store.commit('item/resetErrorMessage');

      // Will contain the new history elements related to the item's data, including the external materials
      // but excluding the thematic axes.
      const itemHistory = this.itemDataHistory.concat(
        this.itemExternalMaterialsDataHistory
      );

      // Send the item's updates to the server if there is any.
      if (itemHistory.length > 0 || this.itemThematicsDataHistory.length > 0) {
        this.$http
          .patch(`/commands/items/${this.itemId}`, {
            ...this.serverFormattedFields,
            itemThematics: this.serverFormattedItemThematicsFields,
          })
          .then(() => {
            // Merge all the built history arrays in order to post them all in
            // one request.
            this.$store.commit(
              'item/removeUpdatedItemThematicsIfTheyAreNewItemThematics'
            );
            const fullHistory = itemHistory.concat(
              this.itemThematicsDataHistory
            );

            // Post the generated history of the item's changes.
            if (fullHistory.length > 0) {
              this.$http
                .post(`/commands/items/${this.itemId}/history`, fullHistory)
                .then(() => {
                  this.$store.commit('item/enableLoading');
                  this.fetchItemById();
                  this.$store.commit('item/disableLoading');
                  this.$store.commit('item/disableEdition');
                });
            } else {
              this.fetchItemById();
              this.$store.commit('item/disableEdition');
            }
          })
          .catch(() => {
            this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
            this.$store.commit('item/disableLoading');
          });
      } else {
        this.$store.commit('item/disableEdition');
        this.$store.commit('item/disableLoading');
      }
    },
    fetchItemById() {
      this.$store.commit('item/disableEdition');

      const fetchingPromises = [];

      fetchingPromises.push(
        this.$store.dispatch('item/loadItemById', this.itemId)
      );
      fetchingPromises.push(
        this.$store.dispatch('item/loadItemThematicsByItemId', this.itemId)
      );
      fetchingPromises.push(
        this.$store.dispatch('item/loadHistoryByItemId', this.itemId)
      );

      Promise.all(fetchingPromises).then(() => {
        this.$store.commit('item/disableLoading');
      });
    },
    duplicateItem() {
      this.$store.commit('item/resetErrorMessage');

      this.showSwal(
        "Êtes-vous sûr de vouloir dupliquer l'item ?",
        "Après confirmation, les détails de l'item résultant de la duplication seront affichés.",
        'Oui, dupliquer',
        'Non, annuler'
      )
        .then(() => {
          this.$http
            .post(`/commands/items/${this.itemId}/duplicateItem`)
            .then((response) => {
              const newItemId = response.headers.location.substr(
                response.headers.location.lastIndexOf('/') + 1
              );
              this.$router
                .push({
                  name: ROUTES_NAMES.ITEM_DETAILS,
                  params: { id: newItemId },
                })
                .then(() => {
                  // eslint-disable-next-line no-restricted-globals
                  location.reload();
                });
            })
            .catch((error) => {
              this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
            });
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
          }
        });
    },
    deleteItem() {
      this.$store.commit('item/resetErrorMessage');

      this.showSwal("Êtes-vous sûr de vouloir supprimer l'item ?")
        .then(() => {
          this.$http
            .delete(`/commands/items/${this.itemId}`)
            .then(() => {
              this.$router.push({
                name: ROUTES_NAMES.ITEMS,
              });
            })
            .catch(() => {
              this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
            });
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
          }
        });
    },
  },
};
</script>

<style scoped>
.inspectors-tab-nav:deep(.nav-pills .nav-item:first-child .nav-link) {
  border-radius: 10px 0 0 10px !important;
}

.inspectors-tab-nav:deep(.nav-pills .nav-item:last-child .nav-link) {
  border-radius: 0 10px 10px 0 !important;
}

.inspectors-tab-nav:deep(.nav-pills .nav-item .nav-link) {
  font-size: 12px;
  padding: 8px 8px;
}
</style>
