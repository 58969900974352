import { createApp } from 'vue';
import { configure } from 'vee-validate';

import './pollyfills';
import mitt from 'mitt';
import Notifications from '@kyvg/vue3-notification';
import {
  ElSelect,
  ElOption,
  ElTooltip,
  ElPopover,
  ElTable,
  ElTableColumn,
} from 'element-plus';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import axios from 'axios';
import { verifyAndRenewToken } from '@/services/AuthService';
import HTTP_CODES from '@/constants/HTTP_CODES';
import router from './router/router';
import App from './App.vue';
import store from './store';

// Plugins
import GlobalComponents from './globalComponents';
import GlobalDirectives from './globalDirectives';
import SideBar from './components/UIComponents/SidebarPlugin';

// css imports
import './assets/sass/paper-dashboard.scss';
import './assets/sass/element_variables.scss';
import './assets/sass/style.scss';
import './assets/sass/style-migration-vue3.scss';
import 'element-plus/theme-chalk/index.css';
import '@noction/vue-bezier/dist/style.css';
import 'katex/dist/katex.min.css';

import sidebarLinks from './sidebarLinks';

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: true,
  validateOnModelUpdate: true,
});

// eslint-disable-next-line import/prefer-default-export
export const app = createApp({
  extends: App,
});

app.use(router);
app.use(store);

// plugin setup
app.use(GlobalDirectives);
app.use(GlobalComponents);
app.use(Notifications);
app.use(SideBar, { sidebarLinks });
app.use(ElOption);
app.use(ElSelect);
app.use(ElTooltip);
app.use(ElPopover);
app.use(ElTable);
app.use(ElTableColumn);

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

// eslint-disable-next-line no-restricted-syntax
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

// Axios http requests and set as $http provider globally
const axiosServiceConfig = {
  baseURL: process.env.VUE_APP_EPROCOM_BACKEND_API_URL,
};

const instance = axios.create(axiosServiceConfig);
instance.interceptors.request.use(async (config) => {
  const configInstance = config;
  if (store.getters['account/token']) {
    // Check if the token is expired, if it is, it will try to login again to generate a new token
    await verifyAndRenewToken();
    configInstance.headers.Authorization = `Bearer ${store.getters['account/token']}`;
  }

  return configInstance;
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (
        error.response.status === HTTP_CODES.UNAUTHORIZED ||
        error.response.status === HTTP_CODES.FORBIDDEN
      ) {
        app.config.globalProperties.$store.dispatch('account/logout');
      }
    }
  }
);
app.config.globalProperties.$http = instance;

app.mount('#app');
