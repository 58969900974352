<template>
  <div>
    <label>Cycle</label>
    <div class="row form-group2">
      <el-select
        :disabled="disabled"
        :modelValue="modelValue"
        class="select-info"
        size="large"
        placeholder="Sélectionner"
        @update:modelValue="$emit('update:modelValue', $event)"
      >
        <el-option
          v-for="option in selects.disciplines"
          :value="option.value"
          :label="option.label"
          :key="option.label"
          class="select-info"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: ['modelValue', 'disabled'],
  data() {
    return {
      editingDisabled: false,
      selects: {
        simple: '',
        cycles: [
          {
            key: 'key',
            value: 'value',
          },
        ],
      },
    };
  },
  created() {
    this.fetchData();
    this.selects.simple = this.modelValue;
  },
  methods: {
    fetchData() {
      this.selects.disciplines = [
        { key: 1, value: 1 },
        { key: 2, value: 2 },
        { key: 3, value: 3 },
      ];
    },
  },
};
</script>
