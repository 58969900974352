<template>
  <details-modal
    :show="show"
    current-operation="create"
    @cancel="hideNewMaterialModal"
    @close="hideNewMaterialModal"
    @create="createNewMaterial"
  >
    <form>
      <div>
        <label>Sélectionner un fichier :</label>
        <div>
          <input
            class="file-input"
            type="file"
            accept=".pdf"
            id="file"
            ref="file"
            v-on:change="handleFileUpload()"
          />
        </div>
      </div>
    </form>
    <p v-if="errorMessage" class="errorMessageInModal">{{ errorMessage }}</p>
  </details-modal>
</template>

<script>
import COMMON from '@/constants/COMMON';
import HTTP_CODES from '@/constants/HTTP_CODES';
import mixinTextTransform from '@/mixins/mixinTextTransform';
import DetailsModal from './DetailsModal.vue';

export default {
  components: {
    DetailsModal,
  },
  mixins: [mixinTextTransform],
  props: {
    show: {
      type: Boolean,
    },
    // camelCase material type
    materialType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      file: '',
      errorMessage: '',
    };
  },
  methods: {
    handleFileUpload() {
      [this.file] = this.$refs.file.files;
    },
    resetData() {
      this.file = '';
      this.errorMessage = '';
    },
    hideNewMaterialModal() {
      this.resetData();
      this.$emit('hideNewMaterialModal');
    },
    createNewMaterial() {
      const formData = new FormData();
      formData.append('file', this.file);

      const lowerCasedMaterialType = this.materialType.toLowerCase();

      this.$http
        .post(`/commands/${lowerCasedMaterialType}materials/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.hideNewMaterialModal();

          // Wait for the DOM to properly update (which means we are waiting for the modal to close) before redirecting the user to the new-created
          // material's page. We have to process this way in order to prevent the scrollbar to disapear after the creation.
          this.$nextTick(() => {
            const pascalCasedMaterialType = this.capitalizeFirstLetter(
              this.materialType
            );
            const newMaterialId = response.data;

            this.$router.push({
              name: `${pascalCasedMaterialType}MaterialDetails`,
              params: { id: newMaterialId, newMaterialCreation: true },
            });
          });
        })
        .catch((error) => {
          if (error.response.status === HTTP_CODES.REQUEST_TOO_LONG) {
            this.errorMessage =
              'Le fichier sélectionné est trop volumineux, veuillez réessayer avec un fichier plus petit.';
          } else {
            this.errorMessage = COMMON.BASIC_ERROR;
          }
        });
    },
  },
};
</script>

<style scoped>
.file-input {
  width: 100%;
}
</style>
