<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="hideSearchByFrancaisAdvancedMaterialModal"
  >
    <template #header>
      <h5 class="modal-title">
        Recherche par matériel de français "textes/corpus"
      </h5>
    </template>
    <material-select
      v-if="show"
      v-model="francaisAdvancedMaterial"
      :material-type="
        ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.FrancaisAdvancedMaterialEntity
          .value
      "
    />
    <template #footer>
      <div class="justify-content-center d-flex p-2 w-100">
        <p-button
          class="mx-1 my-1 w-50"
          type="light"
          round
          @click="hideSearchByFrancaisAdvancedMaterialModal"
          >Annuler
        </p-button>
        <p-button
          class="mx-1 my-1 w-50"
          type="success"
          round
          @click="doSearchByFrancaisAdvancedMaterial"
          >Rechercher
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import { Modal } from 'src/components/UIComponents';
import MaterialSelect from '../UIComponents/MaterialSelect.vue';

export default {
  components: {
    Modal,
    MaterialSelect,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      ITEM_METADATA,
      francaisAdvancedMaterial: {},
    };
  },
  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  methods: {
    keyupHandler(event) {
      if (this.show) {
        if (event.key === 'Enter') {
          this.doSearchByFrancaisAdvancedMaterial();
        } else if (event.key === 'Escape') {
          this.hideSearchByFrancaisAdvancedMaterialModal();
        }
      }
    },
    hideSearchByFrancaisAdvancedMaterialModal() {
      this.francaisAdvancedMaterial = {};
      this.$emit('hideSearchByFrancaisAdvancedMaterialModal');
    },
    doSearchByFrancaisAdvancedMaterial() {
      // Emit a searchByFrancaisAdvancedMaterial that will be catched in the CrudList component,
      // which will fire the fetchItems method of this component.
      this.emitter.emit('searchByFrancaisAdvancedMaterial', {
        francaisMaterialId: this.francaisAdvancedMaterial.id,
      });

      this.hideSearchByFrancaisAdvancedMaterialModal();
    },
  },
};
</script>

<style scoped>
.error-message-modal {
  margin: 2em 0 0 0;
  color: darkred;
}
</style>
