<template>
  <div>
    <top-buttons
      :fetching="fetching || updating"
      :isEntityEdited="isMaterialEdited"
      :isSaveButtonDisabled="!hasDocuments"
      :errorMessage="errorMessage"
      :showDuplicateButton="false"
      @editOrSave="editOrSaveMaterial"
      @cancel="cancelButtonClicked"
      @delete="deleteMaterial"
    />

    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h5 class="title">Données de base</h5>
          </div>
          <div class="card-body">
            <form>
              <div class="mb-4">
                <div v-if="documentErrorMessage" class="alert alert-danger">
                  {{ documentErrorMessage }}
                </div>
                <div
                  v-if="!hasDocuments && !fetching"
                  class="alert alert-danger"
                >
                  Veuillez ajouter au moins un document à ce matériel.
                </div>
                <div
                  v-else
                  v-for="(document, index) in documents"
                  v-bind:key="document.id"
                  class="mb-2"
                >
                  <h6 v-if="index === 0">Document principal (PDF)</h6>
                  <h6 v-if="index === 1">Document(s) secondaire(s)</h6>
                  <div
                    :class="{ 'mb-4': index === 0 }"
                    class="d-flex align-items-end"
                  >
                    <fg-input
                      class="flex-grow-1 my-0 mr-3"
                      :disabled="!isMaterialEdited"
                      v-model="document.name"
                      type="text"
                      label="Nom du fichier"
                    />
                    <fg-input
                      class="my-0 mr-3"
                      :disabled="true"
                      v-model="document.mimetype"
                      type="text"
                      label="Type de fichier"
                    />
                    <el-tooltip
                      v-if="isMaterialEdited"
                      class="item"
                      content="Supprimer le document"
                      placement="left"
                    >
                      <p-button
                        class="btn-document mr-2"
                        type="danger"
                        @click.prevent="deleteDocument(document)"
                      >
                        <template #label>
                          <i class="nc-icon nc-simple-remove"></i>
                        </template>
                      </p-button>
                    </el-tooltip>
                    <el-tooltip
                      class="item"
                      content="Télécharger le document"
                      placement="right"
                    >
                      <p-button
                        :disabled="document.isNewDocument"
                        class="btn-document"
                        type="primary"
                        @click.prevent="downloadDocument(document.id)"
                      >
                        <template #label>
                          <i class="nc-icon nc-cloud-download-93"></i>
                        </template>
                      </p-button>
                    </el-tooltip>
                  </div>
                </div>
                <div v-if="isMaterialEdited" class="mt-4">
                  <label>Ajouter un document</label>
                  <div>
                    <input
                      :accept="acceptedDocumentType"
                      class="file-input"
                      type="file"
                      ref="file"
                      v-on:change="addDocumentToMaterial"
                    />
                  </div>
                </div>
              </div>
              <h6>Détails</h6>
              <div v-if="isMaterialTypeFrancaisAdvanced" class="row">
                <div class="col-md-12">
                  <francais-genre-grouping-select
                    :disabled="!isMaterialEdited"
                    v-model="genreGrouping"
                    title="Regroupement de genre"
                  />
                </div>
                <div class="col-md-12">
                  <fg-input
                    :disabled="!isMaterialEdited"
                    v-model="title"
                    type="text"
                    label="Titre"
                  />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <label class="control-label">Description</label>
                  <div>
                    <textarea
                      class="form-control"
                      placeholder="Écrire une description"
                      rows="3"
                      :disabled="!isMaterialEdited"
                      v-model="description"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div v-if="isMaterialTypeFrancaisAdvanced" class="row">
                <div class="form-group col-md-12">
                  <label class="control-label">Thèmes</label>
                  <div>
                    <textarea
                      class="form-control"
                      placeholder="Écrire une liste de thèmes"
                      rows="3"
                      :disabled="!isMaterialEdited"
                      v-model="theme"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-12">
                  <label class="control-label">Crédits</label>
                  <div>
                    <textarea
                      class="form-control"
                      placeholder="Écrire les crédits"
                      rows="3"
                      :disabled="!isMaterialEdited"
                      v-model="credits"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div v-if="isMaterialTypeFrancaisAdvanced" class="row">
                <div class="col-md-12">
                  <fg-input
                    :disabled="!isMaterialEdited"
                    v-model="lengthInPagesAndWords"
                    type="text"
                    label="Longueur : nombre de pages (nombre de mots)"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <fg-input
                    :disabled="!isMaterialEdited"
                    v-model="rnNoticeUrl"
                    type="text"
                    label="URL de la notice RN"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import swal from 'sweetalert2';
import { createHelpers } from 'vuex-map-fields';
import COMMON from '@/constants/COMMON';
import HTTP_CODES from '@/constants/HTTP_CODES';
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import mixinTextTransform from '@/mixins/mixinTextTransform';
import TopButtons from '../TopButtons.vue';
import FrancaisGenreGroupingSelect from '../FrancaisGenreGroupingSelect.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields, mapMultiRowFields } = createHelpers({
  getterType: 'material/getField',
  mutationType: 'material/updateField',
});

export default {
  components: {
    TopButtons,
    FrancaisGenreGroupingSelect,
  },
  mixins: [mixinTextTransform],
  props: {
    materialType: {
      type: String,
      required: true,
    },
    // Will contain custom material's data that are not included in this component,
    // in order for it to send them during the update process.
    customMaterialData: {
      type: Object,
      default() {
        return {};
      },
    },
    parentErrorMessage: {
      type: String,
    },
  },
  data() {
    return {
      fetching: true,
      updating: false,
      errorMessage: '',
      documentErrorMessage: '',
    };
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "customCode" for "management.customCode").
    ...mapFields([
      'data.id',
      'data.genreGrouping',
      'data.title',
      'data.description',
      'data.theme',
      'data.credits',
      'data.lengthInPagesAndWords',
      'data.rnNoticeUrl',
      'deletedDocumentsIds',
      'isMaterialEdited',
    ]),
    // And with this one we could refer and mutate the array fields.
    ...mapMultiRowFields(['data.documents']),
    buttonClassObject() {
      return {
        'btn-warning': !this.isMaterialEdited,
        'btn-success': this.isMaterialEdited,
      };
    },
    commandEndpointValue() {
      return `${this.materialType.toLowerCase()}materials`;
    },
    isMaterialTypeFrancaisAdvanced() {
      return (
        this.materialType ===
        ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.FrancaisAdvancedMaterialEntity
          .value
      );
    },
    hasDocuments() {
      return this.documents.length > 0;
    },
    acceptedDocumentType() {
      if (this.hasDocuments) {
        return '*';
      }

      // Restrict the document's type to PDF if the user try to add the first document of the material.
      return '.pdf';
    },
  },
  created() {
    this.$store.commit(
      'material/setIsMaterialEdited',
      this.$route.params.newMaterialCreation
    );
    this.fetchMaterialById(this.$route.params.id);
  },
  watch: {
    parentErrorMessage: {
      handler() {
        this.errorMessage = this.parentErrorMessage;
      },
    },
  },
  methods: {
    editOrSaveMaterial() {
      this.errorMessage = '';

      if (this.isMaterialEdited) {
        this.updateMaterial();
      } else {
        this.$store.commit('material/toggleEdition');
      }
    },
    cancelButtonClicked() {
      this.documentErrorMessage = '';
      this.$store.commit('material/resetErrorMessage');
      this.$store.commit('material/toggleEdition');
      this.fetchMaterialById(this.id);
    },
    fetchMaterialById(materialId) {
      this.fetching = true;

      this.$store
        .dispatch('material/fetchMaterialByIdAndType', {
          materialId,
          materialType: this.materialType,
        })
        .then(() => {
          this.fetching = false;
        });
    },
    deleteDocument(document) {
      this.documentErrorMessage = '';
      this.$store.commit('material/resetErrorMessage');

      swal({
        title: 'Êtes-vous sûr de vouloir supprimer ce document ?',
        text: 'Le matériel sera conservé. Les changements ne seront pas sauvegardés tant que vous n\'aurez pas manuellement appuyé sur le bouton "Enregistrer".',
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Oui, supprimer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then(() => {
          this.$store.commit('material/deleteDocument', document);
        })
        .catch(() => {});
    },
    downloadDocument(materialDocumentId) {
      this.documentErrorMessage = '';
      this.$store.commit('material/resetErrorMessage');

      this.$http
        .get(
          `/commands/${this.commandEndpointValue}/documents/download/${materialDocumentId}`,
          {
            headers: {
              Accept: 'application/pdf',
            },
            responseType: 'blob',
          }
        )
        .then((response) => {
          const headerContent = response.headers['content-disposition'];
          const startFileNameIndex = headerContent.indexOf('"') + 1;
          const endFileNameIndex = headerContent.lastIndexOf('"');
          const filename = headerContent.substring(
            startFileNameIndex,
            endFileNameIndex
          );
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(() => {
          this.errorMessage = COMMON.BASIC_ERROR;
        });
    },
    addDocumentToMaterial() {
      this.$store.commit(
        'material/addDocumentToMaterial',
        this.$refs.file.files[0]
      );

      this.$refs.file.value = null;
    },
    updateMaterial() {
      this.documentErrorMessage = '';
      this.$store.commit('material/resetErrorMessage');

      if (this.documents[0].mimetype === 'application/pdf') {
        this.updating = true;

        const newDocuments =
          this.$store.getters['material/getNewCreatedDocumentsList'];

        const formData = new FormData();

        for (const newDocument of newDocuments) {
          formData.append(
            'newDocuments',
            newDocument.content,
            newDocument.name
          );
        }

        formData.append(
          'data',
          new Blob(
            [
              JSON.stringify({
                updatedDocumentsData:
                  this.$store.getters['material/getUpdatedDocumentsList'],
                deletedDocumentsIds: this.deletedDocumentsIds,
                genreGrouping: this.genreGrouping,
                title: this.title,
                description: this.description,
                theme: this.theme,
                credits: this.credits,
                lengthInPagesAndWords: this.lengthInPagesAndWords,
                rnNoticeUrl: this.rnNoticeUrl,
                ...this.customMaterialData,
              }),
            ],
            {
              type: 'application/json',
            }
          )
        );

        this.$http
          .patch(`/commands/${this.commandEndpointValue}/${this.id}`, formData)
          .then((payload) => {
            this.fetchMaterialById(payload.data);
            this.$store.commit('material/setIsMaterialEdited', false);
            this.updating = false;
          })
          .catch((error) => {
            if (error.response.status === HTTP_CODES.UNSUPPORTED_MEDIA_TYPE) {
              this.documentErrorMessage = COMMON.DOCUMENT_TYPE_ERROR;
            } else {
              this.errorMessage = COMMON.BASIC_ERROR;
            }

            this.updating = false;
          });
      } else {
        this.documentErrorMessage = COMMON.DOCUMENT_TYPE_ERROR;
      }
    },
    deleteMaterial() {
      this.documentErrorMessage = '';
      this.$store.commit('material/resetErrorMessage');

      swal({
        title: 'Êtes-vous sûr de vouloir supprimer ce matériel ?',
        text: `Il sera retiré de tous les items qui le référencent, et il ne sera pas possible d'annuler cette opération.`,
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Oui, effacer',
        cancelButtonText: 'Non, conserver',
        buttonsStyling: false,
      })
        .then(() => {
          this.$http
            .delete(`/commands/${this.commandEndpointValue}/${this.id}`)
            .then(() => {
              const pascalCasedMaterialType = this.capitalizeFirstLetter(
                this.materialType
              );

              this.$router.push({
                name: `${pascalCasedMaterialType}Materials`,
              });
            })
            .catch(() => {
              this.errorMessage = COMMON.BASIC_ERROR;
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
.icon {
  position: absolute;
  width: 50px;
  top: 10px;
  right: 31px;
  font-size: 1.6em;
}

.top-button-container {
  margin-top: -30px;
  margin-bottom: 0px;
}

.error-message {
  color: darkred;
}

.btn-document {
  font-size: 1.3em;
  height: 38px;
  margin: 0;
  padding-bottom: 0;
  padding-top: 3px;
}
</style>
