const COMMON = {
  BASIC_ERROR:
    'Une erreur est survenue, veuillez réessayer ou contacter un administrateur.',
  DOCUMENT_TYPE_ERROR:
    "Le document principal d'un matériel doit obligatoirement être un fichier de type PDF.",
  LIGHT_COLOR_LIGHTNESS: 0.95,
  DARK_COLOR_LIGHTNESS: 0.25,
};

export default COMMON;
