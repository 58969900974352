<template>
  <div class="panel panel-border panel-default">
    <div
      :data-target="`#collapse-chapter-${identifier}`"
      data-toggle="collapse"
      class="panel-collapse collapse-link collapsed"
    >
      <div class="panel-heading">
        <div
          class="panel-title-container d-flex justify-content-between align-items-center"
        >
          <div class="panel-title flex-grow-1 pr-2" v-html="title"></div>
          <div class="d-flex">
            <button
              v-if="deletable"
              class="btn btn-icon btn-round btn-danger btn-remove"
              @click="removeItem($event)"
            >
              <i class="fa fa-minus" />
            </button>
            <el-icon class="expand-icon">
              <el-icon-arrow-right />
            </el-icon>
          </div>
        </div>
      </div>
    </div>
    <div :id="`collapse-chapter-${identifier}`" class="panel-collapse collapse">
      <div class="panel-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ElIcon } from 'element-plus';
import { ArrowRight as ElIconArrowRight } from '@element-plus/icons-vue';

export default {
  components: {
    ElIconArrowRight,
    ElIcon,
  },
  props: {
    identifier: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    removeItem(event) {
      // We don't want the collapse to close when clicking the delete button.
      event.stopPropagation();
      this.$emit('removeItem');
    },
  },
};
</script>

<style scoped>
.panel {
  background-color: #f9f7f3;
  border-radius: 3px;
  line-height: 1.6em;
  padding: 0.5em 0.7em;
}

.panel-body {
  padding-top: 0.5em;
}

.panel-border {
  border: 1px solid #e8e7e3;
}

.expand-icon {
  -webkit-transition: all 150ms linear;
  -moz-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  -ms-transition: all 150ms linear;
  transition: all 150ms linear;
}

.panel-default .panel-collapse[aria-expanded='true'] .expand-icon,
.panel-default .panel-collapse.expanded .expand-icon {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.panel-default .panel-collapse {
  color: #66615b;
}

.panel-default .panel-collapse:hover > .panel-heading,
.panel-default .panel-collapse:active > .panel-heading,
.panel-default .panel-collapse:focus > .panel-heading {
  color: #eb5e28;
}

.panel-collapse .panel-heading {
  cursor: pointer;
}

.btn-remove {
  display: none;
  height: 1.3rem !important;
  margin: 0 0.75rem 0 0;
  min-width: 1.3rem !important;
  width: 1.3rem !important;
  z-index: 1000;
}

.panel-default .panel-collapse[aria-expanded='true'] .btn-remove:enabled,
.panel-default .panel-collapse.expanded .btn-remove:enabled {
  display: block;
}

.panel-title-container .panel-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.panel-collapse {
  padding: 0.35em 0;
}
</style>
