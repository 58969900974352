<template>
  <div :class="{ hidden: !show }" class="modal-history">
    <div class="modal-content">
      <div
        class="history-modal-header d-flex justify-content-between align-items-center"
      >
        <div><h2>Détails des modifications</h2></div>
        <div class="close-button" @click="hideModal">&times;</div>
      </div>
      <div class="modal-body">
        <h5 class="item-content">
          {{ selectedHistoryElement.userIdentity
          }}{{ selectedHistoryElement.content }}
        </h5>
        <div class="flex-container">
          <div class="flex-child item-header left">Ancienne valeur</div>
          <div class="flex-child item-header right">Nouvelle valeur</div>
          <div
            v-if="selectedHistoryElement.oldValue"
            class="flex-child left"
            v-html="selectedHistoryElement.oldValue"
          />
          <div v-if="!selectedHistoryElement.oldValue" class="flex-child left">
            <i>- aucune valeur -</i>
          </div>
          <div
            v-if="selectedHistoryElement.newValue"
            class="flex-child right"
            v-html="selectedHistoryElement.newValue"
          />
          <div v-if="!selectedHistoryElement.newValue" class="flex-child right">
            <i>- aucune valeur -</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
    },
    selectedHistoryElement: {
      type: Object,
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  methods: {
    keyupHandler(event) {
      if (this.show) {
        if (event.key === 'Escape') {
          this.hideModal();
        }
      }
    },
    hideModal() {
      this.$emit('hideModal');
    },
  },
};
</script>

<style lang="scss">
.modal-history .not-loaded-image {
  cursor: pointer;
  font-size: 3em;
  margin-bottom: 15px;
}

.modal-history {
  /* Lists */
  ul,
  ol {
    padding-left: 13px;
  }

  /* Task list */
  ul[data-type='taskList'] {
    list-style: none;
    padding: 0;

    p {
      margin: 0;
    }

    li {
      display: flex;
      align-items: baseline;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }
  }

  /* Horizontal rule */
  hr.ProseMirror-selectednode {
    border-top: 1px solid #000;
  }

  /* Table */
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 1px solid black;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-clip: padding-box;
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}
</style>

<style scoped>
.hidden {
  display: none;
}

/* Add Animation */
@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }
  to {
    opacity: 1;
    top: 50%;
  }
}

.modal-history {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

.modal-history .modal-content {
  border-radius: 0;
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid black;
  width: 60%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation-name: animatetop;
  animation-duration: 0.4s;
  top: 50%;
  transform: translateY(-50%);
  max-height: 95%;
}

.modal-history .close-button {
  color: white;
  cursor: pointer;
  font-size: 3em;
  font-weight: bold;
}

/* Modal Header */
.modal-history .history-modal-header {
  border-radius: 0;
  padding: 1em 2em;
  background-color: #2c2c2c;
  color: white;
}

.modal-history .history-modal-header h2 {
  margin: 0.5em 0;
  text-align: left;
}

/* Modal Body */
.modal-history .modal-body {
  overflow: scroll;
  padding: 1.5em;
}

.modal-history .item-content {
  font-weight: 600;
  margin: 0 0 2em 0;
}

.modal-history .flex-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.modal-history .flex-child {
  flex-grow: 1;
  width: 50%;
}

.modal-history .flex-child.item-header {
  font-weight: 600;
  padding-bottom: 1em;
  text-decoration: underline;
}

.modal-history .flex-child.left {
  border-right: 1px solid #aaa;
  padding-right: 2em;
}

.modal-history .flex-child.right {
  padding-left: 2em;
}
</style>
