// https://github.com/maoberlehner/vuex-map-fields
// This library allows us to use the (nested) properties of our store directly in the
// v-model properties of our fields, without having to manually manage the mutations.
import { getField, updateField } from 'vuex-map-fields';
import { v4 as uuidv4 } from 'uuid';
import COMMON from '@/constants/COMMON';
import { app } from '@/main';

const state = () => ({
  data: {
    documents: [], // contain the existing documents fetched from the server and the new-created one
  },
  deletedDocumentsIds: [], // documents deleted but not sent to the server yet
  isMaterialEdited: false,
  errorMessage: '',
});

const getters = {
  // Automatically generate a v-model getter for each nester property of the store.
  getField,
  getNewCreatedDocumentsList(state) {
    return state.data.documents
      .filter((document) => document.isNewDocument)
      .map((document) => ({
        name: document.name,
        content: document.content,
      }));
  },
  getUpdatedDocumentsList(state) {
    return state.data.documents
      .filter((document) => !document.isNewDocument)
      .map((document) => ({
        id: document.id,
        name: document.name,
      }));
  },
};

const mutations = {
  // Automatically generate a v-model mutation for each nester property of the store.
  updateField,
  setMaterialData(state, data) {
    state.data = data;
  },
  setMaterialTextDifficultyIndexValue(state, textDifficultyIndexValue) {
    state.data.textDifficultyIndexValue = textDifficultyIndexValue;
  },
  setMaterialTextDifficultyIndexName(state, textDifficultyIndexName) {
    state.data.textDifficultyIndexName = textDifficultyIndexName;
  },
  addDocumentToMaterial(state, file) {
    state.data.documents.push({
      id: uuidv4(), // temporary id used to delete the document
      name: file.name,
      mimetype: file.type,
      content: file,
      isNewDocument: true, // indicate that the file is new
    });
  },
  deleteDocument(state, document) {
    // Add the document to the deleted documents' list only if this was not
    // a new-created document.
    if (
      !document.isNewDocument &&
      !state.deletedDocumentsIds.includes(document.id)
    ) {
      state.deletedDocumentsIds.push(document.id);
    }

    state.data.documents = state.data.documents.filter(
      (d) => d.id !== document.id
    );
  },
  resetDeletedDocumentsIds(state) {
    state.deletedDocumentsIds = [];
  },
  setIsMaterialEdited(state, isMaterialEdited) {
    state.isMaterialEdited = isMaterialEdited;
  },
  toggleEdition(state) {
    state.isMaterialEdited = !state.isMaterialEdited;
  },
  setErrorMessage(state, errorMessage) {
    state.errorMessage = errorMessage;
  },
  resetErrorMessage(state) {
    state.errorMessage = '';
  },
};

const actions = {
  fetchMaterialByIdAndType({ commit }, { materialId, materialType }) {
    return app.config.globalProperties.$http
      .get(
        `/${materialType}Materials/${materialId}?projection=${materialType}MaterialDTO`
      )
      .then((payload) => {
        commit('resetDeletedDocumentsIds');
        commit('setMaterialData', payload.data);
      })
      .catch(() => {
        commit('setErrorMessage', COMMON.BASIC_ERROR);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
