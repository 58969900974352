<template>
  <div class="content">
    <transition :name="transitionName" mode="out-in">
      <!-- your content here -->
      <keep-alive include="Overview">
        <div>
          <router-view />
        </div>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transitionName: 'fade',
    };
  },
  watch: {
    $route(to, from) {
      if (to.name.endsWith('Details')) {
        this.transitionName = 'slide-left';
      } else if (from.name.endsWith('Details')) {
        this.transitionName = 'slide-right';
      } else {
        this.transitionName = 'fade';
      }
    },
  },
};
</script>

<style>
.fade-enter-active {
  transition: all 0.2s ease-out;
}

.fade-leave-active {
  transition: all 0.2s ease-out;
}

.fade-enter {
  opacity: 0;
  /*transform: translateY(100%);*/
}

.fade-enter-to {
  /* transform: translate(0px); */
}

.fade-leave {
  /* transform: translateX(0px); */
}

.fade-leave-to {
  opacity: 0;
  /*transform: translateY(100%);*/
}

.slide-left-leave-active,
.slide-left-enter-active {
  transition: all 0.2s ease-out;
}

.slide-left-enter {
  transform: translate(100%);
  opacity: 0;
}

.slide-left-leave-to {
  /*transform: translate(-100%);*/
  opacity: 0;
}

.slide-right-leave-active,
.slide-right-enter-active {
  transition: all 0.2s ease-out;
}

.slide-right-leave-to {
  transform: translate(100%);
  opacity: 0;
}

.slide-right-enter {
  /*transform: translate(-100%);*/
  opacity: 0;
}
</style>
