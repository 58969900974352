import ColorHash from 'color-hash';
import COMMON from '@/constants/COMMON';

export default {
  data() {
    return {
      lightColorType: 'light',
      darkColorType: 'dark',
      lightColorHash: new ColorHash({
        lightness: COMMON.LIGHT_COLOR_LIGHTNESS,
      }), // used to generate light colors from a string (to have one color per user and this be able to recognized them in the history part)
      darkColorHash: new ColorHash({ lightness: COMMON.DARK_COLOR_LIGHTNESS }), // used to generate dark colors for the users names.
    };
  },
  methods: {
    textToHslColor(text, type) {
      let colorData = [];

      if (type === this.lightColorType) {
        colorData = this.lightColorHash.hsl(text);
      } else {
        colorData = this.darkColorHash.hsl(text);
      }

      const hue = colorData[0];
      const saturation = `${colorData[1] * 100}%`;
      const lightness = `${colorData[2] * 100}%`;
      return `hsl(${hue}, ${saturation}, ${lightness})`;
    },
  },
};
