<template>
  <div>
    <new-thematic-modal
      v-if="isItemEdited && id"
      :show="showNewThematicModal"
      :itemId="id"
      :disciplineId="discipline.id"
      :cycle="cycle"
      @addThematicToItem="addThematicToItem"
      @hideModalThematic="hideModalThematic"
    />

    <label>
      Axe(s) thématique(s)
      <button
        v-if="isItemEdited"
        class="btn btn-icon btn-round btn-success btn-create-thematic-tab"
        @click="showModalThematic"
      >
        <i class="fa fa-plus" />
      </button>
    </label>

    <div class="accordion">
      <accordion-panel
        v-for="itemThematic in itemThematics"
        class="mt-2"
        :key="itemThematic.id"
        :identifier="itemThematic.id"
        :title="itemThematic.thematic.name"
        :deletable="isItemEdited && !isThematicTheDefaultOne(itemThematic)"
        @removeItem="removeItemThematic(itemThematic)"
      >
        <label v-if="isThematicTheDefaultOne(itemThematic)"
          >Axe thématique principal</label
        >
        <label>Nom complet de l'axe thématique</label>
        <p>{{ itemThematic.thematic.name }}</p>
        <label>Objectif d'apprentissage</label>
        <h6>{{ itemThematic.learningObjective.code }}</h6>
        <p>{{ itemThematic.learningObjective.name }}</p>

        <chapters-accordion
          :item-thematic-id="itemThematic.id"
          :learning-objective-id="itemThematic.learningObjective.id"
          :chapters="itemThematic.chapters"
        />

        <years-per-cycle-select
          :disabled="!isItemEdited"
          :years="itemThematic.years"
          @change="changeYears(itemThematic.id, $event)"
        />

        <progressions-accordion
          v-if="itemThematic.years.length > 0"
          :item-thematic-id="itemThematic.id"
          :learning-objective-id="itemThematic.learningObjective.id"
          :years="itemThematic.years"
          :progressions="itemThematic.progressions"
        />

        <div v-if="itemThematic.progressions.length > 0">
          <label>Éléments spécifiques des progressions</label>
          <textarea
            :value="itemThematic.progressionsSpecificElements"
            :disabled="!isItemEdited"
            class="form-control progressions-specific-elements"
            placeholder="Insérez du texte ici..."
            rows="3"
            @change="
              changeProgressionsSpecificElements(itemThematic.id, $event)
            "
          />
        </div>
      </accordion-panel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import COMMON from '@/constants/COMMON';
import mixinSwal from '@/mixins/mixinSwal';
import NewThematicModal from '../../Modals/NewThematicModal.vue';
import AccordionPanel from '../AccordionPanel.vue';
import ChaptersAccordion from './ChaptersAccordion.vue';
import YearsPerCycleSelect from './YearsPerCycleSelect.vue';
import ProgressionsAccordion from './ProgressionsAccordion.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields, mapMultiRowFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    NewThematicModal,
    AccordionPanel,
    ChaptersAccordion,
    YearsPerCycleSelect,
    ProgressionsAccordion,
  },
  mixins: [mixinSwal],
  data() {
    return {
      showNewThematicModal: false,
    };
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "cycle" for "per.cycle").
    ...mapFields([
      'isItemEdited',
      'itemData.id',
      'itemData.per.discipline',
      'itemData.per.cycle',
    ]),
    // And with this one we could refer and mutate the array fields.
    ...mapMultiRowFields(['itemThematics']),
    ...mapGetters({
      highestItemThematicId: 'item/getHighestItemThematicId',
      highestThematicPosition: 'item/getHighestThematicPosition',
    }),
  },
  methods: {
    isThematicTheDefaultOne(itemThematic) {
      return itemThematic.thematicPosition === 0;
    },
    showModalThematic() {
      this.showNewThematicModal = true;
    },
    hideModalThematic() {
      this.showNewThematicModal = false;
    },
    addThematicToItem(newThematicData) {
      // Set a temporary ID to the item thematic object in order to be able to work on it.
      const temporaryItemThematicId = this.highestItemThematicId + 1;
      const thematicPosition = this.highestThematicPosition + 1;

      const newItemThematic = {
        id: temporaryItemThematicId,
        chapters: [],
        learningObjective: newThematicData.learningObjective,
        progressions: [],
        thematic: newThematicData.itemThematic,
        thematicPosition,
        years: [],
      };

      this.$store.commit('item/addItemThematic', newItemThematic);

      this.hideModalThematic();
    },
    removeItemThematic(itemThematic) {
      this.showSwal(
        "Êtes-vous sûr de vouloir retirer cet axe thématique de l'item ?",
        'Les changements ne seront pas sauvegardés tant que vous n\'avez pas manuellement appuyé sur le bouton "Enregistrer".'
      )
        .then(() => {
          this.$store.commit('item/removeItemThematic', itemThematic);
          this.$store.commit('item/resetErrorMessage');
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
          }
        });
    },
    changeYears(itemThematicId, years) {
      this.$store.commit('item/setItemThematicYears', {
        itemThematicId,
        years,
      });
    },
    changeProgressionsSpecificElements(itemThematicId, event) {
      this.$store.commit('item/setItemThematicProgressionsSpecificElements', {
        itemThematicId,
        progressionsSpecificElements: event.target.value,
      });
    },
  },
};
</script>

<style scoped>
.btn-create-thematic-tab {
  height: 1.3rem !important;
  margin: 0 0 0 0.5rem;
  min-width: 1.3rem !important;
  width: 1.3rem !important;
}

.accordion {
  margin: 0.25em 0 1em 0;
}

.panel-progressions,
.panel-chapters {
  margin-top: -1px !important;
}
</style>
