<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="close"
  >
    <template #header>
      <h5 class="modal-title">Détails</h5>
    </template>
    <slot />
    <template #footer>
      <div class="justify-content-center p-2 d-flex w-100">
        <p-button
          class="mx-1 my-1 w-50"
          v-if="currentOperation === 'edit'"
          type="danger"
          round
          @click="$emit('delete')"
          >Effacer
        </p-button>
        <span v-if="currentOperation === 'edit'">&nbsp;</span>
        <p-button
          class="mx-1 my-1 w-50"
          v-if="currentOperation === 'edit'"
          type="success"
          round
          @click="$emit('update')"
          >Mettre à jour
        </p-button>
        <span v-if="currentOperation === 'edit'">&nbsp;</span>
        <p-button
          class="mx-1 my-1 w-50"
          v-if="currentOperation === 'create'"
          type="success"
          round
          @click="$emit('create')"
          >Créer
        </p-button>
        <span v-if="currentOperation === 'create'">&nbsp;</span>
        <p-button
          class="mx-1 my-1 w-50"
          type="light"
          round
          @click="$emit('cancel')"
          >Annuler
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from 'src/components/UIComponents';

export default {
  name: 'DetailsModal',
  components: {
    Modal,
  },
  props: ['show', 'currentOperation'],
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style>
.errorMessageInModal {
  margin: 2em 0 0.75em 0;
  color: darkred;
}
</style>
