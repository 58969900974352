<template>
  <div>
    <div
      v-if="editor"
      class="rich-text-editor"
      :class="{ fullscreen: fullscreen }"
    >
      <math-equation-modal
        v-if="isItemDisciplineMath"
        :show="isMathEquationModalShown"
        :equation="selectedEquation"
        @hideMathEquationModal="hideMathEquationModal"
        @insertMathEquation="insertMathEquation"
        @updateMathEquation="updateMathEquation"
      />

      <div class="menubar" v-if="!isDisabled">
        <el-tooltip :show-after="1000" content="Plein écran" placement="top">
          <button
            class="menubar__button"
            :class="{ 'is-active': fullscreen }"
            @click="fullscreen = !fullscreen"
          >
            <i v-if="!fullscreen" class="fa fa-expand-arrows-alt" />
            <i v-else class="fa fa-compress-arrows-alt" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Annuler" placement="top">
          <button
            class="menubar__button"
            @click="editor.chain().undo().run()"
            :disabled="!editor.can().undo()"
          >
            <i class="fa fa-undo" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Rétablir" placement="top">
          <button
            class="menubar__button"
            @click="editor.chain().redo().run()"
            :disabled="!editor.can().redo()"
          >
            <i class="fa fa-redo" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Gras" placement="top">
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('bold') }"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <i class="fa fa-bold" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Italique" placement="top">
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('italic') }"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <i class="fa fa-italic" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Souligné" placement="top">
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('underline') }"
            @click="editor.chain().focus().toggleUnderline().run()"
          >
            <i class="fa fa-underline" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Barré" placement="top">
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('strike') }"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <i class="fa fa-strikethrough" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Exposant" placement="top">
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('superscript') }"
            @click="editor.chain().focus().toggleSuperscript().run()"
          >
            <i class="fa fa-superscript" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Indice" placement="top">
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('subscript') }"
            @click="editor.chain().focus().toggleSubscript().run()"
          >
            <i class="fa fa-subscript" />
          </button>
        </el-tooltip>

        <el-popover placement="bottom" width="250" trigger="click">
          <template #reference>
            <button title="Couleur de police" class="menubar__button">
              <i
                :style="{
                  color: selectedTextColor,
                }"
                class="fa fa-fill-drip"
              />
            </button>
          </template>
          <div>
            <sketch
              id="color"
              name="color"
              class="sketch-color"
              unselectable="on"
              onselectstart="return false;"
              :modelValue="selectedTextColor"
              :preset-colors="presetColors"
              @update:modelValue="
                editor.chain().focus().setColor($event.hex).run()
              "
            ></sketch>
            <button
              class="btn btn-reset-color"
              @click="editor.chain().focus().setColor(null).run()"
            >
              Enlever la couleur
            </button>
          </div>
        </el-popover>

        <el-popover placement="bottom" width="250" trigger="click">
          <template #reference>
            <button title="Surlignage" class="menubar__button">
              <i
                :style="{
                  color: selectedTextBackgroundColor,
                }"
                class="fa fa-highlighter"
              />
            </button>
          </template>
          <div>
            <sketch
              id="highlight"
              name="highlight"
              class="sketch-color"
              unselectable="on"
              onselectstart="return false;"
              :modelValue="selectedTextBackgroundColor"
              :preset-colors="presetColors"
              @update:modelValue="
                editor.chain().focus().setBackgroundColor($event.hex).run()
              "
            ></sketch>
            <button
              class="btn btn-reset-color"
              @click="editor.chain().focus().setBackgroundColor(null).run()"
            >
              Enlever le surlignage
            </button>
          </div>
        </el-popover>

        <el-tooltip
          :show-after="1000"
          content="Aligner à gauche"
          placement="top"
        >
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
            @click="editor.chain().focus().setTextAlign('left').run()"
          >
            <i class="fas fa-align-left" />
          </button>
        </el-tooltip>

        <el-tooltip
          :show-after="1000"
          content="Aligner au center"
          placement="top"
        >
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
            @click="editor.chain().focus().setTextAlign('center').run()"
          >
            <i class="fas fa-align-center" />
          </button>
        </el-tooltip>

        <el-tooltip
          :show-after="1000"
          content="Aligner à droite"
          placement="top"
        >
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
            @click="editor.commands.setTextAlign('right')"
          >
            <i class="fas fa-align-right" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Justifier" placement="top">
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
            @click="editor.chain().focus().setTextAlign('justify').run()"
          >
            <i class="fas fa-align-justify" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Indenter" placement="top">
          <button
            class="menubar__button"
            @click="editor.chain().focus().indent().run()"
          >
            <i class="fa fa-indent" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Désindenter" placement="top">
          <button
            class="menubar__button"
            @click="editor.chain().focus().outdent().run()"
          >
            <i class="fa fa-outdent" />
          </button>
        </el-tooltip>

        <el-tooltip :show-after="1000" content="Liste à puces" placement="top">
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('bulletList') }"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            <i class="fa fa-list-ul" />
          </button>
        </el-tooltip>

        <el-tooltip
          :show-after="1000"
          content="Liste numérotée"
          placement="top"
        >
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('orderedList') }"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            <i class="fa fa-list-ol" />
          </button>
        </el-tooltip>

        <el-tooltip
          :show-after="1000"
          content="Liste de tâches"
          placement="top"
        >
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('taskList') }"
            @click="editor.chain().focus().toggleTaskList().run()"
          >
            <i class="fa fa-list" />
          </button>
        </el-tooltip>

        <el-tooltip
          :show-after="1000"
          content="Trait horizontal"
          placement="top"
        >
          <button
            class="menubar__button"
            @click="editor.chain().focus().setHorizontalRule().run()"
          >
            <i class="fa fa-minus" />
          </button>
        </el-tooltip>

        <el-tooltip
          :show-after="1000"
          v-if="isItemDisciplineMath"
          :content="mathEquationLabel"
          placement="top"
        >
          <button
            class="menubar__button"
            :class="{ 'is-active': editor.isActive('math') }"
            @click="showMathEquationModal"
          >
            <i class="fa fa-square-root-alt" />
          </button>
        </el-tooltip>

        <span>
          <el-tooltip
            :show-after="1000"
            content="Insérer une image"
            placement="top"
          >
            <label class="input-image-label" :for="inputFileId">
              <i class="fa fa-images" />
            </label>
          </el-tooltip>

          <input
            class="input-image"
            :id="inputFileId"
            type="file"
            accept="image/png, image/jpeg"
            @change="insertImage($event)"
          />
        </span>

        <el-tooltip
          :show-after="1000"
          content="Insérer un tableau"
          placement="top"
        >
          <button
            class="menubar__button"
            @click="
              editor
                .chain()
                .focus()
                .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
                .run()
            "
          >
            <i class="fa fa-table" />
          </button>
        </el-tooltip>

        <el-tooltip
          :show-after="1000"
          content="Insérer un lien"
          placement="top"
        >
          <button class="menubar__button" @click="setLink">
            <i class="fa fa-link" />
          </button>
        </el-tooltip>

        <span class="table-buttons" v-if="editor.isActive('table')">
          <el-tooltip
            :show-after="1000"
            content="Supprimer le tableau"
            placement="top"
          >
            <button
              class="menubar__button"
              @click="editor.chain().focus().deleteTable().run()"
            >
              <i class="fa fa-trash-alt" />
            </button>
          </el-tooltip>

          <el-tooltip
            :show-after="1000"
            content="Insérer une colonne avant"
            placement="top"
          >
            <button
              class="menubar__button"
              @click="editor.chain().focus().addColumnBefore().run()"
            >
              <i class="fa fa-plus" />
              <i class="fas fa-grip-lines-vertical" />
            </button>
          </el-tooltip>

          <el-tooltip
            :show-after="1000"
            content="Insérer une colonne après"
            placement="top"
          >
            <button
              class="menubar__button"
              @click="editor.chain().focus().addColumnAfter().run()"
            >
              <i class="fas fa-grip-lines-vertical" />
              <i class="fa fa-plus" />
            </button>
          </el-tooltip>

          <el-tooltip
            :show-after="1000"
            content="Supprimer la colonne"
            placement="top"
          >
            <button
              class="menubar__button"
              @click="editor.chain().focus().deleteColumn().run()"
            >
              <i class="fas fa-grip-lines-vertical" />
              <i class="fa fa-minus" />
            </button>
          </el-tooltip>

          <el-tooltip
            :show-after="1000"
            content="Insérer une ligne avant"
            placement="top"
          >
            <button
              class="menubar__button"
              @click="editor.chain().focus().addRowBefore().run()"
            >
              <i class="fa fa-plus" />
              <i class="fas fa-grip-lines" />
            </button>
          </el-tooltip>

          <el-tooltip
            :show-after="1000"
            content="Insérer une ligne après"
            placement="top"
          >
            <button
              class="menubar__button"
              @click="editor.chain().focus().addRowAfter().run()"
            >
              <i class="fa fa-grip-lines" />
              <i class="fa fa-plus" />
            </button>
          </el-tooltip>

          <el-tooltip
            :show-after="1000"
            content="Supprimer la ligne"
            placement="top"
          >
            <button
              class="menubar__button"
              @click="editor.chain().focus().deleteRow().run()"
            >
              <i class="fas fa-grip-lines" />
              <i class="fa fa-minus" />
            </button>
          </el-tooltip>

          <el-tooltip
            :show-after="1000"
            content="Fusionner/Séparer les cellules"
            placement="top"
          >
            <button
              class="menubar__button"
              @click="editor.chain().focus().mergeOrSplit().run()"
            >
              <i class="fa fa-object-group" />
            </button>
          </el-tooltip>
        </span>

        <bubble-menu
          v-if="editor"
          :editor="editor"
          :shouldShow="showImageMenu"
          class="image-menu"
        >
          <div>
            <label for="width">Longueur (px)</label><br />
            <input
              class="input-image-width"
              id="width"
              type="number"
              name="width"
              v-model="imageWidth"
            />
          </div>
          <el-tooltip
            :show-after="1000"
            content="Redimensioner"
            placement="top"
          >
            <button
              class="menubar__button btn-save-image-width"
              @click="editor.chain().focus().resizeImage(imageWidth).run()"
            >
              <i class="fa fa-save" />
            </button>
          </el-tooltip>
        </bubble-menu>
      </div>

      <editor-content
        class="editor-content"
        :class="{ disabled: isDisabled }"
        :editor="editor"
      />
    </div>
    <div v-if="errorMessage" class="error-message alert alert-danger">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
/* eslint-disable import/no-extraneous-dependencies */
import { mapState } from 'vuex';
import PER_CONSTANTS from '@/constants/PER_CONSTANTS';
import { Sketch } from '@lk77/vue3-color';
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-3';
import { DOMParser } from 'prosemirror-model';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import Placeholder from '@tiptap/extension-placeholder';
import History from '@tiptap/extension-history';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import Strike from '@tiptap/extension-strike';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import TextAlign from '@tiptap/extension-text-align';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import HoritontalRule from '@tiptap/extension-horizontal-rule';
import TableRow from '@tiptap/extension-table-row';
import Gapcursor from '@tiptap/extension-gapcursor';
import Link from '@tiptap/extension-link';
import BackgroundColor from './extensions/BackgroundColor';
import { Indent } from './extensions/Indent';
import ResizableImage from './extensions/Image';
import MathEquation from './extensions/mathEquation/MathEquation';
import MathEquationModal from '../../Modals/MathEquationModal.vue';
/*
 * There is a bug in the default table library, causing the tables to not retain their
 * columns width when the editor isn't editable, so we created custom extensions in
 * order to make it work: https://github.com/ueberdosis/tiptap/issues/2041.
 */
import Table from './extensions/Table';
import TableCell from './extensions/TableCell';
import TableHeader from './extensions/TableHeader';

export default {
  components: {
    EditorContent,
    BubbleMenu,
    Sketch,
    MathEquationModal,
  },
  props: {
    fieldName: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Insérez du contenu...',
    },
    disabled: {
      type: Boolean,
    },
    disciplineId: {
      type: Number,
    },
  },
  data() {
    return {
      editor: null,
      // Allows us to mutate the prop value.
      isDisabled: true,
      fullscreen: false,
      imageWidth: 1920,
      isMathEquationModalShown: false,
      selectedEquation: '',
      presetColors: [
        '#ffffff',
        '#c1bfc0',
        '#7e807f',
        '#000000',
        '#c10003',
        '#fd0002',
        '#fd00fb',
        '#fdc003',
        '#fefe00',
        '#92d15e',
        '#7f8000',
        '#01b14f',
        '#03b0f4',
        '#0171c3',
        '#002168',
        '#742da3',
      ],
      errorMessage: null,
    };
  },
  watch: {
    modelValue(modelValue) {
      // HTML
      const isSame = this.editor.getHTML() === modelValue;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(modelValue, false);
    },
    disabled(value) {
      this.isDisabled = value;
    },
    isDisabled() {
      // We have to re-create the whole editor when its editable property changes, otherwise the user
      // won't be able to resize tables columns: https://github.com/ueberdosis/tiptap/issues/2301
      this.destroyEditor();
      this.createEditor();
    },
    fullscreen(value) {
      // Add or remove Bootstrap's "modal-open" class to body in order to hide or not
      // its scrollbar when the editor is in fullscreen mode.
      if (value) {
        document.body.classList.add('modal-open');
      } else {
        document.body.classList.remove('modal-open');
      }
    },
  },
  mounted() {
    this.createEditor();
  },
  beforeUnmount() {
    this.destroyEditor();
  },
  computed: {
    ...mapState({
      itemId: (state) => state.item.itemData.id,
    }),
    isItemDisciplineMath() {
      return this.disciplineId === PER_CONSTANTS.PER_DISCIPLINE_ID_MATH.ID;
    },
    inputFileId() {
      // The input field's ID need to be unique.
      return `input-image-${this.fieldName}`;
    },
    selectedTextColor() {
      return this.editor.getAttributes('textStyle').color
        ? this.editor.getAttributes('textStyle').color
        : '#000000';
    },
    selectedTextBackgroundColor() {
      return this.editor.getAttributes('textStyle')['background-color']
        ? this.editor.getAttributes('textStyle')['background-color']
        : '#000000';
    },
    mathEquationLabel() {
      if (this.editor.isActive('math')) {
        return "Modifier l'équation mathématique";
      }
      return 'Insérer une équation mathématique';
    },
  },
  methods: {
    createEditor() {
      const extensions = [
        Document,
        Paragraph,
        Text,
        Placeholder.configure({
          placeholder: this.placeholder,
        }),
        History,
        Bold,
        Italic,
        Underline,
        Strike,
        Superscript,
        Subscript,
        TextStyle,
        Color,
        BackgroundColor,
        TextAlign.configure({
          types: ['paragraph'],
        }),
        Indent,
        ListItem,
        BulletList,
        OrderedList,
        TaskList,
        TaskItem.configure({
          nested: true,
        }),
        HoritontalRule,
        ResizableImage,
        Gapcursor,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        Link.configure({
          autolink: false,
          openOnClick: false,
        }),
      ];

      if (this.isItemDisciplineMath) {
        extensions.push(MathEquation);
      }

      this.editor = new Editor({
        extensions,
        content: this.modelValue,
        editable: !this.isDisabled,
        onUpdate: () => {
          this.$emit('update:modelValue', this.editor.getHTML());
        },
        onSelectionUpdate: ({ editor }) => {
          this.selectedEquation =
            editor.getAttributes('math')?.equation?.toString() || '';
        },
      });
    },
    destroyEditor() {
      if (this.editor) {
        this.editor.destroy();
      }
    },
    showMathEquationModal() {
      this.isMathEquationModalShown = true;
    },
    hideMathEquationModal() {
      this.isMathEquationModalShown = false;
    },
    showImageMenu({ editor }) {
      if (
        editor.state.selection.node &&
        editor.state.selection.node.attrs.width
      ) {
        this.imageWidth = editor.state.selection.node.attrs.width;
      } else {
        this.imageWidth = 1920;
      }

      return editor.isActive('resizable-image');
    },
    elementFromString(value) {
      const element = document.createElement('div');
      element.innerHTML = value.trim();

      return element;
    },
    insertHTML({ state, view }, value) {
      const { selection } = state;
      const element = this.elementFromString(value);
      const slice = DOMParser.fromSchema(state.schema).parseSlice(element);
      const transaction = state.tr.insert(selection.anchor, slice.content);

      view.dispatch(transaction);
    },
    insertImage(event) {
      // Save the cursor's position since we are going to lose it once the field will be disabled.
      const { selection } = this.editor.state;
      this.isDisabled = true;
      this.$store.commit('item/enableLoading');

      const formData = new FormData();
      formData.append('image', event.target.files[0]);

      this.$http
        .post(`/images/items/${this.itemId}/${this.fieldName}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((response) => {
          this.isDisabled = false;
          // Set the cursor's position as saved before in order to insert the image at the right place.
          this.editor.commands.setTextSelection(selection.anchor);
          const imgSrc = `${process.env.VUE_APP_EPROCOM_BACKEND_API_URL}/${response.data}`;
          this.insertHTML(this.editor, `<img src="${imgSrc}"><p></p></img>`);
        })
        .catch(() => {
          this.isDisabled = false;
          this.errorMessage =
            "Une erreur est survenue lors du téléchargement de l'image, veuillez réessayer plus tard.";
        })
        .finally(() => {
          this.$store.commit('item/disableLoading');
        });
    },
    insertMathEquation(equation) {
      this.insertHTML(this.editor, `<math equation="${equation}"></math>`);
    },
    updateMathEquation(equation) {
      this.editor.chain().focus().setEquation(equation).run();
    },
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      if (previousUrl) {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }

      // Get the currently selected text
      const { selection } = this.editor.view.state;
      const selectedNode = selection.$from.node();
      const selectedText = selectedNode.textContent;

      // client accepted the implementation of the window.prompt
      // https://sg-ciip.atlassian.net/browse/EPR-126
      // eslint-disable-next-line no-alert
      const url = window.prompt('URL', selectedText);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run();
    },
  },
};
</script>

<style lang="scss">
$color-black: #000000;
$color-light-grey: #f1f1f1;

/* Fullscreen */
.rich-text-editor.fullscreen {
  background-color: #fff;
  bottom: 0;
  height: 100vh;
  left: 0;
  overflow: scroll;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 10000;
}

div[role='tooltip'] {
  z-index: 10500 !important;
}

/* Colors */
.sketch-color {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none;
}

.btn-reset-color {
  font-size: 11px;
  width: 100%;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #adb5bd;
  pointer-events: none;
  height: 0;
}

/* Menu bar */
.menubar {
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

  background-color: $color-light-grey;
  border-top: 1px solid #dadada;
  border-top-right-radius: 1px solid #dadada;
  border-left: 1px solid #dadada;
  border-radius: 4px 4px 0 0;

  button.glyphicon-button:focus,
  button.glyphicon-button:active:focus,
  button.glyphicon-button.active:focus,
  button.glyphicon-button.focus,
  button.glyphicon-button:active.focus,
  button.glyphicon-button.active.focus {
    outline: none !important;
  }

  .table-tools {
    i {
      color: blue;
    }
  }

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: $color-black;
    border-radius: 50%;
    cursor: pointer;
    padding: 6px;
    margin: 2px;
    text-align: center;

    i {
      font-size: 15px;
    }

    &:hover:not(:disabled) {
      background-color: rgba($color-black, 0.05);
    }

    &.is-active {
      background-color: rgba($color-black, 0.1);
    }

    &:disabled {
      color: #c3c3c3;
      cursor: no-drop;
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }

  .table-buttons .menubar__button {
    color: #51cbce;
  }

  .input-color {
    border: 0;
    cursor: pointer;
    height: 28px;
    width: 28px;
  }

  .input-image-label {
    border-radius: 50%;
    color: #000;
    cursor: pointer;
    display: inline;
    font-size: 15px;
    padding: 6px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .input-image {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}

.image-menu {
  background-color: #fff;
  border: 1px solid #555;
  border-radius: 4px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: flex-end;
  padding: 0.5em 0.75em 0.75em 0.75em;

  .input-image-width {
    height: 27px;
    margin-right: 1.2em;
    width: 7em;
  }

  .btn-save-image-width {
    margin: 0;
    padding: 0;

    i {
      font-size: 27px;
    }
  }
}

/* Content */
.editor-content {
  border: 1px solid #dadada;
  border-radius: 0 0 4px 4px;
  padding: 10px;

  &.disabled {
    background-color: #d3d3d3;
    border-radius: 4px;
    color: #66615b;
    cursor: no-drop;

    th {
      background-color: #c3c3c3;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }
}

/* Lists */
.editor-content ul,
.editor-content ol {
  padding-left: 13px;
}

/* Task list */
ul[data-type='taskList'] {
  list-style: none;
  padding: 0;

  p {
    margin: 0;
  }

  li {
    display: flex;
    align-items: baseline;

    > label {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      user-select: none;
    }

    > div {
      flex: 1 1 auto;
    }
  }
}

/* Horizontal rule */
hr.ProseMirror-selectednode {
  border-top: 1px solid #000;
}

/* Table */
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 1px solid black;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-clip: padding-box;
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.error-message {
  margin-top: 1em;
}
</style>
