<template>
  <div>
    <div v-if="isItemDisciplineMath" class="equations-explanation">
      Il est possible d'insérer des formules mathématiques dans les champs
      ci-dessous en cliquant sur le bouton
      <i class="fa fa-square-root-alt mx-1" /> du champ, ou en écrivant
      directement dans ce dernier une formule encapsulée par deux symboles
      <code class="ml-1">$</code> (par exemple <code>$a^2+x$</code>). Veuillez
      ensuite vous référer à
      <a href="https://katex.org/docs/supported.html" target="_blank"
        >la documentation de rédaction</a
      >
      de KaTeX.
    </div>

    <item-card title="Matériel à disposition" icon="nc-settings">
      <rich-text-editor
        v-model="availableMaterial"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="available-material"
      />
    </item-card>

    <item-card title="Consignes de passation" icon="nc-alert-circle-i">
      <rich-text-editor
        v-model="passingInstructions"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="passing-instructions"
      />
    </item-card>

    <item-card title="Critères et indicateurs de réussite" icon="nc-satisfied">
      <rich-text-editor
        v-model="successFactors"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="success-factors"
      />
    </item-card>

    <item-card
      v-if="isItemDisciplineMath"
      title="Possibles variables didactiques"
      icon="nc-satisfied"
    >
      <rich-text-editor
        v-model="possibleDidacticVariables"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="possible-didactic-variables"
      />
    </item-card>

    <item-card title="Durée estimée" icon="nc-time-alarm">
      <rich-text-editor
        v-model="estimatedDuration"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="estimated-duration"
      />
    </item-card>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import PER_CONSTANTS from '@/constants/PER_CONSTANTS';
import ItemCard from './ItemCard.vue';
import RichTextEditor from '../RichTextEditor/RichTextEditor.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    ItemCard,
    RichTextEditor,
  },
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "cycle" for "per.cycle").
    ...mapFields([
      'isItemEdited',
      'itemData.instructions.availableMaterial',
      'itemData.instructions.passingInstructions',
      'itemData.instructions.successFactors',
      'itemData.instructions.possibleDidacticVariables',
      'itemData.instructions.estimatedDuration',
    ]),
    isItemDisciplineMath() {
      return this.disciplineId === PER_CONSTANTS.PER_DISCIPLINE_ID_MATH.ID;
    },
  },
};
</script>

<style scoped>
.equations-explanation {
  color: #6c757d;
  font-size: 13px;
  font-style: italic;
  margin: 0 0.75em 0.75em 0.75em;
}
</style>
