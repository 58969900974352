<template>
  <ul class="pagination" :class="paginationClass">
    <li class="page-item prev-page" :class="{ disabled: modelValue === 1 }">
      <a class="page-link" aria-label="Previous" @click="prevPage">
        <span aria-hidden="true"
          ><i class="fa fa-angle-double-left" aria-hidden="true"
        /></span>
      </a>
    </li>
    <li
      v-for="item in range(minPage, maxPage)"
      :key="item"
      class="page-item"
      :class="{ active: modelValue === item }"
    >
      <a class="page-link" @click="changePage(item)">{{ item }}</a>
    </li>
    <li
      class="page-item next-page"
      :class="{ disabled: modelValue === totalPages }"
    >
      <a class="page-link" aria-label="Next" @click="nextPage">
        <span aria-hidden="true"
          ><i class="fa fa-angle-double-right" aria-hidden="true"
        /></span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PPagination',
  props: {
    type: {
      type: String,
      default: 'default',
      description:
        'Pagination type (primary|info|danger|default|warning|success)',
    },
    pageCount: {
      type: Number,
      default: 0,
      description:
        'Pagination page count. This should be specified in combination with perPage',
    },
    perPage: {
      type: Number,
      default: 10,
      description:
        'Pagination per page. Should be specified with total or pageCount',
    },
    total: {
      type: Number,
      default: 0,
      description:
        'Can be specified instead of pageCount. The page count in this case will be total/perPage',
    },
    modelValue: {
      type: Number,
      default: 1,
      description: 'Pagination value',
    },
  },
  data() {
    return {
      defaultPagesToDisplay: 5,
    };
  },
  computed: {
    paginationClass() {
      return `pagination-${this.type}`;
    },
    totalPages() {
      if (this.pageCount > 0) return this.pageCount;
      if (this.total > 0) {
        return Math.ceil(this.total / this.perPage);
      }
      return 1;
    },
    pagesToDisplay() {
      if (this.totalPages > 0 && this.totalPages < this.defaultPagesToDisplay) {
        return this.totalPages;
      }
      return this.defaultPagesToDisplay;
    },
    minPage() {
      if (this.modelValue >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.modelValue;
        if (newMaxPage > this.totalPages) {
          return this.totalPages - this.pagesToDisplay + 1;
        }
        return this.modelValue - pagesToAdd;
      }
      return 1;
    },
    maxPage() {
      if (this.modelValue >= this.pagesToDisplay) {
        const pagesToAdd = Math.floor(this.pagesToDisplay / 2);
        const newMaxPage = pagesToAdd + this.modelValue;
        if (newMaxPage < this.totalPages) {
          return newMaxPage;
        }
        return this.totalPages;
      }
      return this.pagesToDisplay;
    },
  },
  watch: {
    /* perPage(value) {
        //this.$emit('update:modelValue', 1)
      },
      total(value) {
        //this.$emit('update:modelValue', 1)
      } */
  },
  methods: {
    range(min, max) {
      const arr = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },
    changePage(item) {
      this.$emit('update:modelValue', item);
    },
    nextPage() {
      if (this.modelValue < this.totalPages) {
        this.$emit('update:modelValue', this.modelValue + 1);
      }
    },
    prevPage() {
      if (this.modelValue > 1) {
        this.$emit('update:modelValue', this.modelValue - 1);
      }
    },
  },
};
</script>
