import {
  Radio,
  Checkbox,
  FgInput,
  Button,
  Dropdown,
  Card,
} from 'src/components/UIComponents';

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 * Ideally, only small components that are re-used many times across your application should be registered here.
 * For plugins and bigger components local registration is preferable because it will allow you to do code splitting easier :)
 */

const GlobalComponents = {
  install(app) {
    app.component(FgInput.name, FgInput);
    app.component(Dropdown.name, Dropdown);
    app.component(Checkbox.name, Checkbox);
    app.component(Radio.name, Radio);
    app.component(Button.name, Button);
    app.component(Card.name, Button);
  },
};

export default GlobalComponents;
