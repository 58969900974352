<template>
  <modal :show="show" type="notice" @close="hideModal">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>
    <div>
      <slot></slot>
      <p v-if="errorMessage" class="error-message-modal">
        {{ errorMessage }}
      </p>
    </div>
    <template #footer>
      <div class="justify-content-center d-flex p-2 w-100">
        <p-button class="mx-1 my-1 w-50" type="success" round @click="validate"
          >Valider
        </p-button>
        <p-button class="mx-1 my-1 w-50" type="light" round @click="hideModal"
          >Annuler
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from 'src/components/UIComponents';

export default {
  components: {
    Modal,
  },
  props: {
    show: {
      type: Boolean,
    },
    title: {
      type: String,
      required: true,
    },
    errorMessage: {
      type: String,
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  methods: {
    keyupHandler(event) {
      if (this.show) {
        if (event.key === 'Enter') {
          this.validate();
        } else if (event.key === 'Escape') {
          this.hideModal();
        }
      }
    },
    hideModal() {
      this.$emit('hideModal');
    },
    validate() {
      this.$emit('validate');
    },
  },
};
</script>

<style scoped>
.error-message-modal {
  margin: 2em 0 0 0;
  color: darkred;
}
</style>
