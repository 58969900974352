export default [
  {
    name: 'Items',
    icon: 'nc-icon nc-single-copy-04',
    path: '/admin/items/',
  },
  {
    name: 'Matériaux Externes',
    icon: 'nc-icon nc-paper',
    children: [
      {
        name: 'Tâches (Français)',
        path: '/data/francaisSimpleMaterials',
      },
      {
        name: 'Textes/corpus (Français)',
        path: '/data/francaisAdvancedMaterials',
      },
      {
        name: 'Mathématiques',
        path: '/data/mathMaterials',
      },
    ],
  },
  {
    name: 'Données de base',
    icon: 'nc-icon nc-box',
    children: [
      {
        name: 'Synchronisation PER',
        path: '/data/synchroPER',
      },
    ],
  },
  {
    name: 'Support',
    icon: 'fa fa-life-ring',
    path: process.env.VUE_APP_SUPPORT_URL,
    target: '_blank',
    rel: 'noopener',
  },
];
