<template>
  <new-element-modal
    :show="show"
    title="Ajouter une progression d'apprentissage à l'axe thématique"
    :errorMessage="errorMessage"
    @hideModal="hideModalProgression"
    @validate="addProgressionToThematic"
  >
    <progression-select
      v-if="show"
      v-model="selectedProgressionData"
      :learning-objective-id="learningObjectiveId"
      :years="years"
    />
  </new-element-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import NewElementModal from './NewElementModal.vue';
import ProgressionSelect from '../UIComponents/ProgressionSelect.vue';

export default {
  components: {
    NewElementModal,
    ProgressionSelect,
  },
  props: {
    show: {
      type: Boolean,
    },
    itemId: {
      type: Number,
      required: true,
    },
    itemThematicId: {
      type: Number,
    },
    learningObjectiveId: {
      type: Number,
    },
    years: {
      type: Array,
    },
  },
  data() {
    return {
      selectedProgressionData: null,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      doesThematicAxisContainProgression:
        'item/doesThematicAxisContainProgression',
    }),
  },
  watch: {
    selectedProgressionData: {
      handler() {
        this.errorMessage = '';
      },
    },
  },
  methods: {
    resetData() {
      this.selectedProgressionData = null;
      this.errorMessage = '';
    },
    hideModalProgression() {
      this.resetData();
      this.$emit('hideModalProgression');
    },
    addProgressionToThematic() {
      if (this.selectedProgressionData) {
        this.errorMessage = '';

        if (
          this.doesThematicAxisContainProgression(
            this.itemThematicId,
            this.selectedProgressionData.id
          )
        ) {
          this.errorMessage =
            "Cette progression est déjà associée à l'axe thématique sélectionné.";
        } else {
          this.$emit('addProgressionToThematic', this.selectedProgressionData);
          this.resetData();
        }
      } else {
        this.errorMessage =
          "Veuillez sélectionner une progression d'apprentissage.";
      }
    },
  },
};
</script>
