<template>
  <span class="bg-gray-100 px-2 py-1" contenteditable="false">
    <span ref="render" class="katex-render"></span>
  </span>
</template>

<script>
import katex from 'katex';
import 'katex/dist/katex.min.css';

export default {
  props: {
    equation: {
      type: String,
      required: true,
    },
  },
  watch: {
    equation() {
      this.render(this.equation);
    },
  },
  mounted() {
    this.render(this.equation);
  },
  methods: {
    // Updates katex-render with node equation.
    render(equation) {
      katex.render(equation, this.$refs.render, {
        throwOnError: false,
        displayMode: false,
      });
    },
  },
};
</script>
