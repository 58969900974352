// Directive that triggers an event when the user clicked outside the target component.
const clickOutside = {
  beforeMount: (el, binding) => {
    // eslint-disable-next-line no-param-reassign
    el.clickOutsideEvent = (event) => {
      // here I check that click was outside the el and his children
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value();
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
};

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */
const GlobalDirectives = {
  install(app) {
    app.directive('click-outside', clickOutside);
  },
};

export default GlobalDirectives;
