<template>
  <new-element-modal
    :show="show"
    title="Ajouter un axe thématique"
    :errorMessage="errorMessage"
    @hideModal="hideModalThematic"
    @validate="addThematicToItem"
  >
    <thematic-select
      v-if="show"
      v-model="selectedThematicData"
      :discipline-id="disciplineId"
      @clear="resetData"
    />
    <div v-if="learningObjective.code" class="item-addition-learning-objective">
      <label>Objectif d'apprentissage</label>
      <div class="item-addition-learning-objective-content">
        <h6>{{ learningObjective.code }}</h6>
        <p>{{ learningObjective.name }}</p>
      </div>
    </div>
  </new-element-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import COMMON from '@/constants/COMMON';
import NewElementModal from './NewElementModal.vue';
import ThematicSelect from '../UIComponents/ThematicSelect.vue';

export default {
  components: {
    NewElementModal,
    ThematicSelect,
  },
  props: {
    show: {
      type: Boolean,
    },
    itemId: {
      type: Number,
      required: true,
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    cycle: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selectedThematicData: null,
      learningObjective: {
        code: null,
        name: null,
      },
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      doesItemContainThematicAxis: 'item/doesItemContainThematicAxis',
    }),
  },
  watch: {
    selectedThematicData: {
      handler(newVal) {
        this.errorMessage = '';

        if (newVal) {
          this.$http
            .get(
              `/learningObjectives/search/byDisciplineCycleAndThematic?disciplineId=${this.disciplineId}&cycleId=${this.cycle}&thematicId=${newVal.id}`
            )
            .then((payload) => {
              this.learningObjective = payload.data;
            })
            .catch(() => {
              this.errorMessage = COMMON.BASIC_ERROR;
            });
        }
      },
    },
  },
  methods: {
    resetData() {
      this.selectedThematicData = null;
      this.learningObjective = {};
      this.errorMessage = '';
    },
    hideModalThematic() {
      this.resetData();
      this.$emit('hideModalThematic');
    },
    addThematicToItem() {
      if (this.selectedThematicData) {
        this.errorMessage = '';

        if (this.doesItemContainThematicAxis(this.selectedThematicData.id)) {
          this.errorMessage =
            "Cet axe thématique est déjà associé à l'item actuel.";
        } else {
          this.$emit('addThematicToItem', {
            itemThematic: this.selectedThematicData,
            learningObjective: this.learningObjective,
          });
          this.resetData();
        }
      } else {
        this.errorMessage = 'Veuillez sélectionner un axe thématique.';
      }
    },
  },
};
</script>
