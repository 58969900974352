<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="hideMathEquationModal"
  >
    <template #header>
      <h5 class="modal-title">Ajouter une équation mathématique</h5>
    </template>
    <math-equation-form v-model="mutableEquation" />
    <template #footer>
      <div class="justify-content-center d-flex p-2 w-100">
        <p-button
          class="mx-1 my-1 w-50"
          type="light"
          round
          @click="hideMathEquationModal"
          >Annuler
        </p-button>
        <p-button
          class="mx-1 my-1 w-50"
          type="success"
          round
          :disabled="!mutableEquation"
          @click="insertOrUpdateMathEquation"
          >{{ insertOrUpdateButtonLabel }}
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from 'src/components/UIComponents';
import MathEquationForm from '../UIComponents/RichTextEditor/extensions/mathEquation/MathEquationForm.vue';

export default {
  components: {
    Modal,
    MathEquationForm,
  },
  props: {
    show: {
      type: Boolean,
    },
    equation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      mutableEquation: '',
    };
  },
  watch: {
    equation(value) {
      this.mutableEquation = value;
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  computed: {
    insertOrUpdateButtonLabel() {
      if (this.equation) {
        return "Modifier l'équation";
      }

      return "Insérer l'équation";
    },
  },
  methods: {
    keyupHandler(event) {
      if (this.show) {
        if (event.key === 'Enter') {
          this.insertOrUpdateMathEquation();
        } else if (event.key === 'Escape') {
          this.hideMathEquationModal();
        }
      }
    },
    resetData() {
      this.mutableEquation = '';
    },
    hideMathEquationModal() {
      this.resetData();
      this.$emit('hideMathEquationModal');
    },
    insertOrUpdateMathEquation() {
      if (this.mutableEquation) {
        // Update the existing equation if the `equation` props was given.
        if (this.equation) {
          this.$emit('updateMathEquation', this.mutableEquation);
        } else {
          this.$emit('insertMathEquation', this.mutableEquation);
        }

        this.hideMathEquationModal();
      }
    },
  },
};
</script>
