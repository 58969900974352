import ROUTES_NAMES from '@/constants/ROUTES_NAMES';

// EPROCOM application specific components
import SynchroPER from 'src/components/ApplicationViews/SynchroPER.vue';
import FrancaisAdvancedMaterials from 'src/components/ApplicationViews/FrancaisAdvancedMaterials.vue';
import MathMaterials from 'src/components/ApplicationViews/MathMaterials.vue';
import FrancaisAdvancedMaterialDetails from 'src/components/ApplicationViews/FrancaisAdvancedMaterialDetails.vue';
import FrancaisSimpleMaterials from 'src/components/ApplicationViews/FrancaisSimpleMaterials.vue';
import FrancaisSimpleMaterialDetails from 'src/components/ApplicationViews/FrancaisSimpleMaterialDetails.vue';
import MathMaterialDetails from 'src/components/ApplicationViews/MathMaterialDetails.vue';
import Items from 'src/components/ApplicationViews/Items.vue';
import ItemDetails from 'src/components/ApplicationViews/ItemDetails.vue';
import NotAllowed from '../components/GeneralViews/NotAllowed.vue';
import NotFound from '../components/GeneralViews/NotFoundPage.vue';
import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue';

const coreDataMenu = {
  path: '/data/',
  redirect: '/data/synchroPER',
  component: DashboardLayout,
  children: [
    {
      path: 'synchroPER',
      name: ROUTES_NAMES.SYNCHRO_PER,
      component: SynchroPER,
      permissions: {
        lvl: 2,
      },
    },
    {
      path: 'francaisAdvancedMaterials',
      name: ROUTES_NAMES.FRANCAIS_ADVANCED_MATERIALS,
      component: FrancaisAdvancedMaterials,
      permissions: {
        lvl: 2,
      },
    },
    {
      path: 'francaisAdvancedMaterials/:id',
      name: ROUTES_NAMES.FRANCAIS_ADVANCED_MATERIAL_DETAILS,
      component: FrancaisAdvancedMaterialDetails,
      permissions: {
        lvl: 2,
      },
    },
    {
      path: 'francaisSimpleMaterials',
      name: ROUTES_NAMES.FRANCAIS_SIMPLE_MATERIALS,
      component: FrancaisSimpleMaterials,
      permissions: {
        lvl: 2,
      },
    },
    {
      path: 'francaisSimpleMaterials/:id',
      name: ROUTES_NAMES.FRANCAIS_SIMPLE_MATERIAL_DETAILS,
      component: FrancaisSimpleMaterialDetails,
      permissions: {
        lvl: 2,
      },
    },
    {
      path: 'mathMaterials',
      name: ROUTES_NAMES.MATH_MATERIALS,
      component: MathMaterials,
      permissions: {
        lvl: 2,
      },
    },
    {
      path: 'mathMaterials/:id',
      name: ROUTES_NAMES.MATH_MATERIAL_DETAILS,
      component: MathMaterialDetails,
      permissions: {
        lvl: 2,
      },
    },
  ],
};

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/admin/items',
  },
  coreDataMenu,
  {
    path: '/admin/',
    component: DashboardLayout,
    redirect: '/admin/items',
    children: [
      {
        path: 'items/',
        name: ROUTES_NAMES.ITEMS,
        component: Items,
        permissions: {
          lvl: 2,
        },
      },
      {
        path: 'items/:id',
        name: ROUTES_NAMES.ITEM_DETAILS,
        component: ItemDetails,
        permissions: {
          lvl: 2,
        },
      },
    ],
  },
  {
    path: '/not-allowed',
    name: 'NotAllowed',
    component: NotAllowed,
    permissions: {
      lvl: 0,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    permissions: {
      lvl: 0,
    },
  },
];

export default routes;
