import lodash from 'lodash';
import ITEM_METADATA from '@/constants/ITEM_METADATA';

/* eslint no-param-reassign: ["error", { "props": false }] */
function difference(objectD, baseD) {
  function changes(objectC, baseC) {
    return lodash.transform(objectC, (result, value, key) => {
      if (!lodash.isEqual(value, baseC[key])) {
        if (lodash.isObject(value) && lodash.isObject(baseC[key])) {
          result[key] = changes(value, baseC[key]);
        } else {
          result[key] = {
            newValue: value,
            oldValue: baseC[key],
          };
        }
      }
    });
  }

  return changes(objectD, baseD);
}

// Transform the given object of differences as an array that contains a french version of the labels of every fields that
// were updated and the old and new values of those fields (used for the elements in the history tab).
// For some select fields we want to save the integer value (e.g. 0) in the database, but we want to display the associated label
// (e.g. "Complexité basse") in the historical panel.
function buildItemDataHistory(differences) {
  const updatedItemDataArray = [];

  if (differences.management) {
    if (differences.management.status) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'statut',
        fieldKey: 'status',
        oldValue: differences.management.status.oldValue,
        newValue: differences.management.status.newValue,
      });
    }

    if (differences.management.cantonOrigin) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: "canton d'origine",
        fieldKey: 'cantonOrigin',
        oldValue: differences.management.cantonOrigin.oldValue,
        newValue: differences.management.cantonOrigin.newValue,
      });
    }

    if (differences.management.passingYears) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: "l'",
        fieldLabel: 'année scolaire de passation',
        fieldKey: 'itemPassingYears',
        oldValue: differences.management.passingYears.oldValue,
        newValue: differences.management.passingYears.newValue,
      });
    }

    if (differences.management.computerizedItem) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'champ "item informatisé"',
        fieldKey: 'computerizedItem',
        oldValue: differences.management.computerizedItem.oldValue,
        newValue: differences.management.computerizedItem.newValue,
      });
    }

    if (differences.management.functionalitiesComputerizedItem) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'les',
        fieldLabel: 'fonctionnalités de la version informatisée',
        fieldKey: 'functionalitiesComputerizedItem',
        oldValue:
          differences.management.functionalitiesComputerizedItem.oldValue,
        newValue:
          differences.management.functionalitiesComputerizedItem.newValue,
      });
    }

    if (differences.management.visibility) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'la',
        fieldLabel: 'visibilité',
        fieldKey: 'visibility',
        oldValue: differences.management.visibility.oldValue,
        newValue: differences.management.visibility.newValue,
      });
    }

    if (differences.management.multipleItem) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'champ "item multiple"',
        fieldKey: 'multipleItem',
        oldValue: differences.management.multipleItem.oldValue,
        newValue: differences.management.multipleItem.newValue,
      });
    }

    if (differences.management.belongsToMultipleItem) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'champ "fait partie d\'un item multiple"',
        fieldKey: 'belongsToMultipleItem',
        oldValue: differences.management.belongsToMultipleItem.oldValue,
        newValue: differences.management.belongsToMultipleItem.newValue,
      });
    }
  }

  if (differences.per) {
    if (differences.per.interdisciplinaryLink) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'lien interdisciplinaire',
        fieldKey: 'interdisciplinaryLink',
        oldValue: differences.per.interdisciplinaryLink.name.oldValue,
        newValue: differences.per.interdisciplinaryLink.name.newValue,
      });
    }

    if (differences.per.interdisciplinaryLinkDetails) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'les',
        fieldLabel: 'détails du lien interdisciplinaire',
        fieldKey: 'interdisciplinaryLinkDetails',
        oldValue: differences.per.interdisciplinaryLinkDetails.oldValue,
        newValue: differences.per.interdisciplinaryLinkDetails.newValue,
      });
    }
  }

  if (differences.disciplineRelatedData) {
    if (differences.disciplineRelatedData.french) {
      if (differences.disciplineRelatedData.french.contentDifficulty) {
        if (
          differences.disciplineRelatedData.french.contentDifficulty
            .operationType
        ) {
          updatedItemDataArray.push({
            operationType: 'update',
            fieldDeterminant: 'la',
            fieldLabel: "difficulté pour le contenu - type d'opérations",
            fieldKey: 'contentDiffOperationType',
            oldValue:
              differences.disciplineRelatedData.french.contentDifficulty
                .operationType.oldValue,
            newValue:
              differences.disciplineRelatedData.french.contentDifficulty
                .operationType.newValue,
          });
        }

        if (
          differences.disciplineRelatedData.french.contentDifficulty
            .operationTypeIndex
        ) {
          updatedItemDataArray.push({
            operationType: 'update',
            fieldDeterminant: 'la',
            fieldLabel: "difficulté pour le contenu - indice type d'opérations",
            fieldKey: 'contentDiffOperationTypeIndex',
            oldValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.contentDifficulty
                .operationTypeIndex.oldValue
            ),
            newValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.contentDifficulty
                .operationTypeIndex.newValue
            ),
          });
        }

        if (
          differences.disciplineRelatedData.french.contentDifficulty
            .operationNumber
        ) {
          updatedItemDataArray.push({
            operationType: 'update',
            fieldDeterminant: 'la',
            fieldLabel: 'difficulté pour le contenu - nombre de manipulations',
            fieldKey: 'contentDiffOperationNumber',
            oldValue:
              differences.disciplineRelatedData.french.contentDifficulty
                .operationNumber.oldValue,
            newValue:
              differences.disciplineRelatedData.french.contentDifficulty
                .operationNumber.newValue,
          });
        }

        if (
          differences.disciplineRelatedData.french.contentDifficulty
            .operationNumberIndex
        ) {
          updatedItemDataArray.push({
            operationType: 'update',
            fieldDeterminant: 'la',
            fieldLabel:
              'difficulté pour le contenu - indice nombre de manipulations',
            fieldKey: 'contentDiffOperationNumberIndex',
            oldValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.contentDifficulty
                .operationNumberIndex.oldValue
            ),
            newValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.contentDifficulty
                .operationNumberIndex.newValue
            ),
          });
        }

        if (
          differences.disciplineRelatedData.french.contentDifficulty
            .languageObjectAmplitude
        ) {
          updatedItemDataArray.push({
            operationType: 'update',
            fieldDeterminant: 'la',
            fieldLabel: 'difficulté pour le contenu - ampleur objet langagier',
            fieldKey: 'contentDiffLanguageObjectAmplitude',
            oldValue:
              differences.disciplineRelatedData.french.contentDifficulty
                .languageObjectAmplitude.oldValue,
            newValue:
              differences.disciplineRelatedData.french.contentDifficulty
                .languageObjectAmplitude.newValue,
          });
        }

        if (
          differences.disciplineRelatedData.french.contentDifficulty
            .languageObjectAmplitudeIndex
        ) {
          updatedItemDataArray.push({
            operationType: 'update',
            fieldDeterminant: 'la',
            fieldLabel:
              'difficulté pour le contenu - indice ampleur objet langagier',
            fieldKey: 'contentDiffLanguageObjectAmplitudeIndex',
            oldValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.contentDifficulty
                .languageObjectAmplitudeIndex.oldValue
            ),
            newValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.contentDifficulty
                .languageObjectAmplitudeIndex.newValue
            ),
          });
        }
      }

      if (differences.disciplineRelatedData.french.envelopeDifficulty) {
        if (
          differences.disciplineRelatedData.french.envelopeDifficulty
            .instructionIndex
        ) {
          updatedItemDataArray.push({
            operationType: 'update',
            fieldDeterminant: 'la',
            fieldLabel: "difficulté pour l'enveloppe - indice consigne",
            fieldKey: 'envelopDiffInstruction',
            oldValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.envelopeDifficulty
                .instructionIndex.oldValue
            ),
            newValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.envelopeDifficulty
                .instructionIndex.newValue
            ),
          });
        }

        if (
          differences.disciplineRelatedData.french.envelopeDifficulty
            .questionFormatIndex
        ) {
          updatedItemDataArray.push({
            operationType: 'update',
            fieldDeterminant: 'la',
            fieldLabel:
              "difficulté pour l'enveloppe - indice format de questionnement",
            fieldKey: 'envelopDiffQuestionFormat',
            oldValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.envelopeDifficulty
                .questionFormatIndex.oldValue
            ),
            newValue: ITEM_METADATA.DIFFICULTIES_VALUES.get(
              differences.disciplineRelatedData.french.envelopeDifficulty
                .questionFormatIndex.newValue
            ),
          });
        }

        if (
          differences.disciplineRelatedData.french.envelopeDifficulty.material
        ) {
          updatedItemDataArray.push({
            operationType: 'update',
            fieldDeterminant: 'la',
            fieldLabel: "difficulté pour l'enveloppe - matériel",
            fieldKey: 'envelopDiffMaterial',
            oldValue: ITEM_METADATA.MATERIAL_PRESENT_OPTIONS.get(
              differences.disciplineRelatedData.french.envelopeDifficulty
                .material.oldValue
            ),
            newValue: ITEM_METADATA.MATERIAL_PRESENT_OPTIONS.get(
              differences.disciplineRelatedData.french.envelopeDifficulty
                .material.newValue
            ),
          });
        }
      }
    }

    if (differences.disciplineRelatedData.mathematics) {
      if (differences.disciplineRelatedData.mathematics.taskCategorization) {
        updatedItemDataArray.push({
          operationType: 'update',
          fieldDeterminant: 'la',
          fieldLabel: 'catégorisation de la tâche',
          fieldKey: 'taskCategorization',
          oldValue:
            differences.disciplineRelatedData.mathematics.taskCategorization
              .oldValue,
          newValue:
            differences.disciplineRelatedData.mathematics.taskCategorization
              .newValue,
        });
      }

      if (differences.disciplineRelatedData.mathematics.imageFunction) {
        updatedItemDataArray.push({
          operationType: 'update',
          fieldDeterminant: 'le',
          fieldLabel: "statut/fonction de l'image",
          fieldKey: 'imageFunction',
          oldValue:
            differences.disciplineRelatedData.mathematics.imageFunction
              .oldValue,
          newValue:
            differences.disciplineRelatedData.mathematics.imageFunction
              .newValue,
        });
      }

      if (
        differences.disciplineRelatedData.mathematics
          .contextualComplexityFactorIndex
      ) {
        updatedItemDataArray.push({
          operationType: 'update',
          fieldDeterminant: 'le',
          fieldLabel: 'facteur de complexité contextuelle',
          fieldKey: 'mathContextualComplexityFactor',
          oldValue: ITEM_METADATA.MATH_COMPLEXITY_FACTOR.get(
            differences.disciplineRelatedData.mathematics
              .contextualComplexityFactorIndex.oldValue
          ),
          newValue: ITEM_METADATA.MATH_COMPLEXITY_FACTOR.get(
            differences.disciplineRelatedData.mathematics
              .contextualComplexityFactorIndex.newValue
          ),
        });
      }

      if (differences.disciplineRelatedData.mathematics.complexityFactorIndex) {
        updatedItemDataArray.push({
          operationType: 'update',
          fieldDeterminant: 'le',
          fieldLabel: 'facteur de complexité mathématique',
          fieldKey: 'mathComplexityFactor',
          oldValue: ITEM_METADATA.MATH_COMPLEXITY_FACTOR.get(
            differences.disciplineRelatedData.mathematics.complexityFactorIndex
              .oldValue
          ),
          newValue: ITEM_METADATA.MATH_COMPLEXITY_FACTOR.get(
            differences.disciplineRelatedData.mathematics.complexityFactorIndex
              .newValue
          ),
        });
      }

      if (differences.disciplineRelatedData.mathematics.competenceLevelIndex) {
        updatedItemDataArray.push({
          operationType: 'update',
          fieldDeterminant: 'le',
          fieldLabel: 'niveau de compétence',
          fieldKey: 'mathCompetenceLevelFactor',
          oldValue: ITEM_METADATA.MATH_COMPETENCE_LEVEL_FACTOR.get(
            differences.disciplineRelatedData.mathematics.competenceLevelIndex
              .oldValue
          ),
          newValue: ITEM_METADATA.MATH_COMPETENCE_LEVEL_FACTOR.get(
            differences.disciplineRelatedData.mathematics.competenceLevelIndex
              .newValue
          ),
        });
      }

      if (differences.disciplineRelatedData.mathematics.actionsAndOperations) {
        updatedItemDataArray.push({
          operationType: 'update',
          fieldDeterminant: 'les',
          fieldLabel: 'actions et opérations attendues',
          fieldKey: 'actionsAndOperations',
          oldValue:
            differences.disciplineRelatedData.mathematics.actionsAndOperations
              .oldValue,
          newValue:
            differences.disciplineRelatedData.mathematics.actionsAndOperations
              .newValue,
        });
      }
    }

    if (differences.disciplineRelatedData.multiple) {
      if (differences.disciplineRelatedData.multiple.questioningFormat) {
        updatedItemDataArray.push({
          operationType: 'update',
          fieldDeterminant: 'le',
          fieldLabel: 'format de questionnement',
          fieldKey: 'questioningFormat',
          oldValue:
            differences.disciplineRelatedData.multiple.questioningFormat
              .oldValue,
          newValue:
            differences.disciplineRelatedData.multiple.questioningFormat
              .newValue,
        });
      }

      if (differences.disciplineRelatedData.multiple.difficultyAfterthought) {
        updatedItemDataArray.push({
          operationType: 'update',
          fieldDeterminant: 'la',
          fieldLabel: 'difficulté à postériori',
          fieldKey: 'difficultyAfterthought',
          oldValue:
            differences.disciplineRelatedData.multiple.difficultyAfterthought
              .oldValue,
          newValue:
            differences.disciplineRelatedData.multiple.difficultyAfterthought
              .newValue,
        });
      }
    }
  }

  if (differences.contentAndSolution) {
    if (differences.contentAndSolution.generalTaskDescription) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'la',
        fieldLabel: 'description générale de la tâche',
        fieldKey: 'generalTaskDescription',
        oldValue:
          differences.contentAndSolution.generalTaskDescription.oldValue,
        newValue:
          differences.contentAndSolution.generalTaskDescription.newValue,
      });
    }

    if (differences.contentAndSolution.title) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'titre',
        fieldKey: 'title',
        oldValue: differences.contentAndSolution.title.oldValue,
        newValue: differences.contentAndSolution.title.newValue,
      });
    }

    if (differences.contentAndSolution.content) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'contenu',
        fieldKey: 'content',
        oldValue: differences.contentAndSolution.content.oldValue,
        newValue: differences.contentAndSolution.content.newValue,
      });
    }

    if (differences.contentAndSolution.variant) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'la',
        fieldLabel: 'variante',
        fieldKey: 'variant',
        oldValue: differences.contentAndSolution.variant.oldValue,
        newValue: differences.contentAndSolution.variant.newValue,
      });
    }

    if (differences.contentAndSolution.solution) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'corrigé',
        fieldKey: 'solution',
        oldValue: differences.contentAndSolution.solution.oldValue,
        newValue: differences.contentAndSolution.solution.newValue,
      });
    }

    if (differences.contentAndSolution.associatedMaterial) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'matériel associé',
        fieldKey: 'associatedMaterial',
        oldValue: differences.contentAndSolution.associatedMaterial.oldValue,
        newValue: differences.contentAndSolution.associatedMaterial.newValue,
      });
    }

    // The external materials are managed in another function of this service.
  }

  if (differences.instructions) {
    if (differences.instructions.availableMaterial) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'le',
        fieldLabel: 'matériel à disposition',
        fieldKey: 'availableMaterial',
        oldValue: differences.instructions.availableMaterial.oldValue,
        newValue: differences.instructions.availableMaterial.newValue,
      });
    }

    if (differences.instructions.passingInstructions) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'les',
        fieldLabel: 'consignes de passation',
        fieldKey: 'passingInstructions',
        oldValue: differences.instructions.passingInstructions.oldValue,
        newValue: differences.instructions.passingInstructions.newValue,
      });
    }

    if (differences.instructions.successFactors) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'les',
        fieldLabel: 'critères et indicateurs de réussite',
        fieldKey: 'successFactors',
        oldValue: differences.instructions.successFactors.oldValue,
        newValue: differences.instructions.successFactors.newValue,
      });
    }

    if (differences.instructions.possibleDidacticVariables) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'les',
        fieldLabel: 'possibles variables didactiques',
        fieldKey: 'possibleDidacticVariables',
        oldValue: differences.instructions.possibleDidacticVariables.oldValue,
        newValue: differences.instructions.possibleDidacticVariables.newValue,
      });
    }

    if (differences.instructions.estimatedDuration) {
      updatedItemDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'la',
        fieldLabel: 'durée estimée',
        fieldKey: 'estimatedDuration',
        oldValue: differences.instructions.estimatedDuration.oldValue,
        newValue: differences.instructions.estimatedDuration.newValue,
      });
    }
  }

  return updatedItemDataArray;
}

// Transform the given object of item's external materials' changes as an array that contains a french version of the name of every
// material that was added or removed (used for the elements in the history tab).
function buildItemExternalMaterialsHistory(itemExternalMaterialsChanges) {
  const updatedItemExternalMaterialsDataArray = [];
  const deletedMaterialsEntries = Object.entries(
    itemExternalMaterialsChanges.deleted
  );
  const addedMaterialsEntries = Object.entries(
    itemExternalMaterialsChanges.added
  );

  // Write a history element for each deleted item, by indicating its type (e.g. "matériel externe complexe (textes)").
  for (const [materialType, materialsData] of deletedMaterialsEntries) {
    const materialTypeLabel =
      ITEM_METADATA.EXTERNAL_MATERIAL_TYPES[materialType].historyLabel;

    materialsData.forEach((materialData) => {
      updatedItemExternalMaterialsDataArray.push({
        operationType: 'delete',
        fieldDeterminant: 'le',
        fieldLabel: `matériel externe (${materialTypeLabel}) "${materialData.name}" de l'item`,
      });
    });
  }

  // Write a history element for each added item, by indicating its type (e.g. "matériel externe complexe (textes)").
  for (const [materialType, materialsData] of addedMaterialsEntries) {
    const materialTypeLabel =
      ITEM_METADATA.EXTERNAL_MATERIAL_TYPES[materialType].historyLabel;

    materialsData.forEach((materialData) => {
      updatedItemExternalMaterialsDataArray.push({
        operationType: 'add',
        fieldDeterminant: 'le',
        fieldLabel: `matériel externe (${materialTypeLabel}) "${materialData.name}" à l'item`,
      });
    });
  }

  // Check if the materials order changed.
  const hasMaterialsOrderChanged =
    JSON.stringify(itemExternalMaterialsChanges.baseOrder) !==
    JSON.stringify(itemExternalMaterialsChanges.newOrder);

  if (hasMaterialsOrderChanged) {
    updatedItemExternalMaterialsDataArray.push({
      operationType: 'update',
      fieldDeterminant: "l'",
      fieldLabel: "ordre d'affichage des matériaux externes",
    });
  }

  return updatedItemExternalMaterialsDataArray;
}

// Transform the given object of item thematics' changes as an array that contains a french version of the labels of every
// fields that were updated and the old and new values of those fields (used for the elements in the history tab).
function buildItemThematicsHistory(itemThematicsChanges) {
  const updatedItemThematicsDataArray = [];

  itemThematicsChanges.deleted.forEach((itName) => {
    updatedItemThematicsDataArray.push({
      operationType: 'delete',
      fieldDeterminant: 'la',
      fieldLabel: `thématique "${itName}" de l'item`,
    });
  });

  itemThematicsChanges.added.forEach((itName) => {
    updatedItemThematicsDataArray.push({
      operationType: 'add',
      fieldDeterminant: 'la',
      fieldLabel: `thématique "${itName}" à l'item`,
    });
  });

  itemThematicsChanges.updated.forEach((data) => {
    data.chapters.deleted.forEach((deletedChapterName) => {
      updatedItemThematicsDataArray.push({
        operationType: 'delete',
        fieldDeterminant: 'le',
        fieldLabel: `chapitre "${deletedChapterName}" de l'axe thématique "${data.name}"`,
      });
    });

    data.chapters.added.forEach((addedChapterName) => {
      updatedItemThematicsDataArray.push({
        operationType: 'add',
        fieldDeterminant: 'le',
        fieldLabel: `chapitre "${addedChapterName}" à l'axe thématique "${data.name}"`,
      });
    });

    data.chapters.updated.forEach((values, description) => {
      if (values.textualGenre) {
        updatedItemThematicsDataArray.push({
          operationType: 'update',
          fieldDeterminant: 'le',
          fieldLabel: `genre textuel du chapitre "${description}" de l'axe thématique "${data.name}"`,
          oldValue: values.textualGenre.oldValue,
          newValue: values.textualGenre.newValue,
        });
      }
    });

    if (
      data.years.newValue !== undefined &&
      data.years.oldValue !== undefined
    ) {
      updatedItemThematicsDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'les',
        fieldLabel: `années des apprentissages concernés de l'axe thématique "${data.name}"`,
        oldValue: data.years.oldValue.join(', '),
        newValue: data.years.newValue.join(', '),
      });
    }

    data.progressions.deleted.forEach((deletedProgressionName) => {
      updatedItemThematicsDataArray.push({
        operationType: 'delete',
        fieldDeterminant: 'la',
        fieldLabel: `progression "${deletedProgressionName}" de l'axe thématique "${data.name}"`,
      });
    });

    data.progressions.added.forEach((addedProgressionName) => {
      updatedItemThematicsDataArray.push({
        operationType: 'add',
        fieldDeterminant: 'la',
        fieldLabel: `progression "${addedProgressionName}" à l'axe thématique "${data.name}"`,
      });
    });

    if (
      data.progressionsSpecificElements.newValue !== undefined &&
      data.progressionsSpecificElements.oldValue !== undefined
    ) {
      updatedItemThematicsDataArray.push({
        operationType: 'update',
        fieldDeterminant: 'les',
        fieldLabel: `éléments spécifiques des progressions de l'axe thématique "${data.name}"`,
        oldValue: data.progressionsSpecificElements.oldValue,
        newValue: data.progressionsSpecificElements.newValue,
      });
    }
  });

  return updatedItemThematicsDataArray;
}

export {
  difference,
  buildItemDataHistory,
  buildItemExternalMaterialsHistory,
  buildItemThematicsHistory,
};
