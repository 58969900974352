<template>
  <div>
    <label>{{ title }}</label>
    <div class="row form-group2">
      <el-select
        :disabled="disabled"
        :modelValue="modelValue"
        class="select-info flex-grow-1"
        size="large"
        placeholder="Sélectionner"
        @update:modelValue="$emit('update:modelValue', $event)"
      >
        <!-- <option disabled value="">Please select one</option> -->
        <el-option
          v-for="option in selects.default"
          :value="option.value"
          :label="option.label"
          :key="option.label"
          class="select-info"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectFrancaisGenreGrouping',
  props: ['modelValue', 'title', 'disabled'],
  data() {
    return {
      selects: {
        simple: '',
        default: [
          {
            key: 'key',
            value: 'value',
          },
        ],
      },
    };
  },
  created() {
    this.populate();
    this.selects.simple = this.modelValue;
  },
  methods: {
    populate() {
      const values = [
        {
          value: 'Le texte qui raconte',
          label: 'Le texte qui raconte',
        },
        {
          value: 'Le texte qui relate',
          label: 'Le texte qui relate',
        },
        {
          value: 'Le texte qui argumente',
          label: 'Le texte qui argumente',
        },
        {
          value: 'Le texte qui transmet des savoirs',
          label: 'Le texte qui transmet des savoirs',
        },
        {
          value: 'Le texte qui règle des comportements',
          label: 'Le texte qui règle des comportements',
        },
        {
          value: 'Le texte qui joue avec la langue',
          label: 'Le texte qui joue avec la langue',
        },
      ];
      this.selects.default = values.map((element) => ({
        value: element.value,
        label: element.label,
      }));
    },
  },
};
</script>
