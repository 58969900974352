import { createStore } from 'vuex';

import item from './modules/item';
import material from './modules/material';
import account from './modules/account';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
  modules: {
    item,
    material,
    account,
  },
  strict: debug,
});
