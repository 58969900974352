<template>
  <div>
    <label>Genre textuel</label>
    <div class="row form-group2">
      <el-select
        :modelValue="textualGenre"
        :disabled="disabled"
        class="select-info"
        size="large"
        placeholder="Sélectionner"
        @change="$emit('change', $event)"
      >
        <el-option
          v-for="textualGenre in getTextualGenres()"
          :value="textualGenre"
          :label="textualGenre"
          :key="textualGenre"
          class="select-info"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import ITEM_METADATA from '@/constants/ITEM_METADATA';

export default {
  props: {
    textualGenre: {
      type: String,
    },
    learningObjectiveId: {
      type: Number,
      required: true,
    },
    chapter: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  methods: {
    getTextualGenres() {
      const recommendedGenresChapters =
        ITEM_METADATA.RECOMMENDED_GENRES.learningObjectives.get(
          this.learningObjectiveId
        )?.chapters;
      const recommendedGenresByYears = recommendedGenresChapters?.get(
        this.chapter
      )?.recommendedGenresByYears;

      if (recommendedGenresByYears) {
        // Concat all the years' recommended genres in one array.
        const mergedRecommendedGenres = Object.entries(
          recommendedGenresByYears
        ).reduce((accumulator, [key, value]) => accumulator.concat(value), []);
        // Then remove the possible duplicates.
        const mergedRecommendedGenresWithoutDuplicates =
          mergedRecommendedGenres.filter(
            (recommendedGenres, position) =>
              mergedRecommendedGenres.indexOf(recommendedGenres) === position
          );
        return mergedRecommendedGenresWithoutDuplicates;
      }

      return [];
    },
  },
};
</script>
