<template>
  <div>
    <item-card title="Classification PER" icon="nc-map-big">
      <h6>{{ discipline.name }}</h6>
      <p>
        <strong>Cycle {{ cycle }}</strong>
      </p>

      <thematic-axes-accordion />
    </item-card>

    <item-card title="Lien interdisciplinaire" icon="nc-vector">
      <discipline-select
        v-model="interdisciplinaryLink"
        :disabled="!isItemEdited"
        :is-interdisciplinary-link="true"
      />
      <div v-if="interdisciplinaryLink.id">
        <fg-input
          v-model="interdisciplinaryLinkDetails"
          :disabled="!isItemEdited"
          type="text"
          label="Détails du lien interdisciplinaire"
        />
      </div>
    </item-card>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import DisciplineSelect from '../DisciplineSelect.vue';
import ItemCard from './ItemCard.vue';
import ThematicAxesAccordion from './ThematicAxesAccordion.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    ItemCard,
    ThematicAxesAccordion,
    DisciplineSelect,
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "cycle" for "per.cycle").
    ...mapFields([
      'isItemEdited',
      'itemData.per.discipline',
      'itemData.per.cycle',
      'itemData.per.interdisciplinaryLink',
      'itemData.per.interdisciplinaryLinkDetails',
    ]),
  },
};
</script>

<style>
.el-tag,
.el-tag.el-tag--info {
  color: white !important;
}
</style>
