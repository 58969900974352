import { createRouter, createWebHistory } from 'vue-router';
import { isAllowed, verifyAndRenewToken } from '@/services/AuthService';
import routes from '../routes/routes';
import store from '../store/index';

// configure router
const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters['account/token']) {
    try {
      await store.dispatch('account/login');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Shib session is not available', error);
      // Ignore, as we are not authentified
    }
  } else {
    await verifyAndRenewToken();
  }

  if (isAllowed(to)) {
    return next();
  }

  // if not connected, ask for login
  if (!store.getters['account/token']) {
    window.location = `${process.env.VUE_APP_SP_URL}/login?appURL=${window.location.href}`;
  }
  // if connected but do not have writes, show a message and tell them to ask for the roles
  return next({ name: 'NotAllowed' });
});

// export the router instance
export default router;
