<template>
  <div>
    <new-chapter-modal
      v-if="isItemEdited && id"
      :show="showNewChapterModal"
      :itemId="id"
      :itemThematicId="itemThematicId"
      :learningObjectiveId="learningObjectiveId"
      @addChapterToThematic="addChapterToThematic"
      @hideModalChapter="hideModalChapter"
    />

    <label class="chapters-title">Chapitre(s)</label>
    <button
      v-if="isItemEdited"
      class="btn btn-icon btn-round btn-success btn-create-chapter"
      @click="showModalChapter"
    >
      <i class="fa fa-plus" />
    </button>

    <div class="accordion">
      <accordion-panel
        v-for="chapter in chapters"
        class="chapter-panel"
        :key="chapter.id"
        :identifier="chapter.id"
        :title="chapter.description"
        :deletable="isItemEdited"
        @removeItem="removeChapter(chapter)"
      >
        <label>Nom complet</label>
        <div>{{ chapter.description }}</div>

        <textual-genre-select
          class="textual-genre-select"
          v-if="hasChapterTextualGenres(chapter.description)"
          :textualGenre="chapter.textualGenre"
          :learning-objective-id="learningObjectiveId"
          :chapter="chapter.description"
          :disabled="!isItemEdited"
          @change="
            changeTextualGenre(itemThematicId, chapter.description, $event)
          "
        />
      </accordion-panel>

      <div v-if="isChaptersEmpty" class="no-chapter">
        Aucun chapitre n'est actuellement associé à cet axe thématique.
      </div>
    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import COMMON from '@/constants/COMMON';
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import mixinSwal from '@/mixins/mixinSwal';
import TextualGenreSelect from '@/components/ApplicationViews/UIComponents/itemDetails/TextualGenreSelect.vue';
import AccordionPanel from '../AccordionPanel.vue';
import NewChapterModal from '../../Modals/NewChapterModal.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    TextualGenreSelect,
    AccordionPanel,
    NewChapterModal,
  },
  mixins: [mixinSwal],
  props: {
    itemThematicId: {
      type: Number,
      required: true,
    },
    learningObjectiveId: {
      type: Number,
      required: true,
    },
    chapters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showNewChapterModal: false,
    };
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "cycle" for "per.cycle").
    ...mapFields(['isItemEdited', 'itemData.id']),
    isChaptersEmpty() {
      return this.chapters.length === 0;
    },
  },
  methods: {
    showModalChapter() {
      this.showNewChapterModal = true;
    },
    hideModalChapter() {
      this.showNewChapterModal = false;
    },
    addChapterToThematic(chapterData) {
      this.$store.commit('item/addChapterToItemThematic', {
        chapter: chapterData,
        itemThematicId: this.itemThematicId,
      });

      this.hideModalChapter();
    },
    removeChapter(chapter) {
      this.$store.commit('item/resetErrorMessage');

      this.showSwal(
        "Êtes-vous sûr de vouloir retirer ce chapitre de l'axe thématique ?",
        'Les changements ne seront pas sauvegardés tant que vous n\'avez pas manuellement appuyé sur le bouton "Enregistrer".'
      )
        .then(() => {
          this.$store.commit('item/removeChapterOfItemThematic', {
            chapter,
            itemThematicId: this.itemThematicId,
          });
          this.$store.commit('item/resetErrorMessage');
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
          }
        });
    },
    hasChapterTextualGenres(chapterDescription) {
      const recommendedGenresChapters =
        ITEM_METADATA.RECOMMENDED_GENRES.learningObjectives.get(
          this.learningObjectiveId
        )?.chapters;
      const recommendedGenresByYears =
        recommendedGenresChapters?.get(
          chapterDescription
        )?.recommendedGenresByYears;

      return !!recommendedGenresByYears;
    },
    changeTextualGenre(itemThematicId, chapterDescription, textualGenre) {
      this.$store.commit('item/updateTextualGenreOfChapter', {
        itemThematicId,
        chapterDescription,
        textualGenre,
      });
    },
  },
};
</script>

<style scoped>
.accordion {
  margin: 0.25em 0 1em 0;
}

.chapter-panel {
  margin-top: -1px !important;
}

.chapters-title {
  margin-top: 0.9em;
}

.btn-create-chapter {
  height: 1.3rem !important;
  margin: 0 0 0 0.5rem;
  min-width: 1.3rem !important;
  width: 1.3rem !important;
}

.no-chapter {
  font-size: 0.8571em;
  font-style: italic;
  margin-bottom: 1.5em;
}

.textual-genre-select {
  margin-top: 0.75em;
}
</style>
