// https://github.com/maoberlehner/vuex-map-fields
// This library allows us to use the (nested) properties of our store directly in the
// v-model properties of our fields, without having to manually manage the mutations.
import { getField, updateField } from 'vuex-map-fields';
import lodash from 'lodash';
import he from 'he';
import { getFormatedDateTime } from '@/services/DateService';
import {
  difference,
  buildItemDataHistory,
  buildItemExternalMaterialsHistory,
  buildItemThematicsHistory,
} from '@/services/HistoryService';
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import PER_CONSTANTS from '@/constants/PER_CONSTANTS';
import { app } from '@/main';

function getItemThematicById(itemThematics, itemThematicId) {
  // Select the item thematic according to the given ID.
  const itemThematic = itemThematics.filter(
    (it) => it.id === itemThematicId
  )[0];

  return itemThematic;
}

function getChapterByDescription(chapters, chapterDescription) {
  const chapter = chapters.filter(
    (ch) => ch.description === chapterDescription
  )[0];

  return chapter;
}

// Get the history changes of the given item thematics.
function getUpdatedItemThematicChanges(state, itemThematicId) {
  // Create a new element in the item thematics map in there was no change
  // on this item thematic yet.
  if (!state.itemThematicsChanges.updated.has(itemThematicId)) {
    const itemThematic = getItemThematicById(
      state.itemThematics,
      itemThematicId
    );

    state.itemThematicsChanges.updated.set(itemThematicId, {
      name: itemThematic.thematic.name,
      chapters: {
        deleted: [],
        added: [],
        /**
         * [ [ 'chapterDescription', {
         *   textualGenre: {
         *     oldValue: '',
         *     newValue: '',
         *   }
         * } ], ... ]
         */
        updated: new Map(),
      },
      years: {},
      progressions: {
        deleted: [],
        added: [],
      },
      progressionsSpecificElements: {},
    });
  }

  return state.itemThematicsChanges.updated.get(itemThematicId);
}

// /!\ BE AWARE: if you change the structure of the item's data you will have to change it in the history service too,
// in the buildItemDataHistory function.
const state = () => ({
  isLoading: false,
  isItemEdited: false,
  itemData: {
    id: null,
    lastUpdateDate: null,
    management: {
      customCode: '',
      status: '',
      cantonOrigin: '',
      passingYears: '',
      computerizedItem: '',
      functionalitiesComputerizedItem: '',
      visibility: '',
      multipleItem: '',
      belongsToMultipleItem: '',
    },
    per: {
      discipline: {},
      cycle: null,
      interdisciplinaryLink: {
        id: null,
        name: '',
      },
      interdisciplinaryLinkDetails: '',
    },
    disciplineRelatedData: {
      french: {
        contentDifficulty: {
          operationType: '',
          operationTypeIndex: null,
          operationNumber: '',
          operationNumberIndex: null,
          languageObjectAmplitude: '',
          languageObjectAmplitudeIndex: null,
          difficultyName: '',
          difficultyValue: '',
        },
        envelopeDifficulty: {
          instructionIndex: null,
          questionFormatIndex: null,
          material: null,
          difficultyName: '',
          difficultyValue: '',
        },
      },
      mathematics: {
        taskCategorization: '',
        imageFunction: '',
        contextualComplexityFactorIndex: null,
        complexityFactorIndex: null,
        competenceLevelIndex: null,
        actionsAndOperations: '',
      },
      multiple: {
        questioningFormat: '',
        difficultyAfterthought: '',
      },
    },
    contentAndSolution: {
      generalTaskDescription: '',
      title: '',
      content: '',
      variant: '',
      solution: '',
      associatedMaterial: '',
      externalMaterials: [],
    },
    instructions: {
      availableMaterial: '',
      passingInstructions: '',
      successFactors: '',
      possibleDidacticVariables: '', // Math only
      estimatedDuration: '',
    },
    history: [], // Will contain the history elements that are displayed in the history panel.
  },
  itemThematics: [],
  /* Will contain all the deleted and added external materials' ids and names since the last fetch, in order to build the history on saving.
   * Each first-level attribute will contain the material's type as keys, and an array of materials's ids and names as values, e.g.:
   *  deleted: {
   *    MathMaterialEntity: [
   *      {
   *        id: 12,
   *        name: 'Test',
   *      },
   *      ...
   *    ]
   *  }
   */
  itemExternalMaterialsChanges: {
    baseOrder: [],
    newOrder: [],
    deleted: {},
    added: {},
  },
  itemDataCopy: {}, // Will contain a plain deep copy of all the item's details values right after the initial fetch; this will be used to compare fields values when one edits the item in order to generate the history elements.
  /*
   * Will contain all the changes of the item's thematics elements since the initial fetch in order to generate the history when saving the item.
   * Here we do not generate a plain copy of the fethed data becauce it is complicated to compare arrays of complexe objects.
   * The structure will be the following:
   *  {
   *    deleted: new Map( // map that will contain the deleted itemThematics' IDs as keys, and their names as values.
   *      270: '',
   *      185: '',
   *    ),
   *    added: new Map( // map that will contain the added itemThematics' IDs as keys, and their names as values.
   *      300: '',
   *    ),
   *    updated: new Map( // map that will contain the updated itemThematics' IDs as keys, and the updated fields values as values.
   *      272: {
   *        name: '', // the name of the associated thematic
   *        chapters: {
   *          deleted: ['...'], // string array of the deleted chapters names
   *          added: ['...'], // string array of the added chapters names
   *        },
   *        years: {
   *          oldValue: [...], // integer array of the years' old value
   *          newValue: [...], // integer array of the years' new value
   *        },
   *        progressions: {
   *          deleted: ['...'], // string array of the deleted progressions names
   *          added: ['...'], // string array of the added progressions names
   *        },
   *        progressionsSpecificElements: {
   *          oldValue: '...', // old value of the progressions specific elements field
   *          newValue: '...', // new value of the progressions specific elements field
   *        }
   *      },
   *      284: {
   *        ...
   *      },
   *    ),
   *  }
   */
  itemThematicsChanges: {
    deleted: new Map(),
    added: new Map(),
    updated: new Map(),
  },
  errorMessage: '',
});

const getters = {
  // Automatically generate a v-model getter for each nester property of the store.
  getField,
  doesItemContainThematicAxis(state) {
    // Currying the getter in order to be able to receive a parameter.
    return (thematicAxisId) => {
      const thematicAxes = state.itemThematics.filter(
        (itemThematic) => itemThematic.thematic.id === thematicAxisId
      );

      return thematicAxes.length > 0;
    };
  },
  doesThematicAxisContainChapter(state) {
    // Currying the getter in order to be able to receive parameters.
    return (itemThematicId, chapterId) => {
      const itemThematic = getItemThematicById(
        state.itemThematics,
        itemThematicId
      );

      const chapters = itemThematic.chapters.filter(
        (chapter) => chapter.id === chapterId
      );

      return chapters.length > 0;
    };
  },
  doesThematicAxisContainProgression(state) {
    // Currying the getter in order to be able to receive parameters.
    return (itemThematicId, progressionId) => {
      const itemThematic = getItemThematicById(
        state.itemThematics,
        itemThematicId
      );

      const progressions = itemThematic.progressions.filter(
        (progression) => progression.id === progressionId
      );

      return progressions.length > 0;
    };
  },
  getHighestItemThematicId(state) {
    const itemThematicsIdsList = state.itemThematics.map(
      (itemThematic) => itemThematic.id
    );

    const highestItemThematicId = Math.max(...itemThematicsIdsList);

    return highestItemThematicId;
  },
  getHighestThematicPosition(state) {
    const numberOfItemThematics = state.itemThematics.length;

    if (numberOfItemThematics > 0) {
      return state.itemThematics[numberOfItemThematics - 1].thematicPosition;
    }

    return 0;
  },
  // Return the formatted fields of the item that will be sent in the PATCH request to the server.
  getServerFormattedFields(state) {
    const francaisAdvancedMaterialsIds =
      state.itemData.contentAndSolution.externalMaterials
        .filter(
          (material) =>
            material.type ===
            ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.FrancaisAdvancedMaterialEntity
              .value
        )
        .map((externalMaterial) => externalMaterial.id);

    const francaisSimpleMaterialsIds =
      state.itemData.contentAndSolution.externalMaterials
        .filter(
          (material) =>
            material.type ===
            ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.FrancaisSimpleMaterialEntity
              .value
        )
        .map((externalMaterial) => externalMaterial.id);

    const mathMaterialsIds = state.itemData.contentAndSolution.externalMaterials
      .filter(
        (material) =>
          material.type ===
          ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.MathMaterialEntity.value
      )
      .map((externalMaterial) => externalMaterial.id);

    const formattedFields = {
      // Management
      status: state.itemData.management.status,
      cantonOrigin: state.itemData.management.cantonOrigin,
      passingYears: state.itemData.management.passingYears,
      computerizedItem: state.itemData.management.computerizedItem,
      functionalitiesComputerizedItem:
        state.itemData.management.functionalitiesComputerizedItem,
      visibility: state.itemData.management.visibility,
      multipleItem: state.itemData.management.multipleItem,
      belongsToMultipleItem: state.itemData.management.belongsToMultipleItem,
      // PER
      interdisciplinaryLinkId: state.itemData.per.interdisciplinaryLink.id,
      interdisciplinaryLinkDetails:
        state.itemData.per.interdisciplinaryLinkDetails,
      // Discipline-related data - French
      contentDiffOperationType:
        state.itemData.disciplineRelatedData.french.contentDifficulty
          .operationType,
      contentDiffOperationTypeIndex:
        state.itemData.disciplineRelatedData.french.contentDifficulty
          .operationTypeIndex,
      contentDiffOperationNumber:
        state.itemData.disciplineRelatedData.french.contentDifficulty
          .operationNumber,
      contentDiffOperationNumberIndex:
        state.itemData.disciplineRelatedData.french.contentDifficulty
          .operationNumberIndex,
      contentDiffLanguageObjectAmplitude:
        state.itemData.disciplineRelatedData.french.contentDifficulty
          .languageObjectAmplitude,
      contentDiffLanguageObjectAmplitudeIndex:
        state.itemData.disciplineRelatedData.french.contentDifficulty
          .languageObjectAmplitudeIndex,
      envelopDiffInstruction:
        state.itemData.disciplineRelatedData.french.envelopeDifficulty
          .instructionIndex,
      envelopDiffQuestionFormat:
        state.itemData.disciplineRelatedData.french.envelopeDifficulty
          .questionFormatIndex,
      envelopDiffMaterial:
        state.itemData.disciplineRelatedData.french.envelopeDifficulty.material,
      // Discipline-related data - Mathematics
      taskCategorization:
        state.itemData.disciplineRelatedData.mathematics.taskCategorization,
      imageFunction:
        state.itemData.disciplineRelatedData.mathematics.imageFunction,
      mathContextualComplexityFactor:
        state.itemData.disciplineRelatedData.mathematics
          .contextualComplexityFactorIndex,
      mathComplexityFactor:
        state.itemData.disciplineRelatedData.mathematics.complexityFactorIndex,
      mathCompetenceLevelFactor:
        state.itemData.disciplineRelatedData.mathematics.competenceLevelIndex,
      actionsAndOperations:
        state.itemData.disciplineRelatedData.mathematics.actionsAndOperations,
      // Discipline-related data - Multiple
      questioningFormat:
        state.itemData.disciplineRelatedData.multiple.questioningFormat,
      difficultyAfterthought:
        state.itemData.disciplineRelatedData.multiple.difficultyAfterthought,
      // Content and solution
      generalTaskDescription:
        state.itemData.contentAndSolution.generalTaskDescription,
      title: state.itemData.contentAndSolution.title,
      content: state.itemData.contentAndSolution.content,
      variant: state.itemData.contentAndSolution.variant,
      solution: state.itemData.contentAndSolution.solution,
      associatedMaterial: state.itemData.contentAndSolution.associatedMaterial,
      francaisAdvancedMaterialsIds,
      francaisSimpleMaterialsIds,
      mathMaterialsIds,
      // Instructions
      availableMaterial: state.itemData.instructions.availableMaterial,
      passingInstructions: state.itemData.instructions.passingInstructions,
      successFactors: state.itemData.instructions.successFactors,
      possibleDidacticVariables:
        state.itemData.instructions.possibleDidacticVariables,
      estimatedDuration: state.itemData.instructions.estimatedDuration,
    };

    return formattedFields;
  },
  // Find the differences in the item's data since they were fetched, then build the history from it
  // and finally return this history.
  getItemDataHistory(state) {
    const differences = difference(state.itemData, state.itemDataCopy);
    const itemDataHistory = buildItemDataHistory(differences);

    return itemDataHistory;
  },
  // Return the formatted fields of the item thematics array that will be sent in the PUT request to the server.
  getServerFormattedItemThematicsFields(state) {
    const formattedFields = state.itemThematics.map((itemThematic) => {
      const chaptersData = itemThematic.chapters.map((chapter) => ({
        id: chapter.id,
        textualGenre: chapter.textualGenre,
      }));
      const progressionsIds = itemThematic.progressions.map(
        (progression) => progression.id
      );

      return {
        thematicId: itemThematic.thematic.id,
        learningObjectiveId: itemThematic.learningObjective.id,
        chaptersData,
        years: itemThematic.years,
        progressionsIds,
        progressionsSpecificElements: itemThematic.progressionsSpecificElements,
      };
    });

    return formattedFields;
  },
  // Find the differences in the item's external materials' data since they were fetched,
  // then build the history from it and finally return this history.
  getItemExternalMaterialsDataHistory(state) {
    const itemExternalMaterialsDataHistory = buildItemExternalMaterialsHistory(
      state.itemExternalMaterialsChanges
    );

    return itemExternalMaterialsDataHistory;
  },
  // Find the differences in the item thematics' data since they were fetched, then build the history from it
  // and finally return this history.
  getItemThematicsDataHistory(state) {
    const itemThematicsDataHistory = buildItemThematicsHistory(
      state.itemThematicsChanges
    );

    return itemThematicsDataHistory;
  },
};

const mutations = {
  // Automatically generate a v-model mutation for each nester property of the store.
  updateField,
  // We still need to write our own mutations if they are used outside v-model properties.
  toggleEdition(state) {
    state.isItemEdited = !state.isItemEdited;
  },
  disableEdition(state) {
    state.isItemEdited = false;
  },
  enableLoading(state) {
    state.isLoading = true;
  },
  disableLoading(state) {
    state.isLoading = false;
  },
  setId(state, id) {
    state.itemData.id = id;
  },
  setLastUpdateDate(state, lastUpdateDate) {
    state.itemData.lastUpdateDate = lastUpdateDate;
  },
  setManagementData(state, managementData) {
    state.itemData.management = managementData;
  },
  setPerData(state, perData) {
    state.itemData.per = perData;

    if (state.itemData.per.interdisciplinaryLink === null) {
      state.itemData.per.interdisciplinaryLink = {
        id: null,
      };
    }
  },
  setInterdisciplinaryLinkData(state, interdisciplinaryLinkData) {
    state.itemData.per.interdisciplinaryLink.id = interdisciplinaryLinkData.id;
    state.itemData.per.interdisciplinaryLink.name =
      interdisciplinaryLinkData.name;
  },
  resetInterdisciplinaryLinkDetails(state) {
    state.itemData.per.interdisciplinaryLinkDetails = '';
  },
  setDisciplineRelatedData(state, disciplineRelatedData) {
    state.itemData.disciplineRelatedData = disciplineRelatedData;
  },
  setContentAndSolutionData(state, contentAndSolutionData) {
    state.itemData.contentAndSolution = contentAndSolutionData;

    // Save the order of the materials in order to know if it changed when the user presses the save button.
    if (contentAndSolutionData.externalMaterials) {
      const baseOrder = state.itemData.contentAndSolution.externalMaterials.map(
        (material) => material.id
      );

      state.itemExternalMaterialsChanges.baseOrder = baseOrder;
      state.itemExternalMaterialsChanges.newOrder = baseOrder;
    }
  },
  addExternalMaterial(state, externalMaterial) {
    // Check if the external material is not already existing for the current item before adding it.
    const existingExternalMaterial =
      state.itemData.contentAndSolution.externalMaterials.filter(
        (em) => em.id === externalMaterial.id
      );

    if (existingExternalMaterial.length === 0) {
      state.itemData.contentAndSolution.externalMaterials.push(
        externalMaterial
      );

      const addedExternalMaterialsHistory =
        state.itemExternalMaterialsChanges.added[externalMaterial.dtype];
      const deletedExternalMaterialsHistory =
        state.itemExternalMaterialsChanges.deleted[externalMaterial.dtype];

      let materialExistedIntheDeletedMaterialsList = false;

      // If this external material was previously removed from the material (which means the user removed it without
      // saving and want to add it again), just remove it from the deleted materials history list.
      if (deletedExternalMaterialsHistory) {
        state.itemExternalMaterialsChanges.deleted[externalMaterial.dtype] =
          deletedExternalMaterialsHistory.filter((deletedMaterial) => {
            if (deletedMaterial.id === externalMaterial.id) {
              materialExistedIntheDeletedMaterialsList = true;
              return false;
            }

            return true;
          });
      }

      // Add the added external material's data in the array of added materials history object if it was not in the
      // deleted materials list.
      if (!materialExistedIntheDeletedMaterialsList) {
        if (addedExternalMaterialsHistory) {
          addedExternalMaterialsHistory.push({
            id: externalMaterial.id,
            name: externalMaterial.documents[0].name,
          });
        } else {
          state.itemExternalMaterialsChanges.added[externalMaterial.dtype] = [
            {
              id: externalMaterial.id,
              name: externalMaterial.documents[0].name,
            },
          ];
        }
      }
    } else {
      throw new Error('Ce matériel externe est déjà lié à cet item.');
    }
  },
  removeExternalMaterial(state, externalMaterial) {
    const addedExternalMaterialsHistory =
      state.itemExternalMaterialsChanges.added[externalMaterial.dtype];
    const deletedExternalMaterialsHistory =
      state.itemExternalMaterialsChanges.deleted[externalMaterial.dtype];

    let materialExistedIntheAddedMaterialsList = false;

    // If this external material was previously added in the material (which means the user added it without
    // saving and want to remove it again), just remove it from the added materials history list.
    if (addedExternalMaterialsHistory) {
      state.itemExternalMaterialsChanges.added[externalMaterial.dtype] =
        addedExternalMaterialsHistory.filter((addedMaterial) => {
          if (addedMaterial.id === externalMaterial.id) {
            materialExistedIntheAddedMaterialsList = true;
            return false;
          }

          return true;
        });
    }

    // Add the deleted external material's data in the array of deleted materials history object if it was not in the
    // added materials list.
    if (!materialExistedIntheAddedMaterialsList) {
      if (deletedExternalMaterialsHistory) {
        deletedExternalMaterialsHistory.push({
          id: externalMaterial.id,
          name: externalMaterial.documents[0].name,
        });
      } else {
        state.itemExternalMaterialsChanges.deleted[externalMaterial.dtype] = [
          {
            id: externalMaterial.id,
            name: externalMaterial.documents[0].name,
          },
        ];
      }
    }

    state.itemData.contentAndSolution.externalMaterials =
      state.itemData.contentAndSolution.externalMaterials.filter(
        (em) => em.id !== externalMaterial.id
      );
  },
  moveUpMaterial(state, externalMaterialId) {
    const currentIndexOfMaterialToMove =
      state.itemData.contentAndSolution.externalMaterials
        .map((material) => material.id)
        .indexOf(externalMaterialId);

    if (currentIndexOfMaterialToMove > 0) {
      const externalMaterialToMove =
        state.itemData.contentAndSolution.externalMaterials[
          currentIndexOfMaterialToMove
        ];

      const externalMaterialToSwitchWith =
        state.itemData.contentAndSolution.externalMaterials[
          currentIndexOfMaterialToMove - 1
        ];

      state.itemData.contentAndSolution.externalMaterials[
        currentIndexOfMaterialToMove - 1
      ] = externalMaterialToMove;

      state.itemData.contentAndSolution.externalMaterials[
        currentIndexOfMaterialToMove
      ] = externalMaterialToSwitchWith;

      // Save the new materials order in order to know if it changed when the user presses the save button.
      state.itemExternalMaterialsChanges.newOrder =
        state.itemData.contentAndSolution.externalMaterials.map(
          (material) => material.id
        );
    }
  },
  moveDownMaterial(state, externalMaterialId) {
    const currentIndexOfMaterialToMove =
      state.itemData.contentAndSolution.externalMaterials
        .map((material) => material.id)
        .indexOf(externalMaterialId);

    if (
      currentIndexOfMaterialToMove <
      state.itemData.contentAndSolution.externalMaterials.length - 1
    ) {
      const externalMaterialToMove =
        state.itemData.contentAndSolution.externalMaterials[
          currentIndexOfMaterialToMove
        ];

      const externalMaterialToSwitchWith =
        state.itemData.contentAndSolution.externalMaterials[
          currentIndexOfMaterialToMove + 1
        ];

      state.itemData.contentAndSolution.externalMaterials[
        currentIndexOfMaterialToMove + 1
      ] = externalMaterialToMove;

      state.itemData.contentAndSolution.externalMaterials[
        currentIndexOfMaterialToMove
      ] = externalMaterialToSwitchWith;

      // Save the new materials order in order to know if it changed when the user presses the save button.
      state.itemExternalMaterialsChanges.newOrder =
        state.itemData.contentAndSolution.externalMaterials.map(
          (material) => material.id
        );
    }
  },
  setInstructionsData(state, instructionsData) {
    state.itemData.instructions = instructionsData;
  },
  setItemHistory(state, itemHistory) {
    state.itemData.history = itemHistory;
  },
  resetItemHistory(state) {
    state.itemData.history = [];
  },
  setItemThematics(state, itemThematicsArray) {
    state.itemThematics = itemThematicsArray;
  },
  resetItemExternalMaterialsChanges(state) {
    state.itemExternalMaterialsChanges = {
      baseOrder: [],
      newOrder: [],
      deleted: {},
      added: {},
    };
  },
  resetItemThematicsChanges(state) {
    state.itemThematicsChanges = {
      deleted: new Map(),
      added: new Map(),
      updated: new Map(),
    };
  },
  addItemThematic(state, itemThematic) {
    state.itemThematics.push(itemThematic);

    // Add the new item thematic's data in the map of added thematics of the history object.
    state.itemThematicsChanges.added.set(
      itemThematic.id,
      itemThematic.thematic.name
    );
  },
  removeItemThematic(state, itemThematic) {
    // Add the deleted item thematic's data in the map of deleted thematics of the history object.
    // If this item thematic was previously added in the structure (which means the user added it, then
    // removed it without saving), just remove it from the structure.
    if (state.itemThematicsChanges.added.has(itemThematic.id)) {
      state.itemThematicsChanges.added.delete(itemThematic.id);
    } else {
      state.itemThematicsChanges.deleted.set(
        itemThematic.id,
        itemThematic.thematic.name
      );
    }
    // Remove the item thematic from the updated ones in the history object.
    state.itemThematicsChanges.updated.delete(itemThematic.id);

    state.itemThematics = state.itemThematics.filter(
      (it) => it.id !== itemThematic.id
    );
  },
  // yearsData: { years: Array, itemThematicId: Number }
  setItemThematicYears(state, yearsData) {
    const itemThematic = getItemThematicById(
      state.itemThematics,
      yearsData.itemThematicId
    );

    if (itemThematic) {
      // Get the current item thematic's changes from the history.
      const updatedItemThematicChanges = getUpdatedItemThematicChanges(
        state,
        yearsData.itemThematicId
      );

      // Save the old value of the years in the item thematic's history object if it is not already set
      // (if already set, that means the user already changed the value without saving since the last fetch).
      if (!updatedItemThematicChanges.years.oldValue) {
        updatedItemThematicChanges.years.oldValue = itemThematic.years;
      }

      itemThematic.years = yearsData.years;

      // If the user removed the last year of the thematic axis we must remove the associated progressions
      // and specific elements.
      if (itemThematic.years.length === 0) {
        // First save the deleted progressions in the history object.
        // We only want to mark the progressions that existed right after the last fetch of the item,
        // so we have to filter them by removing the last added ones.
        updatedItemThematicChanges.progressions.deleted =
          itemThematic.progressions
            .filter(
              (progression) =>
                !updatedItemThematicChanges.progressions.added.includes(
                  progression.description
                )
            )
            .map((progression) => progression.description);
        updatedItemThematicChanges.progressions.added = [];

        // Then save the deleted associated progressions in the history object.
        if (
          updatedItemThematicChanges.progressionsSpecificElements.oldValue ===
          undefined
        ) {
          updatedItemThematicChanges.progressionsSpecificElements.oldValue =
            itemThematic.progressionsSpecificElements
              ? itemThematic.progressionsSpecificElements
              : '';
        }
        updatedItemThematicChanges.progressionsSpecificElements.newValue = '';

        // Finally reset the fields.
        itemThematic.progressions = [];
        itemThematic.progressionsSpecificElements = '';
      }

      updatedItemThematicChanges.years.newValue = yearsData.years;
    }
  },
  // data: { progressionsSpecificElements: String, itemThematicId: Number }
  setItemThematicProgressionsSpecificElements(state, data) {
    const itemThematic = getItemThematicById(
      state.itemThematics,
      data.itemThematicId
    );

    if (itemThematic) {
      // Get the current item thematic's changes from the history.
      const updatedItemThematicChanges = getUpdatedItemThematicChanges(
        state,
        data.itemThematicId
      );

      // Save the old value of the progressions specific elements in the item thematic's history object
      // if it is not already set (if already set, that means the user already changed the value without
      // saving since the last fetch).
      if (
        updatedItemThematicChanges.progressionsSpecificElements.oldValue ===
        undefined
      ) {
        updatedItemThematicChanges.progressionsSpecificElements.oldValue =
          itemThematic.progressionsSpecificElements
            ? itemThematic.progressionsSpecificElements
            : '';
      }

      itemThematic.progressionsSpecificElements =
        data.progressionsSpecificElements;

      updatedItemThematicChanges.progressionsSpecificElements.newValue =
        data.progressionsSpecificElements;
    }
  },
  // chapterData: { chapter: Object, itemThematicId: Number }
  addChapterToItemThematic(state, chapterData) {
    const itemThematic = getItemThematicById(
      state.itemThematics,
      chapterData.itemThematicId
    );

    if (itemThematic) {
      // Ensure all the chapter's data have been initialized in order to avoid bugs.
      const chapterDataInizialized = {
        ...chapterData.chapter,
        textualGenre: null,
      };
      // Add the chapter to the item thematic.
      itemThematic.chapters.push(chapterDataInizialized);

      // Alphabeticaly sort the chapters of the item thematic by description.
      itemThematic.chapters.sort((a, b) =>
        a.description.localeCompare(b.description)
      );

      // Get the current item thematic's changes from the history.
      const updatedItemThematicChanges = getUpdatedItemThematicChanges(
        state,
        chapterData.itemThematicId
      );

      const chapterDescription = chapterData.chapter.description;
      const deletedChapters = updatedItemThematicChanges.chapters.deleted;

      // Add the added chapter's description in the map of added chapters of the item thematics history object.
      // If this chapter was previously deleted from the structure (which means the user deleted it, then
      // added it without saving), just remove it from the structure.
      if (deletedChapters.includes(chapterDescription)) {
        deletedChapters.splice(deletedChapters.indexOf(chapterDescription), 1);
      } else {
        updatedItemThematicChanges.chapters.added.push(chapterDescription);
      }
    }
  },
  // chapterData: { chapter: Object, itemThematicId: Number }
  removeChapterOfItemThematic(state, chapterData) {
    const itemThematic = getItemThematicById(
      state.itemThematics,
      chapterData.itemThematicId
    );

    if (itemThematic) {
      // Remove the chapter.
      itemThematic.chapters = itemThematic.chapters.filter(
        (chapter) => chapter.id !== chapterData.chapter.id
      );

      // Get the current item thematic's changes from the history.
      const updatedItemThematicChanges = getUpdatedItemThematicChanges(
        state,
        chapterData.itemThematicId
      );

      const chapterDescription = chapterData.chapter.description;
      const addedChapters = updatedItemThematicChanges.chapters.added;
      const updatedChapters = updatedItemThematicChanges.chapters.updated;

      // Add the deleted chapter's description in the map of deleted chapters of the item thematics history object.
      // If this chapter was previously added in the structure (which means the user added it, then
      // removed it without saving), just remove it from the structure.
      if (addedChapters.includes(chapterDescription)) {
        addedChapters.splice(addedChapters.indexOf(chapterDescription), 1);
      } else {
        // If the chapter was updated before the deletion, remove the update entry from the history structure.
        if (updatedChapters.get(chapterDescription)) {
          updatedChapters.delete(chapterDescription);
        }

        updatedItemThematicChanges.chapters.deleted.push(chapterDescription);
      }
    }
  },
  removeUpdatedItemThematicsIfTheyAreNewItemThematics(state) {
    // Remove the updated item thematics from the history object if they are new item thematics
    // (this can happen because the store still build the changes of the new-added item thematic
    // even if it does not exist in the DB).
    state.itemThematicsChanges.added.forEach((value, key) => {
      if (state.itemThematicsChanges.updated.has(key)) {
        state.itemThematicsChanges.updated.delete(key);
      }
    });
  },
  // textualGenreData: { itemThematicId: Number, chapterDescription: String, textualGenre; String }
  updateTextualGenreOfChapter(state, textualGenreData) {
    const itemThematic = getItemThematicById(
      state.itemThematics,
      textualGenreData.itemThematicId
    );

    if (itemThematic) {
      const chapter = getChapterByDescription(
        itemThematic.chapters,
        textualGenreData.chapterDescription
      );

      // Get the current item thematic's changes from the history.
      const updatedItemThematicChanges = getUpdatedItemThematicChanges(
        state,
        textualGenreData.itemThematicId
      );

      // Initialize the chapter's history if it is not already set.
      if (
        !updatedItemThematicChanges.chapters.updated.has(
          textualGenreData.chapterDescription
        )
      ) {
        updatedItemThematicChanges.chapters.updated.set(
          textualGenreData.chapterDescription,
          { textualGenre: {} }
        );
      }

      // Save the old value of the textual genre in the item thematic's history object
      // if it is not already set (if already set, that means that the user already
      // changed the value without saving since the last fetch).
      if (
        updatedItemThematicChanges.chapters.updated.get(
          textualGenreData.chapterDescription
        ).textualGenre.oldValue === undefined
      ) {
        updatedItemThematicChanges.chapters.updated.get(
          textualGenreData.chapterDescription
        ).textualGenre.oldValue = chapter.textualGenre || '';
      }

      // Save the new textual genre of the chapter.
      chapter.textualGenre = textualGenreData.textualGenre;

      const updatedChapters = updatedItemThematicChanges.chapters.updated;
      updatedChapters.get(
        textualGenreData.chapterDescription
      ).textualGenre.newValue = textualGenreData.textualGenre;
    }
  },
  // progressionData: { progression: Object, itemThematicId: Number }
  addProgressionToItemThematic(state, progressionData) {
    const itemThematic = getItemThematicById(
      state.itemThematics,
      progressionData.itemThematicId
    );

    if (itemThematic) {
      itemThematic.progressions.push(progressionData.progression);

      // Alphabeticaly sort the progressions by description.
      itemThematic.progressions.sort((a, b) =>
        a.description.localeCompare(b.description)
      );

      // Get the current item thematic's changes from the history.
      const updatedItemThematicChanges = getUpdatedItemThematicChanges(
        state,
        progressionData.itemThematicId
      );

      const progressionDescription = progressionData.progression.description;
      const deletedProgression =
        updatedItemThematicChanges.progressions.deleted;

      // Add the added progression's description in the map of added progressions of the item thematics history object.
      // If this progression was previously deleted from the structure (which means the user deleted it, then
      // added it without saving), just remove it from the structure.
      if (deletedProgression.includes(progressionDescription)) {
        deletedProgression.splice(
          deletedProgression.indexOf(progressionDescription),
          1
        );
      } else {
        updatedItemThematicChanges.progressions.added.push(
          progressionDescription
        );
      }
    }
  },
  // progressionData: { progression: Object, itemThematicId: Number }
  removeProgressionOfItemThematic(state, progressionData) {
    const itemThematic = getItemThematicById(
      state.itemThematics,
      progressionData.itemThematicId
    );

    if (itemThematic) {
      // Remove the chapter.
      itemThematic.progressions = itemThematic.progressions.filter(
        (progression) => progression.id !== progressionData.progression.id
      );

      // Get the current item thematic's changes from the history.
      const updatedItemThematicChanges = getUpdatedItemThematicChanges(
        state,
        progressionData.itemThematicId
      );

      // If the user removed the last progression of the thematic axis we must remove the associated progressions'
      // specific elements.
      if (itemThematic.progressions.length === 0) {
        //  First save the deleted associated progressions in the history object.
        if (updatedItemThematicChanges.progressionsSpecificElements.oldValue) {
          updatedItemThematicChanges.progressionsSpecificElements.oldValue =
            itemThematic.progressionsSpecificElements;
        }
        updatedItemThematicChanges.progressionsSpecificElements.newValue = '';

        // Then remove the last associated progressions' specific elements.
        itemThematic.progressionsSpecificElements = '';
      }

      const progressionDescription = progressionData.progression.description;
      const addedProgression = updatedItemThematicChanges.progressions.added;

      // Add the deleted progression's description in the map of deleted progressions of the item thematics history object.
      // If this progression was previously added in the structure (which means the user added it, then
      // removed it without saving), just remove it from the structure.
      if (addedProgression.includes(progressionDescription)) {
        addedProgression.splice(
          addedProgression.indexOf(progressionDescription),
          1
        );
      } else {
        updatedItemThematicChanges.progressions.deleted.push(
          progressionDescription
        );
      }
    }
  },
  // Deep copying the current state of the item's values in order to keep a trace of the original data and thus to build the history
  // when saving them.
  backupItemData(state) {
    state.itemDataCopy = lodash.cloneDeep(state.itemData);
  },
  setErrorMessage(state, errorMessage) {
    state.errorMessage = errorMessage;
  },
  resetErrorMessage(state) {
    state.errorMessage = '';
  },
};

const actions = {
  loadItemById({ commit }, itemId) {
    return app.config.globalProperties.$http
      .get(`/items/${itemId}?projection=itemSummary`)
      .then((payloadItem) => {
        const itemDetails = payloadItem.data;

        commit('setId', itemDetails.id);

        commit('setLastUpdateDate', itemDetails.lastUpdateDate);

        commit('setManagementData', {
          customCode: itemDetails.customCode,
          status: itemDetails.status,
          cantonOrigin: itemDetails.cantonOrigin,
          passingYears: itemDetails.itemPassingYears,
          computerizedItem: itemDetails.computerizedItem,
          functionalitiesComputerizedItem:
            itemDetails.functionalitiesComputerizedItem,
          visibility: itemDetails.visibility,
          multipleItem: itemDetails.multipleItem,
          belongsToMultipleItem: itemDetails.belongsToMultipleItem,
        });

        commit('setPerData', {
          discipline: itemDetails.discipline,
          cycle: itemDetails.cycle,
          interdisciplinaryLink: itemDetails.interdisciplinaryLink,
          interdisciplinaryLinkDetails:
            itemDetails.interdisciplinaryLinkDetails,
        });

        commit('setDisciplineRelatedData', {
          french: {
            contentDifficulty: {
              operationType: itemDetails.contentDiffOperationType,
              operationTypeIndex: itemDetails.contentDiffOperationTypeIndex,
              operationNumber: itemDetails.contentDiffOperationNumber,
              operationNumberIndex: itemDetails.contentDiffOperationNumberIndex,
              languageObjectAmplitude:
                itemDetails.contentDiffLanguageObjectAmplitude,
              languageObjectAmplitudeIndex:
                itemDetails.contentDiffLanguageObjectAmplitudeIndex,
              difficultyName: itemDetails.contentDifficultyName,
              difficultyValue: itemDetails.contentDifficultyValue,
            },
            envelopeDifficulty: {
              instructionIndex: itemDetails.envelopDiffInstruction,
              questionFormatIndex: itemDetails.envelopDiffQuestionFormat,
              material: itemDetails.envelopDiffMaterial,
              difficultyName: itemDetails.envelopDifficultyValue,
              difficultyValue: itemDetails.envelopDifficultyName,
            },
          },
          mathematics: {
            taskCategorization: itemDetails.taskCategorization,
            imageFunction: itemDetails.imageFunction,
            contextualComplexityFactorIndex:
              itemDetails.mathContextualComplexityFactor,
            complexityFactorIndex: itemDetails.mathComplexityFactor,
            competenceLevelIndex: itemDetails.mathCompetenceLevelFactor,
            actionsAndOperations: itemDetails.actionsAndOperations,
          },
          multiple: {
            questioningFormat: itemDetails.questioningFormat,
            difficultyAfterthought: itemDetails.difficultyAfterthought,
          },
        });

        commit('resetItemExternalMaterialsChanges');

        let externalMaterials = [];

        // Load the materials according to the discipline of the item.
        if (
          itemDetails.discipline.id ===
          PER_CONSTANTS.PER_DISCIPLINE_ID_FRANCAIS.ID
        ) {
          externalMaterials =
            itemDetails.francaisAdvancedMaterialEntities.concat(
              itemDetails.francaisSimpleMaterialEntities
            );
        } else if (
          itemDetails.discipline.id === PER_CONSTANTS.PER_DISCIPLINE_ID_MATH.ID
        ) {
          externalMaterials = itemDetails.mathMaterialEntities;
        }

        externalMaterials = externalMaterials
          .filter((material) => !!material)
          .map((material) => ({
            ...material,
            type: ITEM_METADATA.EXTERNAL_MATERIAL_TYPES[material.dtype].value,
          }));

        commit('setContentAndSolutionData', {
          generalTaskDescription: itemDetails.generalTaskDescription,
          title: itemDetails.title,
          content: itemDetails.content,
          variant: itemDetails.variant,
          solution: itemDetails.solution,
          associatedMaterial: itemDetails.associatedMaterial,
          externalMaterials,
        });

        commit('setInstructionsData', {
          availableMaterial: itemDetails.availableMaterial,
          passingInstructions: itemDetails.passingInstructions,
          successFactors: itemDetails.successFactors,
          possibleDidacticVariables: itemDetails.possibleDidacticVariables,
          estimatedDuration: itemDetails.estimatedDuration,
        });

        commit('backupItemData');
      })
      .catch(() => {
        commit('setId', null);

        commit(
          'setErrorMessage',
          "Une erreur est survenue lors du chargement des données de l'item, veuillez réessayer ou contacter un administrateur."
        );
      });
  },
  loadItemThematicsByItemId({ commit }, itemId) {
    // Fetch the thematics of the current item.
    return app.config.globalProperties.$http
      .get(
        `/itemsThematics/search/byItemId?itemId=${itemId}&projection=itemThematic`
      )
      .then((payloadItemsThematics) => {
        // If there is no set years for the item's thematic the server will send a 'null' value,
        // but we need a '[]' since we are working on arrays.
        const itemThematicsWithFixedEmptyYears =
          payloadItemsThematics.data._embedded.itemsThematics.map(
            (itemThematic) => {
              // Decode the html entities (e.g. "&lt;" => "<") of the description of each of the current thematic's progressions.
              const progressions = itemThematic.progressions.map(
                (progression) => ({
                  ...progression,
                  description: he.decode(progression.description),
                })
              );

              return {
                ...itemThematic,
                progressions,
                years: itemThematic.years ? itemThematic.years : [],
              };
            }
          );

        commit('setItemThematics', itemThematicsWithFixedEmptyYears);

        commit('resetItemThematicsChanges');
      })
      .catch(() => {
        commit(
          'setErrorMessage',
          "Une erreur est survenue lors du chargement des axes thématiques de l'item, veuillez réessayer ou contacter un administrateur."
        );
      });
  },
  loadHistoryByItemId({ commit }, itemId) {
    return app.config.globalProperties.$http
      .get(`/itemsHistory/search/byItemId?itemId=${itemId}`)
      .then((response) => {
        // Add every element item in the local memory.
        if (
          response.data._embedded.itemsHistory &&
          response.data._embedded.itemsHistory.length
        ) {
          const history = response.data._embedded.itemsHistory.map(
            (historyElement) => ({
              ...historyElement,
              timestamp: getFormatedDateTime(historyElement.timestamp),
            })
          );

          commit('setItemHistory', history);
        } else {
          commit('resetItemHistory');
        }
      })
      .catch(() => {
        commit(
          'setErrorMessage',
          "Une erreur est survenue lors de la récupération de l'historique de l'item, veuillez réessayer ou contacter un administrateur."
        );
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
