<template>
  <div>
    <label
      >Axe thématique <span v-if="isDefaultThematic"> principal</span></label
    >
    <div class="row form-group2">
      <el-select
        v-model="selectedThematicId"
        :disabled="disabled"
        class="select-info"
        size="large"
        filterable
        clearable
        no-match-text="Aucun axe thématique n'a été trouvé"
        placeholder="Sélectionner"
        @update:modelValue="selectThematic"
      >
        <el-option
          v-for="thematic in thematics"
          :value="thematic.id"
          :label="thematic.name"
          :key="thematic.name"
          class="select-info"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object, // This component emits the whole selected thematic object instead of just its ID, in order for the parents to work with its data.
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    isDefaultThematic: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      thematics: [],
      selectedThematicId: null,
    };
  },
  created() {
    // If the discipline's ID is already set once the component was successfully created it means that the
    // associated watcher will not trigger so we have to manually trigger the thematics fetching method.
    if (this.disciplineId) {
      this.fetchData();
    }
  },
  watch: {
    disciplineId: {
      handler() {
        this.fetchData();
      },
    },
    modelValue: {
      handler(newVal) {
        // Reset the select list if the value of the component is null.
        if (!newVal) {
          this.selectedThematicId = null;
        }
      },
    },
  },
  methods: {
    fetchData() {
      if (this.disciplineId) {
        this.$http
          .get(
            `/thematics/search/byDisciplineId?disciplineId=${this.disciplineId}`
          )
          .then((payload) => {
            this.thematics = payload.data._embedded.thematics.map((entity) => ({
              id: entity.id,
              name: entity.name,
            }));
          });
      }
    },
    selectThematic() {
      const selectedThematic = this.thematics.filter(
        (thematic) => thematic.id === this.selectedThematicId
      )[0];

      // This component emits the whole selected thematic object instead of just its ID, in order for the parents to work with its data.
      this.$emit('update:modelValue', selectedThematic);
    },
  },
};
</script>
