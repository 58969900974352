<template>
  <div class="login-page">
    <div class="wrapper wrapper-full-page">
      <div class="full-page login-page section-image">
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="col-lg-6 col-md-6 ml-auto mr-auto">
              <info-section
                class="mt-5"
                type="danger"
                title="Marketing"
                description="We've created the marketing campaign of the website. It was a very interesting collaboration."
                icon="nc-icon nc-alert-circle-i"
              >
                <h2 class="text-white mt-3">Page non trouvée</h2>
                <small class="text-white"
                  >Vous avez essayé d'accéder à une page qui n'existe pas.
                  <br />
                  Vous pouvez retourner
                  <router-link to="/">à la page principale</router-link>
                  .
                </small>
              </info-section>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div
          class="full-page-background"
          style="background-image: url(static/img/background/jan-sendereks.jpg)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { Checkbox, Button, InfoSection } from 'src/components/UIComponents';
import AppFooter from '../Dashboard/Views/Pages/Layout/AppFooter.vue';

export default {
  components: {
    AppFooter,
    InfoSection,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      document.body.classList.remove('off-canvas-sidebar');
    },
  },
  beforeUnmount() {
    this.closeMenu();
  },
};
</script>
