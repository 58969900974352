import swal from 'sweetalert2';

export default {
  methods: {
    showSwal(title, text, confirmButtonText, cancelButtonText) {
      return swal({
        title,
        text: text || "Il ne sera pas possible d'annuler cette action.",
        type: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: confirmButtonText || 'Oui, effacer',
        cancelButtonText: cancelButtonText || 'Non, conserver',
        buttonsStyling: false,
      });
    },
  },
};
