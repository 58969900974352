const ROUTES_NAMES = {
  ITEMS: 'Items',
  ITEM_DETAILS: 'ItemDetails',
  FRANCAIS_ADVANCED_MATERIALS: 'FrancaisAdvancedMaterials',
  FRANCAIS_ADVANCED_MATERIAL_DETAILS: 'FrancaisAdvancedMaterialDetails',
  FRANCAIS_SIMPLE_MATERIALS: 'FrancaisSimpleMaterials',
  FRANCAIS_SIMPLE_MATERIAL_DETAILS: 'FrancaisSimpleMaterialDetails',
  MATH_MATERIALS: 'MathMaterials',
  MATH_MATERIAL_DETAILS: 'MathMaterialDetails',
  SYNCHRO_PER: 'SynchroPER',
};

export default ROUTES_NAMES;
