<template>
  <new-element-modal
    :show="show"
    title="Ajouter un chapitre à l'axe thématique"
    :errorMessage="errorMessage"
    @hideModal="hideModalChapter"
    @validate="addChapterToThematic"
  >
    <chapter-select
      v-if="show"
      v-model="selectedChapterData"
      :learning-objective-id="learningObjectiveId"
    />
  </new-element-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import NewElementModal from './NewElementModal.vue';
import ChapterSelect from '../UIComponents/ChapterSelect.vue';

export default {
  components: {
    NewElementModal,
    ChapterSelect,
  },
  props: {
    show: {
      type: Boolean,
    },
    itemId: {
      type: Number,
      required: true,
    },
    itemThematicId: {
      type: Number,
    },
    learningObjectiveId: {
      type: Number,
    },
  },
  data() {
    return {
      selectedChapterData: null,
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters({
      doesThematicAxisContainChapter: 'item/doesThematicAxisContainChapter',
    }),
  },
  watch: {
    selectedChapterData: {
      handler() {
        this.errorMessage = '';
      },
    },
  },
  methods: {
    resetData() {
      this.selectedChapterData = null;
      this.errorMessage = '';
    },
    hideModalChapter() {
      this.resetData();
      this.$emit('hideModalChapter');
    },
    addChapterToThematic() {
      if (this.selectedChapterData) {
        this.errorMessage = '';

        if (
          this.doesThematicAxisContainChapter(
            this.itemThematicId,
            this.selectedChapterData.id
          )
        ) {
          this.errorMessage =
            "Ce chapitre est déjà associé à l'axe thématique sélectionné.";
        } else {
          this.$emit('addChapterToThematic', this.selectedChapterData);
          this.resetData();
        }
      } else {
        this.errorMessage = 'Veuillez sélectionner un chapitre.';
      }
    },
  },
};
</script>
