<template>
  <details-modal
    :show="show"
    current-operation="create"
    @cancel="hideNewItemModal"
    @close="hideNewItemModal"
    @create="createItem"
  >
    <form>
      <discipline-select v-model="item.discipline"></discipline-select>
      <cycle-select v-model="item.cycle"></cycle-select>
      <thematic-select
        v-if="item.discipline && item.discipline.id"
        :isDefaultThematic="true"
        :discipline-id="item.discipline.id"
        @update:modelValue="setItemDefaultThematic"
      />
      <div
        class="item-addition-learning-objective"
        v-if="itemLearningObjective"
      >
        <label>Objectif d'apprentissage</label>
        <div class="item-addition-learning-objective-content">
          <h6>{{ itemLearningObjective.code }}</h6>
          <p>{{ itemLearningObjective.name }}</p>
        </div>
      </div>
    </form>
    <p class="errorMessageInModal" v-if="errorMessage">{{ errorMessage }}</p>
  </details-modal>
</template>

<script>
import ROUTES_NAMES from '@/constants/ROUTES_NAMES';
import DetailsModal from './DetailsModal.vue';
import CycleSelect from '../UIComponents/CycleSelect.vue';
import DisciplineSelect from '../UIComponents/DisciplineSelect.vue';
import ThematicSelect from '../UIComponents/ThematicSelect.vue';

export default {
  components: {
    DetailsModal,
    CycleSelect,
    DisciplineSelect,
    ThematicSelect,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      item: {
        cycle: null,
        defaultThematicId: null,
        discipline: {},
      },
      itemLearningObjective: null, // the learning objective is separated from the item itself because we don't want to watch it.
      errorMessage: '',
    };
  },
  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  watch: {
    item: {
      handler(newVal) {
        if (
          newVal.discipline &&
          newVal.discipline.id &&
          newVal.cycle &&
          newVal.defaultThematicId
        ) {
          this.$http
            .get(
              `/learningObjectives/search/byDisciplineCycleAndThematic?disciplineId=${newVal.discipline.id}&cycleId=${newVal.cycle}&thematicId=${newVal.defaultThematicId}`
            )
            .then((payload) => {
              this.itemLearningObjective = payload.data;
            })
            .catch(() => {
              this.errorMessage =
                "Une erreur est survenue lors de la récupération de l'objectif d'apprentissage associé, veuillez réessayer ou contacter un adminstrateur.";
            });
        }
      },
      deep: true,
    },
  },
  methods: {
    keyupHandler(event) {
      if (this.show) {
        if (event.key === 'Enter') {
          this.createItem();
        } else if (event.key === 'Escape') {
          this.hideNewItemModal();
        }
      }
    },
    resetData() {
      this.item = {
        cycle: null,
        defaultThematicId: null,
        discipline: {},
      };
      this.itemLearningObjective = null;
      this.errorMessage = '';
    },
    hideNewItemModal() {
      this.$emit('hideNewItemModal');
      this.resetData();
    },
    createItem() {
      this.errorMessage = '';

      if (
        this.item.discipline &&
        this.item.discipline.id &&
        this.item.cycle &&
        this.item.defaultThematicId
      ) {
        this.$http
          .post('/commands/items/', {
            disciplineId: this.item.discipline.id,
            cycle: this.item.cycle,
            defaultThematicId: this.item.defaultThematicId,
            defaultLearningObjectiveId: this.itemLearningObjective.id,
          })
          .then((response) => {
            this.hideNewItemModal();

            // Wait for the DOM to properly update (which means we are waiting for the modal to close) before redirecting the user to the new-created
            // item's page. We have to process this way in order to prevent the scrollbar to disapear after the creation.
            this.$nextTick(() => {
              const newItemId = response.headers.location.substr(
                response.headers.location.lastIndexOf('/') + 1
              );
              this.$router.push({
                name: ROUTES_NAMES.ITEM_DETAILS,
                params: { id: newItemId },
              });
            });
          })
          .catch(() => {
            this.errorMessage =
              'Une erreur est survenue lors de la création, veuillez réessayer ou contacter un adminstrateur.';
          });
      } else {
        this.errorMessage = 'Veuillez remplir tous les champs.';
      }
    },
    setItemDefaultThematic(thematic) {
      this.item.defaultThematicId = thematic.id;
    },
  },
};
</script>
