<template>
  <div>
    <item-card title="Identifiants" icon="nc-book-bookmark">
      <span
        ><i>{{ customCode }}</i></span
      ><br />
      <span>URL : </span><span>/items/{{ id }}</span>
    </item-card>

    <item-card title="Gestion" icon="nc-lock-circle-open">
      <generic-select
        :options="ITEM_METADATA.STATUS_OPTIONS"
        :disabled="!isItemEdited"
        v-model="status"
        label="Statut"
      />
      <generic-select
        :options="ITEM_METADATA.CANTONS_OPTIONS"
        :disabled="!isItemEdited"
        v-model="cantonOrigin"
        label="Origine"
      />
      <div class="form-group">
        <label class="control-label">Passations</label>
        <textarea
          v-model="passingYears"
          class="form-control"
          :disabled="!isItemEdited"
          rows="3"
        />
      </div>
    </item-card>

    <item-card title="Item informatisé" icon="nc-lock-circle-open">
      <generic-select
        :options="ITEM_METADATA.COMPUTERIZED_ITEM_OPTIONS"
        :disabled="!isItemEdited"
        v-model="computerizedItem"
        label="Item informatisé"
      />
      <div class="form-group">
        <label class="control-label">Fonctionnalité version informatisée</label>
        <textarea
          v-model="functionalitiesComputerizedItem"
          :disabled="!isItemEdited"
          class="form-control"
          rows="3"
        />
      </div>
    </item-card>

    <item-card title="Item multiple" icon="nc-lock-circle-open">
      <generic-select
        :options="ITEM_METADATA.YES_NO_OPTIONS"
        :disabled="!isItemEdited"
        v-model="visibility"
        label="Visibilité"
      />
      <fg-input
        v-model="multipleItem"
        :disabled="!isItemEdited"
        type="text"
        label="Item multiple"
      />
      <fg-input
        v-model="belongsToMultipleItem"
        :disabled="!isItemEdited"
        type="text"
        label="Fait partie d'un item multiple"
      />
    </item-card>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import GenericSelect from '../GenericSelect.vue';
import ItemCard from './ItemCard.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    GenericSelect,
    ItemCard,
  },
  data() {
    return {
      ITEM_METADATA,
    };
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "customCode" for "management.customCode").
    ...mapFields([
      'isItemEdited',
      'itemData.id',
      'itemData.management.customCode',
      'itemData.management.status',
      'itemData.management.cantonOrigin',
      'itemData.management.passingYears',
      'itemData.management.computerizedItem',
      'itemData.management.functionalitiesComputerizedItem',
      'itemData.management.visibility',
      'itemData.management.multipleItem',
      'itemData.management.belongsToMultipleItem',
    ]),
  },
};
</script>
