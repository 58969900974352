export default {
  methods: {
    getDifficultyNameByValue(value) {
      let difficultyName;

      if (value <= -0.61) {
        difficultyName = 'Facile (F)';
      } else if (value <= -0.21) {
        difficultyName = 'Plutôt facile (PF)';
      } else if (value <= 0.2) {
        difficultyName = 'Intermédiaire (I)';
      } else if (value <= 0.6) {
        difficultyName = 'Plutôt difficile (PD)';
      } else {
        difficultyName = 'Difficile (D)';
      }

      return difficultyName;
    },
  },
};
