<template>
  <item-card title="Journal de bord" icon="nc-chat-33">
    <div class="messages-container" ref="messagesContainer">
      <card
        v-for="message in messages"
        :key="message.id"
        :style="{
          backgroundColor: textToHslColor(message.author, lightColorType),
        }"
      >
        <button
          v-if="message.authorEmail === userMail || isAdministrator"
          class="btn btn-icon btn-round btn-danger btn-remove-message"
          @click="removeMessage(message.id)"
        >
          <i class="fa fa-minus" />
        </button>
        <p>
          <strong
            :style="{
              color: textToHslColor(message.author, darkColorType),
            }"
            >{{ message.author }}</strong
          ><br />
          {{ message.content }}
        </p>
        <span class="message-item-timestamp">{{ message.timestamp }}</span>
      </card>
      <card v-if="!messages.length" class="message-no-item"
        >Le journal de bord ne contient actuellement aucun message.
      </card>
    </div>
    <form class="search-form">
      <div class="input-group no-border">
        <input
          v-model="newMessage"
          type="text"
          class="form-control"
          placeholder="Envoyer un nouveau message..."
        />
        <div class="input-group-append">
          <button
            class="btn btn-icon btn-send-message"
            @click.prevent="sendMessage"
          >
            <i class="nc-icon nc-send" />
          </button>
        </div>
      </div>
    </form>
  </item-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import COMMON from '@/constants/COMMON';
import mixinSwal from '@/mixins/mixinSwal';
import mixinMessages from '@/mixins/mixinMessages';
import { getFormatedDateTime } from '@/services/DateService';
import { Card } from 'src/components/UIComponents';
import ItemCard from './ItemCard.vue';

export default {
  components: {
    Card,
    ItemCard,
  },
  mixins: [mixinSwal, mixinMessages],
  data() {
    return {
      messages: [],
      newMessage: '',
    };
  },
  computed: {
    ...mapState({
      userMail: (state) => state.account.email,
      itemId: (state) => state.item.itemData.id,
    }),
    ...mapGetters({
      isAdministrator: 'account/isAdministrator',
    }),
  },
  created() {
    this.fetchMessages();
  },
  watch: {
    itemId: {
      handler(newVal) {
        if (newVal) {
          this.fetchMessages();
        }
      },
    },
    messages: {
      handler() {
        // Wait for the DOM to be refreshed, then scroll the panel down to see the new-posted message.
        this.$nextTick(() => {
          this.$refs.messagesContainer.scrollTop =
            this.$refs.messagesContainer.scrollHeight;
        });
      },
    },
  },
  methods: {
    fetchMessages() {
      this.$http
        .get(
          `/itemsMessages/search/byItemId?itemId=${this.itemId}&projection=itemMessage`
        )
        .then((response) => {
          // Add every message item in the local memory.
          if (
            response.data._embedded.itemsMessages &&
            response.data._embedded.itemsMessages.length
          ) {
            this.messages = response.data._embedded.itemsMessages.map(
              (message) => ({
                ...message,
                timestamp: getFormatedDateTime(message.timestamp),
              })
            );
          }
        })
        .catch(() => {
          this.$store.commit(
            'item/setErrorMessage',
            'Une erreur est survenue lors de la récupération des messages du journal de bord, veuillez réessayer ou contacter un administrateur.'
          );
        });
    },
    removeMessage(messageId) {
      this.$store.commit('item/resetErrorMessage');

      this.showSwal(
        'Êtes-vous sûr de vouloir supprimer cette entrée du journal de bord ?'
      )
        .then(() =>
          this.$http
            .delete(`/commands/items/${this.itemId}/messages/${messageId}`)
            .then(() => {
              this.messages = this.messages.filter(
                (message) => message.id !== messageId
              );
            })
            .catch(() => {
              this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
            })
        )
        .catch((error) => {
          if (error !== 'cancel') {
            this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
          }
        });
    },
    sendMessage() {
      this.$store.commit('item/resetErrorMessage');

      // Check that the text field is not empty.
      if (this.newMessage) {
        this.$http
          .post(`/commands/items/${this.itemId}/messages`, {
            content: this.newMessage,
          })
          .then((response) => {
            this.newMessage = '';

            const formattedMessageData = {
              ...response.data,
              timestamp: getFormatedDateTime(response.data.timestamp),
            };
            // Add the new message in the messages array in order to display it.
            this.messages.push(formattedMessageData);
          })
          .catch(() => {
            this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
          });
      }
    },
  },
};
</script>

<style scoped>
.messages-container {
  margin-bottom: 1em;
  max-height: 580px;
  overflow: scroll;
}

.messages-container > .card:last-child {
  margin-bottom: 8px;
}

.messages-container .btn-remove-message {
  float: right;
  height: 1.3rem !important;
  margin: 0 0.75rem 0 0;
  min-width: 1.3rem !important;
  width: 1.3rem !important;
  z-index: 1000;
}

.btn-send-message {
  background-color: rgba(222, 222, 222, 0.5);
  margin: 0;
}

.btn-send-message:hover {
  background-color: rgba(222, 222, 222, 1) !important;
}

.btn-send-message i {
  color: #66615b;
}

.message-item-timestamp {
  color: #888;
  font-size: 0.9em;
  bottom: 0.5em;
  position: absolute;
  right: 1em;
}

.message-no-item {
  background-color: rgba(222, 222, 222, 0.3);
  font-style: italic;
}
</style>
