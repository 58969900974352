/*
 * Make sure the tables are aligned: https://github.com/ueberdosis/tiptap/issues/2041.
 */

/* eslint-disable no-param-reassign */
import Table from '@tiptap/extension-table';
// eslint-disable-next-line import/no-extraneous-dependencies
import { mergeAttributes } from '@tiptap/core';

export default Table.extend({
  addAttributes() {
    return {
      style: {
        default: null,
      },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    let totalWidth = 0;
    let fixedWidth = true;

    try {
      // use first row to determine width of table;
      const tr = node.content.content[0];
      tr.content.content.forEach((td) => {
        if (td.attrs.colwidth) {
          td.attrs.colwidth.forEach((col) => {
            if (!col) {
              fixedWidth = false;
              totalWidth += this.options.cellMinWidth;
            } else {
              totalWidth += col;
            }
          });
        } else {
          fixedWidth = false;
          const colspan = td.attrs.colspan ? td.attrs.colspan : 1;
          totalWidth += this.options.cellMinWidth * colspan;
        }
      });
    } catch (error) {
      fixedWidth = false;
    }

    if (fixedWidth && totalWidth > 0) {
      HTMLAttributes.style = `width: ${totalWidth}px;`;
    } else if (totalWidth && totalWidth > 0) {
      HTMLAttributes.style = `min-width: ${totalWidth}px`;
    } else {
      HTMLAttributes.style = null;
    }

    return [
      'div',
      { class: 'table-wrapper' },
      [
        'table',
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        ['tbody', 0],
      ],
    ];
  },
});
