// eslint-disable-next-line import/no-extraneous-dependencies
import { Node, nodeInputRule, mergeAttributes } from '@tiptap/core';
import { VueNodeViewRenderer } from '@tiptap/vue-3';
import MathNodeWrapper from './MathNodeWrapper.vue';

export default Node.create({
  name: 'math',
  group: 'inline',
  atom: true,
  inline: true,

  addAttributes() {
    return {
      equation: {
        default: '',
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'math',
      },
    ];
  },
  renderHTML({ HTMLAttributes }) {
    return ['math', mergeAttributes(HTMLAttributes)];
  },
  addNodeView() {
    return VueNodeViewRenderer(MathNodeWrapper);
  },
  addCommands() {
    return {
      setEquation:
        (equation) =>
        ({ commands }) => {
          return commands.updateAttributes('math', {
            equation,
          });
        },
    };
  },
  addInputRules() {
    return [
      nodeInputRule({
        find: /((?:\$)([^$\s]+(?:\s+[^$\s]+)*)(?:\$))$/,
        type: this.type,
        getAttributes: (match) => {
          const [, , equation] = match;
          return { equation };
        },
      }),
    ];
  },
});
