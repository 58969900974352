<template>
  <materials
    :material-type="
      ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.MathMaterialEntity.value
    "
    title="Matériaux externes - mathématiques"
  />
</template>

<script>
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import Materials from './UIComponents/materials/Materials.vue';

export default {
  components: {
    Materials,
  },
  data() {
    return {
      ITEM_METADATA,
    };
  },
};
</script>
