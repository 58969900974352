import routes from '@/routes/routes';
import store from '@/store';
import { app } from '@/main';

export const formatRoutes = (routesToFormat, parent) => {
  return routesToFormat.flatMap((route) => {
    if (route.children) {
      return formatRoutes(route.children, route.path);
    }
    return [
      {
        ...route,
        path: parent + route.path,
      },
    ];
  });
};

export const isUserAllowedByRoles = (rolesNeeded) => {
  const rolesUser = store.getters['account/roles'];
  return rolesNeeded.filter((role) => rolesUser.includes(role)).length > 0;
};

export const isUserAllowedByLvl = (rolesNeeded) => {
  if (rolesNeeded.lvl === 0) {
    return true;
  }

  const rolesUser = store.getters['account/roles'];
  let isLvlEnough = false;
  rolesUser.forEach((role) => {
    if (role.value >= rolesNeeded.lvl) {
      isLvlEnough = true;
    }
  });
  return isLvlEnough;
};

export const getRolesNeeded = (to) => {
  const routesFormatted = formatRoutes(routes, '');
  const toArray = to.path.split('/').filter((e) => e !== '');
  const routesPossible = routesFormatted.filter((route) => {
    const routeArray = route.path.split('/').filter((e) => e !== '');
    return (
      routeArray.length === toArray.length &&
      !routeArray.find(
        (element, index) =>
          !element.startsWith(':') && element !== toArray[index]
      )
    );
  });
  return routesPossible.length > 0 ? routesPossible[0].permissions : null;
};

export const isAllowed = (to) => {
  const rolesNeeded = getRolesNeeded(to);

  // PERMISSION DEFINED BY ROLES
  if (rolesNeeded && rolesNeeded.roles) {
    return isUserAllowedByRoles(rolesNeeded.roles);
  }

  // PERMISSION DEFINED BY LVL
  if (rolesNeeded && rolesNeeded.lvl !== undefined) {
    return isUserAllowedByLvl(rolesNeeded);
  }
  // PERMISSION DEFINED BY IS CONNECTED
  if (rolesNeeded && rolesNeeded.auth) {
    // The check if token expired done in router
    return !!store.getters['account/token']; // return true if have a token
  }
  // NO PERMISSION DEFINED

  return false; // No public so return false
};

export const verifyAndRenewToken = async () => {
  if (
    store.getters['account/token'] &&
    new Date().getTime() > store.getters['account/expiration']
  ) {
    store.commit('account/clear'); // We need to clear because we need a new token

    try {
      await store.dispatch('account/login');

      if (store.getters['account/token']) {
        app.config.globalProperties.$notify({
          text: 'Vous avez été reconnecté.',
          title: 'Session expirée',
          duration: 10000,
          ignoreDuplicates: true,
        });
      } else {
        // eslint-disable-next-line no-throw-literal
        throw 'Token expired and not authenticated';
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Shib session is not available', error);

      app.config.globalProperties.$notify({
        text: 'Vous avez été déconnecté car votre session a expiré, veuillez vous reconnecter.',
        title: 'Session expirée',
        duration: 10000,
        ignoreDuplicates: true,
      });
    }
  }
};
