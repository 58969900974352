<template>
  <div>
    <div class="material-explanation">
      Afin d'ajouter un matériel à l'item, il faut au préalable l'avoir créé
      dans la base des matériaux (rubrique "Matériaux Externes"). Il suffit
      ensuite d'entrer son nom dans le champ ci-dessous, puis d'appuyer sur le
      bouton "+".
    </div>
    <div class="material-container">
      <hr />
      <div v-if="!hasAtLeastOneMaterial" class="material-explanation mb-2">
        <i>Aucun matériel de cette catégorie n'a été ajouté à cet item.</i>
      </div>
      <form
        v-else
        v-for="(externalMaterial, index) in filteredByTypeExternalMaterials"
        :key="externalMaterial.id"
      >
        <div class="row mb-3">
          <div
            class="col-md-12 d-flex flex-wrap justify-content-between align-items-center"
          >
            <h6 class="mt-2" v-if="!isItemEdited">
              Matériel externe "{{ externalMaterial.documents[0].name }}"
            </h6>
            <div
              v-if="isItemEdited"
              class="d-flex flex-wrap align-items-end mt-2"
            >
              <label class="mr-3">Position du matériel dans la liste</label>
              <div>
                <button
                  :disabled="isFirstMaterial(index)"
                  type="button"
                  class="btn btn-icon btn-round btn-default btn-material btn-sm"
                  @click="moveUpMaterial(externalMaterial.id)"
                >
                  <i class="nc-icon nc-minimal-up" />
                </button>
                <button
                  :disabled="isLastMaterial(index)"
                  type="button"
                  class="btn btn-icon btn-round btn-default btn-material btn-sm"
                  @click="moveDownMaterial(externalMaterial.id)"
                >
                  <i class="nc-icon nc-minimal-down" />
                </button>
              </div>
            </div>
            <div class="mt-2">
              <el-tooltip
                v-if="isItemEdited"
                class="item"
                content="Supprimer le matériel de l'item"
                placement="left"
              >
                <button
                  type="button"
                  class="btn btn-icon btn-round btn-danger m-0"
                  @click="removeExternalMaterial(externalMaterial)"
                >
                  <i class="fa fa-minus" />
                </button>
              </el-tooltip>
              <el-tooltip
                class="item"
                content="Ouvrir les détails du matériel dans un nouvel onglet"
                placement="right"
              >
                <button
                  type="button"
                  class="btn btn-icon btn-round btn-info btn-information my-0 ml-3 mr-1"
                  @click="viewExternalMaterial(externalMaterial)"
                >
                  <i class="nc-icon nc-alert-circle-i" />
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-for="(document, index) in externalMaterial.documents"
          v-bind:key="document.id"
        >
          <div
            class="d-flex justify-content-end flex-wrap align-items-end mb-2 col-md-12"
          >
            <fg-input
              :disabled="true"
              :label="documentTitle(index)"
              v-model="document.name"
              type="text"
              class="m-0 mr-3 flex-grow-1"
            />
            <div class="mt-2">
              <el-tooltip
                class="item"
                content="Télécharger le document"
                placement="right"
              >
                <button
                  type="button"
                  class="btn btn-icon btn-round btn-primary btn-material"
                  @click="downloadDocument(document.id)"
                >
                  <i class="nc-icon nc-cloud-download-93" />
                </button>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div v-if="isMaterialsFrancaisAdvancedType">
          <div class="row">
            <div class="col-md-12">
              <label>Indice de difficulté du matériel externe</label>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <fg-input
                :disabled="true"
                v-model="externalMaterial.textDifficultyIndexName"
                type="text"
              />
            </div>
            <div class="col-md-4">
              <fg-input
                :disabled="true"
                v-model="externalMaterial.textDifficultyIndexValue"
                type="text"
              />
            </div>
          </div>
        </div>
        <hr class="my-4" />
      </form>
    </div>
    <div v-if="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <div v-if="isItemEdited" class="d-flex flex-row align-items-center">
      <material-select
        v-model="externalMaterialToAdd"
        :material-type="materialType"
      />
      <button
        :disabled="!externalMaterialToAdd"
        type="button"
        class="btn btn-icon btn-round btn-success ml-2"
        @click="addExternalMaterial"
      >
        <i class="fa fa-plus" />
      </button>
    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import mixinSwal from '@/mixins/mixinSwal';
import mixinTextTransform from '@/mixins/mixinTextTransform';
import COMMON from '@/constants/COMMON';
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import MaterialSelect from '@/components/ApplicationViews/UIComponents/MaterialSelect.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields, mapMultiRowFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    MaterialSelect,
  },
  props: {
    materialType: {
      type: String,
      required: true,
    },
  },
  mixins: [mixinSwal, mixinTextTransform],
  data() {
    return {
      externalMaterialToAdd: null,
      errorMessage: '',
    };
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "cycle" for "per.cycle").
    ...mapFields(['isItemEdited']),
    // And with this one we could refer and mutate the array fields.
    ...mapMultiRowFields(['itemData.contentAndSolution.externalMaterials']),
    isMaterialsFrancaisAdvancedType() {
      return (
        this.materialType ===
        ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.FrancaisAdvancedMaterialEntity
          .value
      );
    },
    filteredByTypeExternalMaterials() {
      return this.externalMaterials.filter(
        (material) => material.type === this.materialType
      );
    },
    hasAtLeastOneMaterial() {
      return this.filteredByTypeExternalMaterials.length > 0;
    },
  },
  methods: {
    documentTitle(index) {
      switch (index) {
        case 0:
          return 'Document principal (PDF)';
        case 1:
          return 'Document(s) secondaire(s)';
        default:
          return '';
      }
    },
    viewExternalMaterial(externalMaterial) {
      const pascalCaseExternalMaterialType = this.capitalizeFirstLetter(
        externalMaterial.type
      );

      const routeData = this.$router.resolve({
        name: `${pascalCaseExternalMaterialType}MaterialDetails`,
        params: { id: externalMaterial.id, newMaterialCreation: false },
      });

      window.open(routeData.href, '_blank');
    },
    downloadDocument(documentId) {
      this.$http
        .get(
          `/commands/${this.materialType.toLowerCase()}materials/documents/download/${documentId}`,
          {
            headers: {
              Accept: 'application/pdf',
            },
            responseType: 'blob',
          }
        )
        .then((response) => {
          const headerContent = response.headers['content-disposition'];
          const startFileNameIndex = headerContent.indexOf('"') + 1;
          const endFileNameIndex = headerContent.lastIndexOf('"');
          const filename = headerContent.substring(
            startFileNameIndex,
            endFileNameIndex
          );
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        })
        .catch(() => {
          this.errorMessage = COMMON.BASIC_ERROR;
        });
    },
    removeExternalMaterial(externalMaterial) {
      this.errorMessage = '';

      this.showSwal(
        "Êtes-vous sûr de vouloir retirer ce matériel de l'item ?",
        'Les changements ne seront pas sauvegardés tant que vous n\'aurez pas manuellement appuyé sur le bouton "Enregistrer".'
      )
        .then(() => {
          this.$store.commit('item/removeExternalMaterial', externalMaterial);
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.errorMessage = COMMON.BASIC_ERROR;
          }
        });
    },
    isFirstMaterial(index) {
      return index === 0;
    },
    isLastMaterial(index) {
      return index === this.filteredByTypeExternalMaterials.length - 1;
    },
    moveUpMaterial(externalMaterialId) {
      this.$store.commit('item/moveUpMaterial', externalMaterialId);
    },
    moveDownMaterial(externalMaterialId) {
      this.$store.commit('item/moveDownMaterial', externalMaterialId);
    },
    addExternalMaterial() {
      this.errorMessage = '';

      const newMaterialData = {
        ...this.externalMaterialToAdd,
        type: this.materialType,
      };

      if (this.externalMaterialToAdd) {
        try {
          this.$store.commit('item/addExternalMaterial', newMaterialData);
          this.externalMaterialToAdd = null;
        } catch (error) {
          this.errorMessage = error.message;
        }
      }
    },
  },
};
</script>

<style scoped>
.icon-big {
  font-size: 1.8em;
}

.btn-material {
  margin: 0 0.25em;
}

.material-explanation {
  color: #6c757d;
  font-size: 13px;
  font-style: italic;
  margin-left: 5px;
}

.material-container {
  margin-top: 0.8em;
}

.btn-information {
  font-size: 1.4rem !important;
}
</style>
