<template>
  <div :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications
      transition-name="notification-list"
      transition-mode="out-in"
    />
    <router-view name="header" />
    <transition name="fade" mode="out-in">
      <div>
        <router-view />
      </div>
    </transition>
    <router-view name="footer" />
  </div>
</template>

<script>
// Loading some plugin css asynchronously
// eslint-disable-next-line no-unused-expressions
import('sweetalert2/dist/sweetalert2.css');

export default {};
</script>
