<template>
  <div>
    <div v-if="isItemDisciplineMath" class="equations-explanation">
      Il est possible d'insérer des formules mathématiques dans les champs
      ci-dessous en cliquant sur le bouton
      <i class="fa fa-square-root-alt mx-1" /> du champ, ou en écrivant
      directement dans ce dernier une formule encapsulée par deux symboles
      <code class="ml-1">$</code> (par exemple <code>$a^2+x$</code>). Veuillez
      ensuite vous référer à
      <a href="https://katex.org/docs/supported.html" target="_blank"
        >la documentation de rédaction</a
      >
      de KaTeX.
    </div>

    <item-card
      title="Description générale de la tâche"
      icon="nc-single-copy-04"
    >
      <rich-text-editor
        v-model="generalTaskDescription"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="general-task-description"
      />
    </item-card>

    <div v-if="isItemDisciplineMath || isItemDisciplineFrench">
      <item-card title="Titre" icon="nc-single-copy-04">
        <fg-input v-model="title" :disabled="!isItemEdited" type="text" />
      </item-card>
    </div>

    <item-card title="Contenu" icon="nc-single-copy-04">
      <rich-text-editor
        v-model="content"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="content"
      />
    </item-card>

    <item-card title="Variante" icon="nc-single-copy-04">
      <fg-input v-model="variant" :disabled="!isItemEdited" type="text" />
    </item-card>

    <item-card title="Corrigé" icon="nc-single-copy-04">
      <rich-text-editor
        v-model="solution"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="solution"
      />
    </item-card>

    <item-card title="Matériel associé" icon="nc-single-copy-04">
      <rich-text-editor
        v-model="associatedMaterial"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="associated-material"
      />
    </item-card>

    <div v-if="isItemDisciplineFrench">
      <item-card title="Matériaux externes (tâches)" icon="nc-single-copy-04">
        <external-materials-panel
          :material-type="
            ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.FrancaisSimpleMaterialEntity
              .value
          "
        />
      </item-card>
      <item-card
        title="Matériaux externes (textes/corpus)"
        icon="nc-single-copy-04"
      >
        <external-materials-panel
          :material-type="
            ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.FrancaisAdvancedMaterialEntity
              .value
          "
        />
      </item-card>
    </div>

    <div v-if="isItemDisciplineMath">
      <item-card title="Matériaux externes" icon="nc-single-copy-04">
        <external-materials-panel
          :material-type="
            ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.MathMaterialEntity.value
          "
        />
      </item-card>
    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import PER_CONSTANTS from '@/constants/PER_CONSTANTS';
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import ItemCard from './ItemCard.vue';
import RichTextEditor from '../RichTextEditor/RichTextEditor.vue';
import ExternalMaterialsPanel from './ExternalMaterialsPanel.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    ItemCard,
    RichTextEditor,
    ExternalMaterialsPanel,
  },
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ITEM_METADATA,
    };
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "cycle" for "per.cycle").
    ...mapFields([
      'isItemEdited',
      'itemData.contentAndSolution.generalTaskDescription',
      'itemData.contentAndSolution.title',
      'itemData.contentAndSolution.content',
      'itemData.contentAndSolution.variant',
      'itemData.contentAndSolution.solution',
      'itemData.contentAndSolution.associatedMaterial',
    ]),
    isItemDisciplineFrench() {
      return this.disciplineId === PER_CONSTANTS.PER_DISCIPLINE_ID_FRANCAIS.ID;
    },
    isItemDisciplineMath() {
      return this.disciplineId === PER_CONSTANTS.PER_DISCIPLINE_ID_MATH.ID;
    },
  },
};
</script>

<style scoped>
.equations-explanation {
  color: #6c757d;
  font-size: 13px;
  font-style: italic;
  margin: 0 0.75em 0.75em 0.75em;
}
</style>
