<template>
  <div>
    <modal
      :show="modals.synchro"
      footer-classes="justify-content-center"
      type="notice"
      @close="closeModal"
    >
      <template #header>
        <h5 class="modal-title">Synchronisation PER</h5>
      </template>
      <div>
        Cette fonction vous donne la possibilité de récupérer les données de
        base du Plan d'Etudes Romand (PER).
      </div>
      <div>
        Cette synchronisation ne doit être faite que si des données ont été
        mises à jour dans la base de données du PER.
      </div>
      <template #footer>
        <div class="justify-content-center d-flex p-2 w-100">
          <p-button
            class="mx-1 my-1 w-50"
            type="success"
            round
            @click="startSynchro()"
            >Lancer la synchronisation
          </p-button>
          <p-button
            class="mx-1 my-1 w-50"
            type="light"
            round
            @click="closeModal()"
            >Annuler
          </p-button>
        </div>
      </template>
    </modal>
    <div class="row">
      <div class="col-md-12">
        <div v-if="errorMessage" class="alert alert-danger">
          {{ errorMessage }}
        </div>

        <crud-list
          ref="crudList"
          :fetch-data="fetchSynchroTasks"
          title="Tâches de synchronisation"
          @create="create"
          @row-clicked="rowClicked"
        >
          <el-table-column label="Démarrée" min-width="100">
            <template #default="slotProps">
              <div>
                {{ getSentenceFormatedDateTime(slotProps.row.startTime) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Terminée" min-width="100">
            <template #default="slotProps">
              <div v-if="slotProps.row.completionTime">
                {{ getSentenceFormatedDateTime(slotProps.row.completionTime) }}
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="Etat" min-width="100">
            <template #default="slotProps">
              <div>{{ slotProps.row.status }}&nbsp;</div>
            </template>
          </el-table-column>
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import COMMON from '@/constants/COMMON';
import { Modal } from 'src/components/UIComponents';
import { getSentenceFormatedDateTime } from '@/services/DateService';
import CrudList from './UIComponents/CrudList.vue';

export default {
  components: {
    CrudList,
    Modal,
  },
  data() {
    return {
      modals: {
        synchro: false,
      },
      currentOperation: {},
      cycle: {},
      errorMessage: '',
      getSentenceFormatedDateTime,
    };
  },
  methods: {
    rowClicked(row) {},
    fetchSynchroTasks(pageNumber, pageSize, searchText) {
      let url;
      if (searchText) {
        url = `/synchroTasks/search/fulltext?sort=name&name=${searchText}&page=${
          pageNumber - 1
        }&size=${pageSize}`;
      } else {
        url = `/synchroTasks?sort=name&page=${pageNumber - 1}&size=${pageSize}`;
      }
      return this.$http.get(url).then((response) => ({
        records: response.data._embedded.synchroTasks
          ? response.data._embedded.synchroTasks
          : [],
        page: response.data.page,
      }));
    },
    startSynchro() {
      this.$http
        .post('/commands/sync')
        .then((response) => {
          this.$refs.crudList.fetch();
          this.errorMessage = '';
          this.modals.synchro = false;
        })
        .catch((error) => {
          this.errorMessage = COMMON.BASIC_ERROR;
        });
    },
    create() {
      this.synchroTask = {};
      this.currentOperation = 'create';
      this.modals.synchro = true;
    },
    closeModal() {
      this.modals.synchro = false;
      this.errorMessage = '';
    },
  },
};
</script>
