/* eslint-disable no-param-reassign */
import Image from '@tiptap/extension-image';
// eslint-disable-next-line import/no-extraneous-dependencies
import { mergeAttributes } from '@tiptap/core';

export default Image.extend({
  name: 'resizable-image',

  addAttributes() {
    return {
      ...Image.config.addAttributes(),
      width: {
        default: '1920',
        parseHTML: (element) => element.getAttribute('width'),
      },
    };
  },

  addCommands() {
    return {
      resizeImage:
        (width) =>
        ({ tr, commands }) => {
          if (tr.selection?.node?.type?.name === 'resizable-image') {
            return commands.updateAttributes('resizable-image', { width });
          }

          return commands.insertContent({
            type: this.name,
            attrs: {
              width,
            },
          });
        },
    };
  },

  renderHTML({ node, HTMLAttributes }) {
    HTMLAttributes.width = node.attrs.width;

    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },
});
