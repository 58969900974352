const PER_CONSTANTS = {
  PER_DISCIPLINE_ID_FRANCAIS: {
    ID: 1,
    KEY: 'Francais',
  },
  PER_DISCIPLINE_ID_MATH: {
    ID: 5,
    KEY: 'Math',
  },
};

export default PER_CONSTANTS;
