<template>
  <div>
    <label>Progression d'apprentissage</label>
    <div class="row form-group2">
      <el-select
        v-model="selectedProgressionId"
        :disabled="disabled"
        class="select-info"
        size="large"
        filterable
        clearable
        no-match-text="Aucune progression n'a été trouvée"
        placeholder="Sélectionner"
        @update:modelValue="selectProgression"
      >
        <el-option
          v-for="progression in progressions"
          :value="progression.id"
          :label="progression.description"
          :key="progression.id"
          :title="progression.description"
          class="select-info option-progressions"
        >
          <span v-html="progression.description"></span>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import he from 'he';

export default {
  props: {
    modelValue: {
      type: Object, // This component emits the whole selected thematic object instead of just its ID, in order for the parents to work with its data.
    },
    learningObjectiveId: {
      type: Number,
    },
    years: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      progressions: [],
      selectedProgressionId: null,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    learningObjectiveId: {
      handler() {
        this.fetchData();
      },
    },
    modelValue: {
      handler(newVal) {
        // Reset the select list if the value of the component is null.
        if (!newVal) {
          this.selectedProgressionId = null;
        }
      },
    },
  },
  methods: {
    fetchData() {
      if (this.learningObjectiveId) {
        let query = '/progressions/search/';

        // If the user set the years array we must fetch the progressions that have the given learning objective and that occur during the given years.
        // For that, we need to create a regex string in which each year must begin and end with a semicolon, and is separated by the '|' character
        // with the other years between them, e.g. if we want to fetch progressions that are either in the 1st or 3rd year, the regex will look like this: ";1;|;3;".
        if (this.years) {
          // HTML code (contained in URLs) for the ';' symbol.
          const semiColonHtmlCode = '%3B';
          // HTML code (contained in URLs) for the '|' symbol.
          const orHtmlCode = '%7C';

          query += `byLearningObjectiveIdAndYearsRegex?learningObjectiveId=${this.learningObjectiveId}&yearsRegex=`;

          // Append years in the regex.
          this.years.forEach((year) => {
            query += `${semiColonHtmlCode}${year}${semiColonHtmlCode}${orHtmlCode}`;
          });

          // Remove the last '|'.
          query = query.slice(0, -orHtmlCode.length);
        } else {
          // Othwerwise we just need to fetch the progressions by the given learning objective.
          query += `byLearningObjectiveId?learningObjectiveId=${this.learningObjectiveId}`;
        }

        return this.$http.get(query).then((payload) => {
          this.progressions = payload.data._embedded.progressions.map(
            (entity) => {
              return {
                id: entity.id,
                description: he.decode(entity.description.trim()),
              };
            }
          );
        });
      }

      return null;
    },
    selectProgression() {
      const selectedProgression = this.progressions.filter(
        (progression) => progression.id === this.selectedProgressionId
      )[0];

      // This component emits the whole selected thematic object instead of just its ID, in order for the parents to work with its data.
      this.$emit('update:modelValue', selectedProgression);
    },
  },
};
</script>

<style>
.option-progressions {
  max-width: calc(100vw / 2);
}
</style>
