<template>
  <div>
    <new-progression-modal
      v-if="isItemEdited && id"
      :show="showNewProgressionModal"
      :itemId="id"
      :itemThematicId="itemThematicId"
      :learningObjectiveId="learningObjectiveId"
      :years="years"
      @addProgressionToThematic="addProgressionToThematic"
      @hideModalProgression="hideModalProgression"
    />

    <label class="progressions-title">Progression(s)</label>
    <button
      v-if="isItemEdited"
      class="btn btn-icon btn-round btn-success btn-create-progression"
      @click="showModalProgression"
    >
      <i class="fa fa-plus" />
    </button>

    <div class="accordion">
      <accordion-panel
        v-for="progression in progressions"
        class="progression-panel"
        :key="progression.id"
        :identifier="progression.id"
        :title="progression.description"
        :deletable="isItemEdited"
        @removeItem="removeProgression(progression)"
      >
        <label>Nom complet</label>
        <div v-html="progression.description"></div>
      </accordion-panel>

      <div v-if="isProgressionsEmpty" class="no-progression">
        Aucune progression n'est actuellement associée à cet axe thématique.
      </div>
    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import COMMON from '@/constants/COMMON';
import mixinSwal from '@/mixins/mixinSwal';
import AccordionPanel from '../AccordionPanel.vue';
import NewProgressionModal from '../../Modals/NewProgressionModal.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    AccordionPanel,
    NewProgressionModal,
  },
  mixins: [mixinSwal],
  props: {
    itemThematicId: {
      type: Number,
      required: true,
    },
    learningObjectiveId: {
      type: Number,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    },
    progressions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showNewProgressionModal: false,
    };
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "cycle" for "per.cycle").
    ...mapFields(['isItemEdited', 'itemData.id']),
    isProgressionsEmpty() {
      return this.progressions.length === 0;
    },
  },
  methods: {
    showModalProgression() {
      this.showNewProgressionModal = true;
    },
    hideModalProgression() {
      this.showNewProgressionModal = false;
    },
    addProgressionToThematic(progressionData) {
      this.$store.commit('item/addProgressionToItemThematic', {
        progression: progressionData,
        itemThematicId: this.itemThematicId,
      });

      this.hideModalProgression();
    },
    removeProgression(progression) {
      this.$store.commit('item/resetErrorMessage');

      this.showSwal(
        "Êtes-vous sûr de vouloir retirer cette progression de l'axe thématique ?",
        'Les changements ne seront pas sauvegardés tant que vous n\'avez pas manuellement appuyé sur le bouton "Enregistrer".'
      )
        .then(() => {
          this.$store.commit('item/removeProgressionOfItemThematic', {
            progression,
            itemThematicId: this.itemThematicId,
          });
          this.$store.commit('item/resetErrorMessage');
        })
        .catch((error) => {
          if (error !== 'cancel') {
            this.$store.commit('item/setErrorMessage', COMMON.BASIC_ERROR);
          }
        });
    },
  },
};
</script>

<style scoped>
.accordion {
  margin: 0.25em 0 1em 0;
}

.progression-panel {
  margin-top: -1px !important;
}

.progressions-title {
  margin-top: 0.9em;
}

.btn-create-progression {
  height: 1.3rem !important;
  margin: 0 0 0 0.5rem;
  min-width: 1.3rem !important;
  width: 1.3rem !important;
}

.no-progression {
  font-size: 0.8571em;
  font-style: italic;
  margin-bottom: 1.5em;
}
</style>
