/*
 * There is a bug in the default table library, causing the tables to not retain their
 * columns width when the editor isn't editable, so we created custom extensions in
 * order to make it work: https://github.com/ueberdosis/tiptap/issues/2041.
 */

/* eslint-disable no-param-reassign */
import TableCell from '@tiptap/extension-table-cell';
// eslint-disable-next-line import/no-extraneous-dependencies
import { mergeAttributes } from '@tiptap/core';

export default TableCell.extend({
  addAttributes() {
    return {
      ...this.parent(),
      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute('colwidth');
          const value = colwidth
            ? colwidth.split(',').map((item) => parseInt(item, 10))
            : null;

          return value;
        },
      },
      style: {
        default: null,
      },
    };
  },

  renderHTML({ HTMLAttributes }) {
    let totalWidth = 0;
    let fixedWidth = true;

    if (HTMLAttributes.colwidth) {
      HTMLAttributes.colwidth.forEach((col) => {
        if (!col) {
          fixedWidth = false;
        } else {
          totalWidth += col;
        }
      });
    } else {
      fixedWidth = false;
    }

    if (fixedWidth && totalWidth > 0) {
      HTMLAttributes.style = `width: ${totalWidth}px;`;
    } else if (totalWidth && totalWidth > 0) {
      HTMLAttributes.style = `min-width: ${totalWidth}px`;
    } else {
      HTMLAttributes.style = null;
    }

    return [
      'td',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});
