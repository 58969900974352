import moment from 'moment';

function getFormatedDateTime(dateTime) {
  const timestamp = moment(dateTime).format('DD.MM.YYYY, HH:mm:ss');
  return timestamp;
}

function getSentenceFormatedDateTime(dateTime) {
  const timestamp = moment(dateTime).format('DD.MM.YYYY à HH:mm:ss');
  return timestamp;
}

export { getFormatedDateTime, getSentenceFormatedDateTime };
