<template>
  <card>
    <div class="pull-right text-info">
      <i class="icon-big nc-icon" :class="icon" />
    </div>
    <h5 class="card-subtitle mb-2 text-muted">{{ title }}</h5>
    <slot></slot>
  </card>
</template>

<script>
import { Card } from 'src/components/UIComponents';

export default {
  components: {
    Card,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.icon-big {
  font-size: 1.8em;
}
</style>
