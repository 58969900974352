<template>
  <div>
    <label v-if="!isInterdisciplinaryLink">Discipline</label>
    <label v-if="isInterdisciplinaryLink">Lien interdisciplinaire</label>
    <div class="row form-group2">
      <el-select
        v-model="selectedDisciplineId"
        :disabled="disabled"
        class="select-info"
        size="large"
        filterable
        clearable
        placeholder="Sélectionner"
        @update:modelValue="selectDiscipline"
      >
        <el-option
          v-for="discipline in disciplines"
          :value="discipline.id"
          :label="discipline.name"
          :key="discipline.name"
          class="select-info"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object, // This component emits the whole selected thematic object instead of just its ID, in order for the parents to work with its data without having to fetch it from the server.
    },
    isInterdisciplinaryLink: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      disciplines: [],
      selectedDisciplineId: null,
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    modelValue: {
      handler(newVal) {
        // Reset the select list if the value of the component is null.
        if (newVal) {
          this.selectedDisciplineId = this.modelValue.id;
        } else {
          this.selectedDisciplineId = null;
        }
      },
    },
  },
  methods: {
    fetchData() {
      return this.$http.get('/disciplines').then((response) => {
        this.disciplines = response.data._embedded.disciplines.map(
          (entity) => ({
            id: entity.id,
            name: entity.name,
          })
        );
      });
    },
    selectDiscipline() {
      const selectedDiscipline = this.disciplines.filter(
        (discipline) => discipline.id === this.selectedDisciplineId
      )[0];

      // This component emits the whole selected thematic object instead of just its ID, in order for the parents to work with its data.
      this.$emit('update:modelValue', selectedDiscipline);
    },
  },
};
</script>
