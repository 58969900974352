export default {
  data() {
    return {
      endParagraph: '</p>',
    };
  },
  methods: {
    // Remove all html tags from the given string.
    removeHtmlTags(string) {
      return string
        .substring(0, string.indexOf(this.endParagraph))
        .replace(/<\/?[^>]+(>|$)/g, '');
    },
  },
};
