<template>
  <div>
    <p class="mb-4">
      Veuillez vous référer
      <a
        class="text-button italic inline"
        href="https://katex.org/docs/supported.html"
        target="_blank"
      >
        à la documentation de rédaction</a
      >
      de KaTeX en cas de doute.
    </p>
    <fg-input v-model="model" type="text" label="Écrivez votre équation ici" />
    <div v-if="model" class="mt-4">
      <p class="mb-2">Résultat :</p>
      <math-equation :equation="model || ''" />
    </div>
  </div>
</template>

<script>
import MathEquation from './MathEquation.vue';

export default {
  components: {
    MathEquation,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>
