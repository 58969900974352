<template>
  <modal
    :show="show"
    footer-classes="justify-content-center"
    type="notice"
    @close="hideAdvancedSearchModal"
  >
    <template #header>
      <h5 class="modal-title">Recherche avancée</h5>
    </template>
    <discipline-select v-model="discipline"></discipline-select>
    <cycle-select v-model="cycle"></cycle-select>
    <thematic-select
      v-if="discipline && discipline.id"
      :discipline-id="discipline.id"
      @update:modelValue="setAdvancedSearchThematic"
    />
    <div v-if="isSelectedDisciplineMath">
      <label>Titre</label>
      <fg-input v-model="title" type="text" />
    </div>
    <label>Contenu</label>
    <textarea
      class="form-control progressions-specific-elements"
      placeholder="Insérez une partie du contenu ici..."
      rows="3"
      v-model="content"
    ></textarea>
    <template #footer>
      <div class="justify-content-center d-flex p-2 w-100">
        <p-button
          class="mx-1 my-1 w-50"
          type="light"
          round
          @click="hideAdvancedSearchModal"
          >Annuler
        </p-button>
        <p-button
          class="mx-1 my-1 w-50"
          type="success"
          round
          @click="doAdvancedSearch"
          >Rechercher
        </p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import PER_CONSTANTS from '@/constants/PER_CONSTANTS';
import { Modal } from 'src/components/UIComponents';
import CycleSelect from '../UIComponents/CycleSelect.vue';
import DisciplineSelect from '../UIComponents/DisciplineSelect.vue';
import ThematicSelect from '../UIComponents/ThematicSelect.vue';

export default {
  components: {
    Modal,
    CycleSelect,
    DisciplineSelect,
    ThematicSelect,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      discipline: {},
      cycle: null,
      thematicId: null,
      title: '',
      content: '',
    };
  },
  computed: {
    isSelectedDisciplineMath() {
      return this.discipline.id === PER_CONSTANTS.PER_DISCIPLINE_ID_MATH.ID;
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
  },
  beforeUnmount() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  methods: {
    keyupHandler(event) {
      if (this.show) {
        if (event.key === 'Enter') {
          this.doAdvancedSearch();
        } else if (event.key === 'Escape') {
          this.hideAdvancedSearchModal();
        }
      }
    },
    resetData() {
      this.discipline = {};
      this.cycle = null;
      this.thematicId = null;
      this.title = '';
      this.content = '';
    },
    hideAdvancedSearchModal() {
      this.resetData();
      this.$emit('hideAdvancedSearchModal');
    },
    doAdvancedSearch() {
      // Emit an advanced search event with the filled search data that will be catched in the CrudList component,
      // which will fire the fetchItems method of this component.
      this.emitter.emit('advancedSearch', {
        discipline: this.discipline,
        cycle: this.cycle,
        thematicId: this.thematicId,
        title: this.title,
        content: this.content,
      });

      this.hideAdvancedSearchModal();
    },
    setAdvancedSearchThematic(thematic) {
      this.thematicId = thematic.id;
    },
  },
};
</script>

<style scoped>
.error-message-modal {
  margin: 2em 0 0 0;
  color: darkred;
}
</style>
