<template>
  <div>
    <label>Années des apprentissages concernés</label>
    <div class="row form-group2">
      <el-select
        :modelValue="years"
        :disabled="disabled"
        :multiple="true"
        class="select-info"
        size="large"
        placeholder="Sélectionner"
        @change="$emit('change', $event)"
      >
        <el-option
          v-for="year in availableYearsOfCycle"
          :value="year.value"
          :label="year.label"
          :key="year.label"
          class="select-info"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import ITEM_METADATA from '@/constants/ITEM_METADATA';

export default {
  props: {
    years: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    availableYearsOfCycle() {
      const itemCycle = this.$store.state.item.itemData.per.cycle;

      if (itemCycle) {
        return ITEM_METADATA.AVAILABLE_YEARS_PER_CYCLE.get(itemCycle);
      }

      return [];
    },
  },
};
</script>
