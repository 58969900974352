<template>
  <div>
    <div class="card">
      <div v-if="!disableWritingOperations" class="card-header">
        <h5 class="title">{{ title }}</h5>
        <button
          v-if="!loading"
          type="button"
          class="btn btn-icon btn-round btn-success btn-add"
          @click="create"
        >
          <i class="fa fa-plus" />
        </button>
        <div v-if="loading" class="spinner-border spinner" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
      </div>
      <div class="card-body table-responsive table-full-width">
        <el-table :data="records" class="mydatalist" @row-click="rowClicked">
          <slot />
        </el-table>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 pagination-info">
        <p class="category">Total: {{ pagination.total }} éléments</p>
      </div>
      <div class="col-sm-6">
        <p-pagination
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
          class="pull-right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PPagination from 'src/components/UIComponents/Pagination.vue';

export default {
  components: {
    PPagination,
  },
  props: ['title', 'fetchData', 'disableWritingOperations'],
  data() {
    return {
      advancedSearchData: '',
      searchText: '',
      loading: false,
      records: [],
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
    };
  },
  watch: {
    'pagination.currentPage': {
      handler(newValue, oldValue) {
        this.fetch(this.advancedSearchData);
      },
    },
  },
  created() {
    this.fetch();
    this.emitter.on('advancedSearch', this.advancedSearch);
    this.emitter.on('search', this.search);
    this.emitter.on(
      'searchByFrancaisAdvancedMaterial',
      this.searchByFrancaisAdvancedMaterial
    );
  },
  beforeUnmount() {
    this.emitter.off('advancedSearch', this.advancedSearch);
    this.emitter.off('search', this.search);
    this.emitter.off(
      'searchByFrancaisAdvancedMaterial',
      this.searchByFrancaisAdvancedMaterial
    );
  },
  methods: {
    rowClicked(event) {
      this.$emit('row-clicked', event);
    },
    fetch(searchData) {
      this.loading = true;
      this.fetchData(
        this.pagination.currentPage,
        this.pagination.perPage,
        searchData
      ).then((response) => {
        this.loading = false;
        this.records = response.records;
        this.pagination.total = response.page.totalElements;
      });
    },
    advancedSearch(advancedSearchData) {
      this.advancedSearchData = advancedSearchData;
      this.fetch(this.advancedSearchData);
    },
    search(searchText) {
      this.advancedSearchData = searchText;
      this.fetch(this.advancedSearchData);
    },
    searchByFrancaisAdvancedMaterial(advancedSearchData) {
      this.advancedSearchData = advancedSearchData;
      this.fetch(this.advancedSearchData);
    },
    create() {
      this.$emit('create');
    },
  },
};
</script>

<style scoped>
.mydatalist:deep(tr) {
  cursor: pointer;
}

.btn-add,
.spinner {
  position: absolute !important;
  margin: 0px;
}

.btn-add {
  right: 10px;
  top: 10px;
}

.spinner {
  right: 13px;
  top: 13px;
}
</style>
