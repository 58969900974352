<template>
  <material-details-base
    :material-type="
      ITEM_METADATA.EXTERNAL_MATERIAL_TYPES.FrancaisAdvancedMaterialEntity.value
    "
    :custom-material-data="customMaterialData"
    :parent-error-message="errorMessage"
  >
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h5 class="title">Difficulté</h5>
        </div>
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-md-4">
                <fg-input
                  :disabled="!isMaterialEdited"
                  v-model="lowFrequencyWordsPercentage"
                  type="text"
                  label="% de mots peu fréquents"
                />
              </div>
              <div class="col-md-4">
                <fg-input
                  :disabled="!isMaterialEdited"
                  v-model="gunningIndex"
                  type="text"
                  label="Indice de Gunning"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <complexity-select
                  :disabled="!isMaterialEdited"
                  v-model="vocabularyComplexity"
                  title="Complexité du vocabulaire"
                  @update:modelValue="calculateDifficulty"
                />
              </div>
              <div class="col-md-4">
                <complexity-select
                  :disabled="!isMaterialEdited"
                  v-model="syntacticComplexity"
                  title="Complexité syntaxique"
                  @update:modelValue="calculateDifficulty"
                />
              </div>
              <div class="col-md-4">
                <complexity-select
                  :disabled="!isMaterialEdited"
                  v-model="textualStructure"
                  title="Complexité de la structure du texte"
                  @update:modelValue="calculateDifficulty"
                />
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <fg-input
                  :disabled="true"
                  v-model="textDifficultyIndexValue"
                  type="text"
                  label="Valeur indice de difficulté du texte"
                />
              </div>
              <div class="col-md-4">
                <fg-input
                  :disabled="true"
                  v-model="textDifficultyIndexName"
                  type="text"
                  label="Indice de difficulté du texte"
                />
              </div>
              <div class="col-md-4">
                <fg-input
                  :disabled="!isMaterialEdited"
                  v-model="bonusMalusQualityIndex"
                  type="text"
                  label="Indice qualitatif bonus et malus"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h5 class="title">Items associés</h5>
        </div>
        <div class="card-body associated-items-card-body">
          <div class="col-md-12">
            <crud-list
              ref="simpleList"
              :fetch-data="fetchAssociatedItems"
              :disable-writing-operations="true"
              class="simpleList"
              @row-clicked="associatedItemsRowClicked"
            >
              <el-table-column label="Objectif et contenu" min-width="200">
                <template #default="slotProps">
                  <div>
                    <span>{{ slotProps.row.mainLearningObjectiveCode }}</span>
                    <!-- Get the first line of the item's content as a substring then Remove any HTML tag from that substring and display it, in order to have a first look at the item. -->
                    <p class="item-list-content">
                      {{
                        slotProps.row.content
                          ? removeHtmlTags(slotProps.row.content)
                          : '-'
                      }}
                    </p>
                  </div>
                </template>
              </el-table-column>
            </crud-list>
          </div>
        </div>
      </div>
    </div>
  </material-details-base>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import ROUTES_NAMES from '@/constants/ROUTES_NAMES';
import mixinDifficulty from '@/mixins/mixinDifficulty';
import mixinItems from '@/mixins/mixinItems';
import MaterialDetailsBase from './UIComponents/materials/MaterialDetailsBase.vue';
import ComplexitySelect from './UIComponents/ComplexitySelect.vue';
import CrudList from './UIComponents/CrudList.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields } = createHelpers({
  getterType: 'material/getField',
  mutationType: 'material/updateField',
});

export default {
  components: {
    MaterialDetailsBase,
    ComplexitySelect,
    CrudList,
  },
  mixins: [mixinDifficulty, mixinItems],
  data() {
    return {
      ITEM_METADATA,
      errorMessage: '',
    };
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "customCode" for "management.customCode").
    ...mapFields([
      'data.lowFrequencyWordsPercentage',
      'data.gunningIndex',
      'data.vocabularyComplexity',
      'data.syntacticComplexity',
      'data.textualStructure',
      'data.textDifficultyIndexValue',
      'data.textDifficultyIndexName',
      'data.bonusMalusQualityIndex',
      'isMaterialEdited',
    ]),
    customMaterialData() {
      return {
        lowFrequencyWordsPercentage: this.lowFrequencyWordsPercentage,
        gunningIndex: this.gunningIndex,
        bonusMalusQualityIndex: this.bonusMalusQualityIndex,
        vocabularyComplexity: this.vocabularyComplexity,
        syntacticComplexity: this.syntacticComplexity,
        textualStructure: this.textualStructure,
      };
    },
  },
  methods: {
    fetchAssociatedItems(pageNumber, pageSize) {
      return this.$http
        .get(
          `/items/search/byFrancaisAdvancedMaterial?projection=itemOverview&francaisAdvancedMaterialId=${
            this.$route.params.id
          }&page=${pageNumber - 1}&size=${pageSize}`
        )
        .then((response) => ({
          records: response.data._embedded.items
            ? response.data._embedded.items
            : [],
          page: response.data.page ? response.data.page : 0,
        }))
        .catch(() => {
          this.errorMessage =
            'Une erreur est survenue lors de la récupération des items associés à ce matériel.';
        });
    },
    associatedItemsRowClicked(row) {
      this.$router.push({
        name: ROUTES_NAMES.ITEM_DETAILS,
        params: { id: row.id },
      });
    },
    calculateDifficulty() {
      if (
        this.vocabularyComplexity != null &&
        this.syntacticComplexity != null &&
        this.textualStructure != null
      ) {
        // Locally calculate the text difficulty in order to display it to the user, but they won't be
        // sent to the server since it already calculates them (and we don't want frontend-side calculations
        // to be sent since it can be unsecure).
        const averageTextDifficulty =
          (this.vocabularyComplexity +
            this.syntacticComplexity +
            this.textualStructure) /
          3;

        const roundedAverageTextDifficulty =
          Math.round(averageTextDifficulty * 100) / 100;

        this.$store.commit(
          'material/setMaterialTextDifficultyIndexValue',
          roundedAverageTextDifficulty
        );

        const textDifficultyName = this.getDifficultyNameByValue(
          roundedAverageTextDifficulty
        );

        this.$store.commit(
          'material/setMaterialTextDifficultyIndexName',
          textDifficultyName
        );
      }
    },
  },
};
</script>

<style scoped>
.associated-items-card-body {
  padding-top: 0px !important;
}

.simpleList {
  max-height: 25em;
  overflow: scroll;
}
</style>
