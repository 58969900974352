<template>
  <div
    class="sidebar"
    :data-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <div class="logo">
      <router-link class="simple-text logo-mini" to="/">
        <div class="logo-img">
          <img :src="logo" alt="logo eprocom" />
        </div>
      </router-link>
      <router-link class="simple-text logo-normal" to="/">
        {{ title }}
      </router-link>
    </div>

    <perfect-scrollbar>
      <div class="sidebar-wrapper">
        <slot />
        <ul class="nav">
          <slot name="links">
            <sidebar-item
              v-for="(link, index) in links"
              :key="link.name + index"
              :link="link"
            >
              <template v-if="link.children" #default>
                <sidebar-item
                  v-for="(subLink, index) in link.children"
                  :key="subLink.name + index"
                  :link="subLink"
                />
              </template>
            </sidebar-item>
          </slot>
        </ul>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SidebarItem from '@/components/UIComponents/SidebarPlugin/SidebarItem.vue';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';

export default {
  components: {
    SidebarItem,
    PerfectScrollbar,
  },
  props: {
    title: {
      type: String,
      default: 'Vue PDP Pro_X',
      description: 'Sidebar title',
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        const acceptedValues = ['white', 'brown', 'black'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Sidebar background color (white|brown|black)',
    },
    activeColor: {
      type: String,
      default: 'success',
      validator: (value) => {
        const acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar active text color (primary|info|success|warning|danger)',
    },
    logo: {
      type: String,
      default: 'static/img/vue-logo.png',
      description: 'Sidebar Logo',
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        'Sidebar links. Can be also provided as children components (sidebar-item)',
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  computed: {
    ...mapGetters({
      isAdministrator: 'account/isAdministrator',
    }),
    links() {
      // Show only links that are not admin or are admin and user is admin.
      return this.sidebarLinks.filter(
        (link) => !link.admin || (link.admin && this.isAdministrator)
      );
    },
  },
};
</script>

<style src="vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"></style>

<style lang="scss">
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.ps {
  height: calc(100vh - 75px);
  z-index: 4;
}
</style>
