<template>
  <el-select
    v-model="selectedExternalMaterialId"
    :remote-method="loadExternalMaterials"
    :loading="loading"
    placeholder="Recherchez un matériel externe..."
    class="select-info flex-grow-1"
    filterable
    clearable
    remote
    @update:modelValue="selectExternalMaterial"
  >
    <el-option
      v-for="externalMaterial in externalMaterials"
      :key="externalMaterial.id"
      :label="externalMaterial.documents[0].name"
      :value="externalMaterial.id"
      class="select-info"
    />
  </el-select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Object, // This component emits the whole selected external material object instead of just its ID, in order for the parents to work with its data.
    },
    materialType: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      selectedExternalMaterialId: null,
      externalMaterials: [],
    };
  },
  watch: {
    modelValue: {
      handler(newVal) {
        // Reset the select list if the value of the component is null.
        if (!newVal) {
          this.selectedExternalMaterialId = null;
        }
      },
    },
  },
  methods: {
    loadExternalMaterials(query) {
      if (query !== '') {
        this.loading = true;

        this.$http
          .get(
            `/${this.materialType}Materials/search/byName?name=${query}&page=0&size=10&projection=${this.materialType}MaterialDTO`
          )
          .then((response) => {
            this.loading = false;
            this.externalMaterials =
              response.data._embedded[`${this.materialType}Materials`];
          });
      } else {
        this.externalMaterials = [];
      }
    },
    selectExternalMaterial() {
      const selectedExternalMaterial = this.externalMaterials.filter(
        (externalMaterial) =>
          externalMaterial.id === this.selectedExternalMaterialId
      )[0];

      // This component emits the whole selected external material object instead of just its ID, in order for the parents to work with its data.
      this.$emit('update:modelValue', selectedExternalMaterial);
    },
  },
};
</script>
