<template>
  <div>
    <item-card title="Mathématiques" icon="nc-chart-bar-32">
      <generic-select
        :options="ITEM_METADATA.TASK_CATEGORIZATION_OPTIONS"
        :disabled="!isItemEdited"
        v-model="taskCategorization"
        label="Catégorisation de la tâche"
      />
      <generic-select
        :options="ITEM_METADATA.IMAGE_FUNCTION_OPTIONS"
        :disabled="!isItemEdited"
        v-model="imageFunction"
        label="Statut/fonction de l'image"
      />
      <generic-select
        :options="mathComplexityFactor"
        :disabled="!isItemEdited"
        v-model="contextualComplexityFactorIndex"
        label="Facteur de complexité contextuelle"
      />
      <generic-select
        :options="mathComplexityFactor"
        :disabled="!isItemEdited"
        v-model="complexityFactorIndex"
        label="Facteur de complexité mathématique"
      />
      <generic-select
        :options="mathCompetenceLevelFactor"
        :disabled="!isItemEdited"
        v-model="competenceLevelIndex"
        label="Niveau de compétence"
      />
      <div class="form-group">
        <label class="control-label">Difficulté à postériori</label>
        <textarea
          v-model="difficultyAfterthought"
          :disabled="!isItemEdited"
          class="form-control"
          rows="3"
        />
      </div>
      <fg-input
        v-model="questioningFormat"
        :disabled="!isItemEdited"
        type="text"
        label="Format de questionnement"
      />
    </item-card>

    <item-card title="Actions et opérations" icon="nc-tile-56">
      <rich-text-editor
        v-model="actionsAndOperations"
        :disabled="!isItemEdited"
        :disciplineId="disciplineId"
        field-name="actions-and-operations"
      />
    </item-card>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import ItemCard from './ItemCard.vue';
import GenericSelect from '../GenericSelect.vue';
import RichTextEditor from '../RichTextEditor/RichTextEditor.vue';

// Used to be able to map store attributes in the v-model properties of the field
// and thus to automatically map the mutations to update them.
const { mapFields } = createHelpers({
  getterType: 'item/getField',
  mutationType: 'item/updateField',
});

export default {
  components: {
    ItemCard,
    GenericSelect,
    RichTextEditor,
  },
  props: {
    disciplineId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      ITEM_METADATA,
    };
  },
  computed: {
    // With this mapping we could then refer each (nested) field of the store directly from its name in the html template (e.g. "cycle" for "per.cycle").
    ...mapFields([
      'isItemEdited',
      'itemData.disciplineRelatedData.mathematics.taskCategorization',
      'itemData.disciplineRelatedData.mathematics.imageFunction',
      'itemData.disciplineRelatedData.mathematics.contextualComplexityFactorIndex',
      'itemData.disciplineRelatedData.mathematics.complexityFactorIndex',
      'itemData.disciplineRelatedData.mathematics.competenceLevelIndex',
      'itemData.disciplineRelatedData.multiple.difficultyAfterthought',
      'itemData.disciplineRelatedData.multiple.questioningFormat',
      'itemData.disciplineRelatedData.mathematics.actionsAndOperations',
    ]),
    mathComplexityFactor() {
      // Convert the yes/no map's elements to an array of objects.
      const mathComplexityFactorObjectFromMap = Array.from(
        this.ITEM_METADATA.MATH_COMPLEXITY_FACTOR
      ).map((element) => ({
        key: element[0],
        value: element[0],
        label: element[1],
      }));

      return mathComplexityFactorObjectFromMap;
    },
    mathCompetenceLevelFactor() {
      // Convert the yes/no map's elements to an array of objects.
      const mathCompetenceLevelFactorObjectFromMap = Array.from(
        this.ITEM_METADATA.MATH_COMPETENCE_LEVEL_FACTOR
      ).map((element) => ({
        key: element[0],
        value: element[0],
        label: element[1],
      }));

      return mathCompetenceLevelFactorObjectFromMap;
    },
  },
};
</script>
