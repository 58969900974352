<template>
  <node-view-wrapper class="inline">
    <math-equation
      v-if="node && node.attrs.equation"
      :equation="node.attrs.equation.toString()"
    />
  </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import MathEquation from './MathEquation.vue';

export default {
  name: 'Math',
  components: {
    NodeViewWrapper,
    MathEquation,
  },
  props: nodeViewProps,
};
</script>
