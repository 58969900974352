/**
 * This constant contains the list of recommended genres ("genres conseillés"), grouped by learning objective, chapter
 * and years.
 *
 * You can find examples of recommended genres here: https://www.plandetudes.ch/web/guest/L1_21/
 *
 * The choice of hardcoding this list rather than retrieving it directly from the PER API was made because the data of
 * the API are currently not structured. Indeed, the recommended genres are considered as progressions of the learning
 * objective and are therefore mixed with all other progressions. It is therefore very difficult to find them other than
 * by going through all the progressions one by one and searching for the keyword "Genre conseillé" and comparing the
 * line numbers of the chapter and the progression to try to make them match.
 * In addition, there are between 0 and 2 lines separating the chapter from the recommended genres, and this is
 * unpredictable. This data are sometimes separated into 2 progressions (one containing the heading "Genre conseillé :"
 * and the other containing the bulleted list), while sometimes they are grouped in a single progression.
 *
 * In summary, if we wanted to retrieve the recommended genres, we would have to write a complex code that:
 *   1) retrieve the position of the chapter in the learning objective table;
 *   2) look for the progression that starts with "Genre conseillé" and whose position is between 1 and 3 increments
 *      higher than that of the chapter;
 *   3a) retrieve the next progression if the label is disassociated from the bulleted list;
 *   3b) extract the bulleted list from the text if the label and the bulleted list are merged;
 *   4) resolve other inconsistencies;
 *   5) parse the html content to extract each of the bullets.
 * It is easy to understand that this operation becomes extremely complex, especially as it is not the role of the
 * EpRoCom application but rather that of the PER application.
 *
 * A ticket has been created on the Jira of the PER application to restructure the data:
 * https://sg-ciip.atlassian.net/browse/PER-48
 *
 * @type {{learningObjectives: Map<number, {name: string, chapters: Map<string, {recommendedGenresByYears: {"7,8": string[], "5,6": string[]}}>}>}}
 */
const RECOMMENDED_GENRES = {
  learningObjectives: new Map([
    [
      3,
      {
        name: 'L1 21',
        chapters: new Map([
          [
            'Le texte qui raconte',
            {
              recommendedGenresByYears: {
                '5,6': [
                  'le conte merveilleux',
                  "le récit d'aventure",
                  'la fable',
                  'le conte du pourquoi et du comment',
                ],
                '7,8': [
                  'le conte du pourquoi et du comment',
                  'le récit mythique',
                  'la légende',
                  "le récit d'aventure",
                  "le récit d'énigme",
                ],
              },
            },
          ],
          [
            'Le texte qui relate',
            {
              recommendedGenresByYears: {
                '5,6': [
                  'le récit de vie',
                  "le témoignage d'une expérience vécue",
                ],
                '7,8': [
                  'le fait divers',
                  'la biographie',
                  'le récit historique',
                ],
              },
            },
          ],
          [
            'Le texte qui argumente',
            {
              recommendedGenresByYears: {
                '5,6': [
                  'la lettre de demande',
                  "la lettre d'opinion",
                  'la réponse au courrier des lecteurs',
                ],
                '7,8': ['la lettre au courrier des lecteurs'],
              },
            },
          ],
          [
            'Le texte qui transmet des savoirs',
            {
              recommendedGenresByYears: {
                '5,6': ['le texte documentaire', "l'article encyclopédique"],
                '7,8': [
                  'le texte documentaire',
                  "l'article encyclopédique",
                  'le texte qui explique «comment» et «pourquoi»',
                ],
              },
            },
          ],
          [
            'Le texte qui règle des comportements',
            {
              recommendedGenresByYears: {
                '5,6': ['la recette', "la marche à suivre d'un bricolage"],
                '7,8': [
                  "la marche à suivre d'un bricolage",
                  'la règle de jeu',
                  'le règlement',
                ],
              },
            },
          ],
          [
            'Le texte qui joue avec la langue – Le texte poétique',
            {
              recommendedGenresByYears: {
                '5,6,7,8': ['le poème', "les paroles d'une chanson"],
              },
            },
          ],
        ]),
      },
    ],
    [
      4,
      {
        name: 'L1 22',
        chapters: new Map([
          [
            'Le texte qui raconte',
            {
              recommendedGenresByYears: {
                '5,6': [
                  'le conte merveilleux',
                  "le récit d'aventure",
                  'le conte du pourquoi et du comment',
                ],
                '7,8': [
                  "le récit d'aventure",
                  'le conte du pourquoi et du comment',
                ],
              },
            },
          ],
          [
            'Le texte qui relate',
            {
              recommendedGenresByYears: {
                '5,6': ['le récit de vie'],
                '7,8': ['le fait divers', "l'esquisse biographique"],
              },
            },
          ],
          [
            'Le texte qui argumente',
            {
              recommendedGenresByYears: {
                '5,6': [
                  'la lettre de demande justifiée',
                  'la réponse au courrier des lecteurs',
                ],
                '7,8': [
                  'la lettre de lecteur',
                  'la lettre de demande justifiée',
                ],
              },
            },
          ],
          [
            'Le texte qui transmet des savoirs',
            {
              recommendedGenresByYears: {
                '5,6': ['le texte documentaire', "l'article encyclopédique"],
                '7,8': [
                  'le texte qui explique «comment» et «pourquoi»',
                  "l'article encyclopédique",
                  "l'exposé écrit",
                  'la note de synthèse',
                ],
              },
            },
          ],
          [
            'Le texte qui règle des comportements',
            {
              recommendedGenresByYears: {
                '5,6': ['la recette', "la marche à suivre d'un bricolage"],
                '7,8': ["la marche à suivre d'un bricolage", 'la règle de jeu'],
              },
            },
          ],
          [
            'Le texte qui joue avec la langue – Le texte poétique',
            {
              recommendedGenresByYears: {
                '5,6,7,8': ['poème', 'calligramme'],
              },
            },
          ],
        ]),
      },
    ],
    [
      5,
      {
        name: 'L1 23',
        chapters: new Map([
          [
            'Le texte qui raconte',
            {
              recommendedGenresByYears: {
                '5,6': [
                  'le conte',
                  "l'histoire inventée",
                  "le récit d'aventure",
                ],
                '7,8': [
                  'la légende',
                  'le mythe',
                  "le récit d'aventure",
                  'la fable',
                ],
              },
            },
          ],
          [
            'Le texte qui relate',
            {
              recommendedGenresByYears: {
                '5,6,7,8': [
                  'le récit de vie',
                  "l'interview d'une personne qui témoigne de son vécu",
                ],
              },
            },
          ],
          [
            'Le texte qui argumente',
            {
              recommendedGenresByYears: {
                '5,6,7,8': ["le débat régulé en tant qu'auditeur"],
              },
            },
          ],
          [
            'Le texte qui transmet des savoirs',
            {
              recommendedGenresByYears: {
                '5,6,7,8': [
                  "l'interview d'expert dans une émission radiophonique",
                  'un documentaire audiovisuel',
                ],
              },
            },
          ],
          [
            'Le texte qui règle des comportements',
            {
              recommendedGenresByYears: {
                '5,6': ['la recette', "la description d'un itinéraire"],
                '7,8': ['la règle de jeu'],
              },
            },
          ],
          [
            'Le texte qui joue avec la langue – Le texte poétique',
            {
              recommendedGenresByYears: {
                '5,6,7,8': ['le poème', 'la chanson'],
              },
            },
          ],
        ]),
      },
    ],
    [
      6,
      {
        name: 'L1 24',
        chapters: new Map([
          [
            'Le texte qui raconte',
            {
              recommendedGenresByYears: {
                '5,6': ['le conte', "l'histoire inventée"],
                '7,8': ['la légende', 'le sketch'],
              },
            },
          ],
          [
            'Le texte qui relate',
            {
              recommendedGenresByYears: {
                '5,6': ['le récit de vie'],
                '7,8': ["l'exposé sur un personnage", 'le récit de vie'],
              },
            },
          ],
          [
            'Le texte qui argumente',
            {
              recommendedGenresByYears: {
                '5,6': ['le débat régulé'],
              },
            },
          ],
          [
            'Le texte qui transmet des savoirs',
            {
              recommendedGenresByYears: {
                '5,6': ["l'interview d'expert"],
                '7,8': ["l'interview d'expert", "l'exposé oral"],
              },
            },
          ],
          [
            'Le texte qui règle des comportements',
            {
              recommendedGenresByYears: {
                '5,6': ["la description d'un itinéraire"],
                '7,8': ['la règle de jeu'],
              },
            },
          ],
          [
            'Le texte qui joue avec la langue – Le texte poétique',
            {
              recommendedGenresByYears: {
                '5,6,7,8': ['le poème', 'la chanson'],
              },
            },
          ],
        ]),
      },
    ],
  ]),
};

// Some of the key-value options are Map objects, because it is easier to work with them when writing the history
// (we indeed need to access the labels of the integer ID values of the selects in order to make the history
// readable by a human).
const ITEM_METADATA = {
  EXTERNAL_MATERIAL_TYPES: {
    FrancaisAdvancedMaterialEntity: {
      value: 'francaisAdvanced',
      historyLabel: 'textes/corpus',
    },
    FrancaisSimpleMaterialEntity: {
      value: 'francaisSimple',
      historyLabel: 'tâches',
    },
    MathMaterialEntity: {
      value: 'math',
      historyLabel: 'mathématiques',
    },
  },
  STATUS_OPTIONS: [
    { key: 'Concepteurs', value: 'Concepteurs' },
    { key: 'Experts', value: 'Experts' },
    { key: 'Consultation', value: 'Consultation' },
    { key: 'Cantons', value: 'Cantons' },
    { key: 'Enseignants', value: 'Enseignants' },
  ],
  CANTONS_OPTIONS: [
    { key: 'Autre', value: 'Autre' },
    { key: 'BE', value: 'BE' },
    { key: 'FR', value: 'FR' },
    { key: 'GE', value: 'GE' },
    { key: 'IRDP', value: 'IRDP' },
    { key: 'JU', value: 'JU' },
    { key: 'NE', value: 'NE' },
    { key: 'VD', value: 'VD' },
    { key: 'VS', value: 'VS' },
  ],
  COMPUTERIZED_ITEM_OPTIONS: [
    { key: 'Oui', value: 'Oui' },
    { key: 'Non', value: 'Non' },
    { key: "Pourrait l'être", value: "Pourrait l'être" },
  ],
  YES_NO_OPTIONS: [
    { key: 0, value: 'Non' },
    { key: 1, value: 'Oui' },
  ],
  // Map of available years for a thematic.
  AVAILABLE_YEARS_PER_CYCLE: new Map([
    [
      1,
      [
        {
          value: 1,
          label: '1re',
        },
        {
          value: 2,
          label: '2e',
        },
        {
          value: 3,
          label: '3e',
        },
        {
          value: 4,
          label: '4e',
        },
      ],
    ],
    [
      2,
      [
        {
          value: 5,
          label: '5e',
        },
        {
          value: 6,
          label: '6e',
        },
        {
          value: 7,
          label: '7e',
        },
        {
          value: 8,
          label: '8e',
        },
      ],
    ],
    [
      3,
      [
        {
          value: 9,
          label: '9e',
        },
        {
          value: 10,
          label: '10e',
        },
        {
          value: 11,
          label: '11e',
        },
      ],
    ],
  ]),
  MATERIAL_PRESENT_OPTIONS: new Map([
    [-1, 'Présent'],
    [1, 'Absent'],
  ]),
  TASK_CATEGORIZATION_OPTIONS: [
    { key: 'Tâche technique', value: 'Tâche technique' },
    { key: "Tâche d'application", value: "Tâche d'application" },
    { key: 'Problème', value: 'Problème' },
    { key: 'Problème atypique', value: 'Problème atypique' },
  ],
  IMAGE_FUNCTION_OPTIONS: [
    { key: 'Décorative', value: 'Décorative' },
    { key: 'Représentative', value: 'Représentative' },
    { key: 'Organisatrice', value: 'Organisatrice' },
    { key: 'Informative', value: 'Informative' },
    { key: 'Absente', value: 'Absente' },
  ],
  MATH_COMPLEXITY_FACTOR: new Map([
    [0, 'Complexité basse'],
    [1, 'Complexité moyenne'],
    [2, 'Complexité haute'],
  ]),
  MATH_COMPETENCE_LEVEL_FACTOR: new Map([
    [0, 'Niveau 1'],
    [1, 'Niveau 2'],
    [2, 'Niveau 3'],
  ]),
  DIFFICULTIES_VALUES: new Map([
    [1, 'Difficile'],
    [0, 'Intermédiaire'],
    [-1, 'Facile'],
  ]),
  RECOMMENDED_GENRES,
};

export default ITEM_METADATA;
