<template>
  <div>
    <advanced-search-modal
      :show="advancedSearchModalShown"
      @hideAdvancedSearchModal="hideAdvancedSearchModal"
    />

    <search-by-francais-advanced-material-modal
      :show="searchByFrancaisAdvancedMaterialModalShown"
      @hideSearchByFrancaisAdvancedMaterialModal="
        hideSearchByFrancaisAdvancedMaterialModal
      "
    />

    <new-item-modal
      :show="newItemModalShown"
      @hideNewItemModal="hideNewItemModal"
    />

    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          :fetch-data="fetchItems"
          title="Items"
          @create="showNewItemModal"
          @row-clicked="rowClicked"
        >
          <el-table-column
            label="Code et contenu/titre"
            min-width="220"
            class="table-items-list"
          >
            <template #default="slotProps">
              <div class="table-items-list-item">
                <span>{{ slotProps.row.customCode }}</span>
                <!-- Get the first line of the item's content as a substring then Remove any HTML tag from that substring and display it, in order to have a first look at the item. -->
                <p
                  v-if="isDisciplineFrench(slotProps.row.discipline.id)"
                  class="item-list-content"
                >
                  {{
                    slotProps.row.content
                      ? removeHtmlTags(slotProps.row.content)
                      : '-'
                  }}
                </p>
                <p
                  v-if="isDisciplineMath(slotProps.row.discipline.id)"
                  class="item-list-content"
                >
                  {{ slotProps.row.title ? slotProps.row.title : '-' }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Discipline" min-width="100">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.discipline.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Cycle" min-width="100">
            <template #default="slotProps">
              <div class="table-items-list-item">
                {{ slotProps.row.cycle }}
              </div>
            </template>
          </el-table-column>
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import PER_CONSTANTS from '@/constants/PER_CONSTANTS';
import ROUTES_NAMES from '@/constants/ROUTES_NAMES';
import mixinItems from '@/mixins/mixinItems';
import CrudList from './UIComponents/CrudList.vue';
import AdvancedSearchModal from './Modals/AdvancedSearchModal.vue';
import SearchByFrancaisAdvancedMaterialModal from './Modals/SearchByFrancaisAdvancedMaterialModal.vue';
import NewItemModal from './Modals/NewItemModal.vue';

export default {
  components: {
    CrudList,
    AdvancedSearchModal,
    SearchByFrancaisAdvancedMaterialModal,
    NewItemModal,
  },
  mixins: [mixinItems],
  data() {
    return {
      errorMessage: '',
      advancedSearchModalShown: false,
      searchByFrancaisAdvancedMaterialModalShown: false,
      newItemModalShown: false,
    };
  },
  created() {
    this.emitter.on('showAdvancedSearchModal', this.showAdvancedSearchModal);
    this.emitter.on(
      'showSearchByFrancaisAdvancedMaterialModal',
      this.showSearchByFrancaisAdvancedMaterialModal
    );
  },
  beforeUnmount() {
    this.emitter.off('showAdvancedSearchModal', this.showAdvancedSearchModal);
    this.emitter.off(
      'showSearchByFrancaisAdvancedMaterialModal',
      this.showSearchByFrancaisAdvancedMaterialModal
    );
  },
  methods: {
    isDisciplineFrench(disciplineId) {
      return disciplineId === PER_CONSTANTS.PER_DISCIPLINE_ID_FRANCAIS.ID;
    },
    isDisciplineMath(disciplineId) {
      return disciplineId === PER_CONSTANTS.PER_DISCIPLINE_ID_MATH.ID;
    },
    rowClicked(row) {
      const routeData = this.$router.resolve({
        name: ROUTES_NAMES.ITEM_DETAILS,
        params: { id: row.id },
      });
      window.open(routeData.href, '_blank');
    },
    fetchItems(pageNumber, pageSize, advancedSearchData) {
      let url;
      // Check if the user started an advanced search or not.
      if (advancedSearchData && Object.entries(advancedSearchData).length) {
        // Search by external Français Material
        if (advancedSearchData.francaisMaterialId) {
          url = `/items/search/byFrancaisAdvancedMaterial?projection=itemOverview&sort=customCode&page=${
            pageNumber - 1
          }&size=${pageSize}&francaisAdvancedMaterialId=${
            advancedSearchData.francaisMaterialId
          }`;
          this.hideSearchByFrancaisAdvancedMaterialModal();
        } else {
          // Advanced Search
          // Had to remove sort=customCode when enforcing pagination as this parameter was not compatible with the custom query
          // in the ItemRepository which is looking for unexistant property cutomCode on ItemThematicEntity
          url = `/items/search/advancedSearch?projection=itemOverview&page=${
            pageNumber - 1
          }&size=${pageSize}`;

          if (
            advancedSearchData.discipline &&
            advancedSearchData.discipline.id
          ) {
            url += `&disciplineId=${advancedSearchData.discipline.id}`;
          }

          if (advancedSearchData.cycle) {
            url += `&cycle=${advancedSearchData.cycle}`;
          }

          if (advancedSearchData.thematicId) {
            url += `&thematicId=${advancedSearchData.thematicId}`;
          }

          if (advancedSearchData.title) {
            url += `&title=${advancedSearchData.title}`;
          }

          if (advancedSearchData.content) {
            url += `&content=${advancedSearchData.content}`;
          }

          this.hideAdvancedSearchModal();
        }
      } else {
        // Fetch all items
        url = `/items?projection=itemOverview&sort=customCode&page=${
          pageNumber - 1
        }&size=${pageSize}`;
      }

      return this.$http.get(url).then((response) => ({
        records: response.data._embedded.items
          ? response.data._embedded.items
          : [],
        page: response.data.page ? response.data.page : 0,
      }));
    },
    showNewItemModal() {
      this.newItemModalShown = true;
    },
    hideNewItemModal() {
      this.newItemModalShown = false;
    },
    showAdvancedSearchModal() {
      this.advancedSearchModalShown = true;
    },
    hideAdvancedSearchModal() {
      this.advancedSearchModalShown = false;
    },
    showSearchByFrancaisAdvancedMaterialModal() {
      this.searchByFrancaisAdvancedMaterialModalShown = true;
    },
    hideSearchByFrancaisAdvancedMaterialModal() {
      this.searchByFrancaisAdvancedMaterialModalShown = false;
    },
  },
};
</script>
