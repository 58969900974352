<template>
  <footer class="footer footer-black footer-white">
    <div class="container-fluid">
      <nav class="pull-left">
        <ul>
          <li>
            <a href="http://www.ciip.ch/">
              Conférence intercantonale de l'instruction publique de la Suisse
              Romande et du Tessin
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright pull-right">
        <span
          >Développé avec <i class="fa fa-heart heart"></i> à l'<a
            href="http://www.iict.ch"
            target="_blank"
            >IICT</a
          ></span
        >
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'main-footer',
};
</script>
