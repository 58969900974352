<template>
  <div>
    <new-material-modal
      :show="isNewMaterialModalShown"
      :material-type="materialType"
      @hideNewMaterialModal="hideNewMaterialModal"
    />

    <div class="row">
      <div class="col-md-12">
        <crud-list
          ref="crudList"
          :fetch-data="fetchMaterials"
          :title="title"
          @create="showNewMaterialModal"
          @row-clicked="rowClicked"
        >
          <el-table-column label="Nom" min-width="100">
            <template #default="slotProps">
              <div>{{ slotProps.row.name }}</div>
            </template>
          </el-table-column>
        </crud-list>
      </div>
    </div>
  </div>
</template>

<script>
import ITEM_METADATA from '@/constants/ITEM_METADATA';
import mixinTextTransform from '@/mixins/mixinTextTransform';
import CrudList from '../CrudList.vue';
import NewMaterialModal from '../../Modals/NewMaterialModal.vue';

export default {
  components: {
    CrudList,
    NewMaterialModal,
  },
  mixins: [mixinTextTransform],
  props: {
    materialType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ITEM_METADATA,
      isNewMaterialModalShown: false,
    };
  },
  methods: {
    rowClicked(row) {
      const pascalCasedMaterialType = this.capitalizeFirstLetter(
        this.materialType
      );

      this.$router.push({
        name: `${pascalCasedMaterialType}MaterialDetails`,
        params: { id: row.id, newMaterialCreation: false },
      });
    },
    fetchMaterials(pageNumber, pageSize, searchText) {
      const endpointName = `${this.materialType}Materials`;
      let url = `/${endpointName}`;

      if (searchText) {
        url += `/search/byName?name=${searchText}&`;
      } else {
        url += `?`;
      }

      url += `sort=name&page=${
        pageNumber - 1
      }&size=${pageSize}&projection=materialOverviewDTO`;

      return this.$http.get(url).then((response) => ({
        records: response.data._embedded[endpointName]
          ? response.data._embedded[endpointName]
          : [],
        page: response.data.page ? response.data.page : 0,
      }));
    },
    showNewMaterialModal() {
      this.isNewMaterialModalShown = true;
    },
    hideNewMaterialModal() {
      this.isNewMaterialModalShown = false;
    },
  },
};
</script>
