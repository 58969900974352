<template>
  <div>
    <label>{{ title }}</label>
    <div class="row form-group2">
      <el-select
        :disabled="disabled"
        :modelValue="modelValue"
        class="select-info"
        size="large"
        placeholder="Sélectionner"
        @update:modelValue="$emit('update:modelValue', $event)"
      >
        <el-option
          v-for="materialPresentOption in Array.from(
            ITEM_METADATA.MATERIAL_PRESENT_OPTIONS
          )"
          :value="materialPresentOption[0]"
          :label="materialPresentOption[1]"
          :key="materialPresentOption[1]"
          class="select-info"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import ITEM_METADATA from '@/constants/ITEM_METADATA';

export default {
  name: 'SelectMaterialPresent',
  props: ['modelValue', 'title', 'disabled'],
  data() {
    return {
      ITEM_METADATA,
    };
  },
};
</script>
