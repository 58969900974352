<template>
  <div class="row top-button-container">
    <div
      class="col-md-12 mb-4 d-flex flex-nowrap justify-content-between align-items-center"
    >
      <div class="d-flex">
        <button
          type="submit"
          :disabled="isSaveButtonDisabled || isLoading"
          :class="editSaveButtonClass"
          class="btn btn-fill btn-wd mr-1"
          @click.prevent="editOrSaveEntity"
        >
          <a v-if="!isEntityEdited">Editer</a>
          <a v-else>Enregister</a>
        </button>
        <button
          type="warning"
          class="btn btn-warning btn-fill btn-wd mr-1"
          v-show="isEntityEdited"
          @click.prevent="cancelButtonClicked"
        >
          Annuler
        </button>
        <button
          type="danger"
          :disabled="isLoading"
          class="btn btn-danger btn-fill btn-wd"
          v-show="isEntityEdited"
          @click.prevent="deleteEntity"
        >
          Supprimer
        </button>
      </div>
      <div class="text-center mx-5">
        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
        <div v-if="lastUpdateDate" class="last-update-date">
          Dernière sauvegarde effectuée le {{ lastUpdateDate }}.
        </div>
      </div>
      <div class="d-flex">
        <div v-if="isLoading" class="spinner-border" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <button
          v-if="showDuplicateButton"
          type="warning"
          class="btn btn-warning btn-fill btn-wd my-0 mr-0 ml-3"
          @click.prevent="duplicateEntity"
        >
          Dupliquer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    isEntityEdited: {
      type: Boolean,
    },
    isSaveButtonDisabled: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
    lastUpdateDate: {
      type: String,
    },
    showDuplicateButton: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.item.isLoading,
    }),
    editSaveButtonClass() {
      return {
        'btn-warning': !this.isEntityEdited,
        'btn-success': this.isEntityEdited,
      };
    },
  },
  methods: {
    editOrSaveEntity() {
      this.$emit('editOrSave');
    },
    cancelButtonClicked() {
      this.$emit('cancel');
    },
    deleteEntity() {
      this.$emit('delete');
    },
    duplicateEntity() {
      this.$emit('duplicate');
    },
  },
};
</script>

<style scoped>
.top-button-container {
  margin-top: 0 !important;
}

.error-message {
  color: darkred;
}

.last-update-date {
  color: #6c757d;
  font-style: italic;
}

button:disabled,
button[disabled] {
  cursor: no-drop;
}
</style>
